import checkIcon from 'assets/checkIcon.png'
import { postStoryResource, storyboardPaths } from 'features/storyboard/storyboard.api'
import { subscriptionsPlansEnum } from 'features/storyboard/storyboard.enums'
import { useNavigate } from 'react-router-dom'
import { Button } from 'shared/components/Buttons/Button'
import styled from 'styled-components'
import { Layout } from '../theme/Layouts/Layout'
import { pagesRoutesEnum } from './Router/router.enums'

export const Subscriptions = () => {
  const navigate = useNavigate()

  const onClickFree = () => {
    navigate(pagesRoutesEnum.create)
  }

  const onClickCreator = async () => {
    const success_redirect_url =
      process.env.NODE_ENV === 'development'
        ? `http://localhost:3000${pagesRoutesEnum.paymentSuccess}`
        : `${window.location.protocol}//${window.location.hostname}${pagesRoutesEnum.paymentSuccess}`
    const failed_redirect_url =
      process.env.NODE_ENV === 'development'
        ? `http://localhost:3000${pagesRoutesEnum.paymentFailed}`
        : `${window.location.protocol}//${window.location.hostname}${pagesRoutesEnum.paymentFailed}`

    const type = subscriptionsPlansEnum.regular
    const data = await postStoryResource<
      { type: any; success_redirect_url: string; failed_redirect_url: string },
      { payment_link: string }
    >(storyboardPaths.payment, { arg: { type, success_redirect_url, failed_redirect_url } })
    window.open(data.payment_link, '_blank')
  }

  const onClickEnterprise = () => {
    window.location.href = 'mailto:founders@storia.ai?subject=StoriaBoard Enterprise Tier'
  }

  return (
    <Layout>
      <Div listIcon={checkIcon}>
        <h1 className="mb-5 mt-5">Pricing</h1>
        <p className="mb-5">
          We are who we choose to be. Now choose!
          <cite>&nbsp;—&nbsp;Green Goblin, Spider-Man</cite>
        </p>

        <div className="flex flex-wrap">
          <span>
            <h2>Explorer</h2>
            <p>Glimpse into AI-powered storytelling</p>
            <div>
              <h1>Free</h1>
              <Button size="large" variant="outline" onClick={onClickFree}>
                Get started
              </Button>
            </div>
            <ul>
              <li>Unlimited storyboards</li>
              <li>50 AI-generated shots</li>
            </ul>
          </span>

          <span>
            <h2>Creator</h2>
            <p>AI side-kick for your creative endeavors</p>
            <div>
              <h1>$15</h1>
              <Button size="large" onClick={onClickCreator}>
                One-time purchase
              </Button>
            </div>
            <ul>
              <li>Unlimited storyboards</li>
              <li>1000 AI-generated shots</li>
            </ul>
          </span>

          <span>
            <h2>Enterprise</h2>
            <p>Customized AI for your business needs</p>
            <div>
              <h1>Flexible</h1>
              <Button size="large" variant="outline" onClick={onClickEnterprise}>
                Contact us
              </Button>
            </div>
            <ul>
              <li>Unlimited storyboards</li>
              <li>Unlimited image credits</li>
              <li>Customized style models</li>
              <li>Customized character models</li>
            </ul>
          </span>
        </div>
      </Div>
    </Layout>
  )
}

const Div = styled.div<{ listIcon: string }>`
  padding: 1rem;
  > h1 {
    font-size: 36px;
    line-height: 42px;
    text-align: center;
    color: ${({ theme }) => theme.colors.secondary_100};
  }
  > p {
    font-size: 16px;
    line-height: 150%;
    text-align: center;
    color: ${({ theme }) => theme.colors.secondary_100};
    margin-bottom: 1rem;
  }
  > div {
    display: flex;
    justify-content: center;
    gap: 1rem;
    margin-top: 1rem;
    > span {
      width: 350px;
      border: 1px solid #e9eaec;
      border-radius: 16px;
      padding: 1rem;
      div {
        display: flex;
        flex-direction: column;
        gap: 1rem;
        padding: 1rem 0;
        border: 1px solid ${({ theme }) => theme.colors.gray_40};
        border-left: none;
        border-right: none;
        margin-top: 1rem;
      }
      h2 {
        font-size: 20px;
        line-height: 140%;
        color: ${({ theme }) => theme.colors.secondary_100};
      }
      p {
        font-size: 16px;
        line-height: 21px;
        color: ${({ theme }) => theme.colors.gray_60};
        height: 21px;
      }
      h1 {
        font-size: 40px;
        line-height: 130%;
        color: ${({ theme }) => theme.colors.secondary_100};
      }
      ul {
        margin-top: 1rem;
        display: flex;
        flex-direction: column;
        gap: 0.5rem;
        list-style-image: url(${({ listIcon }) => listIcon});
        list-style-position: inside;
      }
    }
  }
`
