import { storyboardClient } from 'features/http'
import { StoryboardView } from 'features/storyboard/components/Storyboard/StoryboardView'
import {
  useGetResources,
  useGetResourcesImmutable,
  useGetResourcesInfinite,
} from 'features/storyboard/hooks/useGetResources'
import { useInlineStoryboardScenesEvent } from 'features/storyboard/hooks/useInlineStoryboardScenesEvent'
import { storyboardPaths } from 'features/storyboard/storyboard.api'
import {
  IStoryBoard,
  IStoryBoardOptions,
  IStoryBoardScene,
  IStoryBoardScenes,
} from 'features/storyboard/storyboard.types'
import { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { LoadingBox } from 'shared/components/ApiStates/Loading/LoadingBox'
import { pagesRoutesEnum } from './Router/router.enums'

export const StoryboardPage = () => {
  const { id: storyboardId } = useParams<{ id: string }>()
  const [scenes, setScenes] = useState<IStoryBoardScene[]>([])
  const [visualScenes, setVisualScenes] = useState<IStoryBoardScene[]>([])

  useEffect(() => {
    setVisualScenes(scenes.filter((scene) => scene.script_units[0].visualizable_units.length > 0))
  }, [scenes])

  const navigate = useNavigate()
  // Whenever we get a completion event from Firebase, we need to mutate the scenes.
  // This happens when the user explicitly requests more scenes after the first pass.
  const { activeEvent, activeEventId } = useInlineStoryboardScenesEvent(storyboardId)

  // Useful for debugging misbehaving state.
  useEffect(() => {
    console.log('scenes=', scenes)
  }, [scenes])
  useEffect(() => {
    console.log('visualScenes=', visualScenes)
  }, [visualScenes])

  const { data: storyboard, mutate: mutateStoryboard } = useGetResourcesImmutable<IStoryBoard>(
    storyboardPaths.storyboardMetadata(storyboardId || ''),
    {
      onSuccess() {
        mutateScenes()
      },
      onError(error) {
        console.log('Error loading storyboard metadata.', error)
        navigate(pagesRoutesEnum.root)
      },
    },
  )

  const { mutate: mutateOptions } = useGetResources<IStoryBoardOptions>(storyboardPaths.options)

  const scenesPerRequest = 10
  const {
    mutate: mutateScenes,
    size,
    setSize,
  } = useGetResourcesInfinite<IStoryBoardScenes>(
    (pageIndex: number) =>
      storyboardPaths.getScenes(storyboardId || '', pageIndex * scenesPerRequest, scenesPerRequest),
    {
      initialSize: 1, // the number of pages to be loaded initially
      revalidateFirstPage: false,
      revalidateAll: false,
      // Removing revaliation on focus is *very* important, otherwise setScenes() inside of onSuccess() will overwrite
      // scenes with potentially old data.
      revalidateOnFocus: false,
      parallel: true,
      onSuccess(newResponses) {
        if (storyboard && size * scenesPerRequest < storyboard.total) {
          setSize(size + 1)
        }
        setScenes(newResponses?.flatMap((response) => response.scenes) || [])
      },
      onError(error) {
        console.log('Error fetching scenes.', error)
      },
    },
  )

  async function refetchScene(idx: number) {
    if (storyboard) {
      const response = await storyboardClient.get(storyboardPaths.getScenes(storyboard.id, idx, 1))
      const newScene = response.data.scenes[0]
      console.log('new scene', newScene)
      setScenes((oldScenes) => [...oldScenes.slice(0, idx), newScene, ...oldScenes.slice(idx + 1)])
    }
  }

  // Mutate scenes after we get a completion event from Firebase.
  useEffect(() => {
    if (activeEventId == storyboard?.id && activeEvent && activeEvent.total > 0) {
      mutateStoryboard()
      mutateOptions()
    }
  }, [activeEvent])

  if (!storyboardId) {
    navigate(pagesRoutesEnum.home)
    return <></>
  }

  if (!storyboard || scenes.length == 0) {
    return <LoadingBox />
  }

  return (
    <div className="h-full">
      <StoryboardView storyboard={storyboard} scenes={scenes} visualScenes={visualScenes} refetchScene={refetchScene} />
    </div>
  )
}
