import { getAnalytics } from 'firebase/analytics'
import { FirebaseApp, initializeApp } from 'firebase/app'
import { Auth, getAuth } from 'firebase/auth'
import { Firestore, getFirestore } from 'firebase/firestore'
import { useRef } from 'react'

export const useFirebase = () => {
  const app = useRef<null | FirebaseApp>(null)
  const auth = useRef<null | Auth>(null)
  const db = useRef<null | Firestore>(null)

  const firebaseConfig = {
    apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
    authDomain: 'storiaboard-efb12.firebaseapp.com',
    projectId: 'storiaboard-efb12',
    storageBucket: 'storiaboard-efb12.appspot.com',
    messagingSenderId: '829066034691',
    appId: '1:829066034691:web:e7031a500e16ba79b45836',
    measurementId: 'G-N2MWC8DNZX',
    databaseURL: 'https://storiaboard-efb12-default-rtdb.firebaseio.com/',
  }

  app.current = initializeApp(firebaseConfig)
  const analytics = getAnalytics(app.current)
  auth.current = getAuth(app.current)
  db.current = getFirestore(app.current)

  return { app: app.current, auth: auth.current, db: db.current, analytics }
}
