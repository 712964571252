import { recordChangePageEvent } from 'features/eventTracking/eventTracking.api'
import { changePageInteractionEventsEnum } from 'features/eventTracking/eventTracking.enums'
import StoryCard from 'features/storyboard/components/Storyboard/StoryCard'
import { IStoryBoardSlim } from 'features/storyboard/storyboard.types'
import { pagesRoutesEnum } from 'pages/Router/router.enums'
import { useNavigate } from 'react-router-dom'
import styled from 'styled-components'
interface IProps {
  stories: IStoryBoardSlim[]
  deletable?: boolean
}
export const StoriesList = ({ stories, deletable }: IProps) => {
  const navigate = useNavigate()

  return (
    <Div>
      {stories?.map((story) => {
        return (
          <div
            key={story.id}
            className="content-story cursor-pointer hover:shadow-3 relative"
            onClick={() => {
              if (!deletable) {
                recordChangePageEvent({
                  page: window.location.pathname,
                  to: `${pagesRoutesEnum.storyboard}/${story.id}`,
                  interactionElement: changePageInteractionEventsEnum.viewInspirationStory,
                  storyId: story.id,
                })
              }
              navigate(`${pagesRoutesEnum.storyboard}/${story.id}`)
            }}
          >
            <StoryCard story={story} deletable={deletable} />
          </div>
        )
      })}
    </Div>
  )
}

const Div = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
  padding-top: 1rem;

  position: relative;
  min-height: 150px;

  > .content-story {
    width: 266.99px;
    min-height: 246.16px;
  }

  .card-details {
    position: relative;

    .card-title {
      max-height: 100px;
      overflow-y: auto;
      word-break: break-all;
    }
  }
`
