import { recordStoryActionEvent } from 'features/eventTracking/eventTracking.api'
import { interactionEventsEnum } from 'features/eventTracking/eventTracking.enums'
import { storyboardModeAtom } from 'features/storyboard/storyboard.state'
import { IStoryBoard } from 'features/storyboard/storyboard.types'
import { STOWAWAY_MODIFIERS } from 'features/storyboard/storyboard.utils'
import { useAtom } from 'jotai'
import { pagesRoutesEnum } from 'pages/Router/router.enums'
import { PrimeIcons } from 'primereact/api'
import { Button } from 'primereact/button'
import { useNavigate } from 'react-router-dom'
import styled from 'styled-components'

interface IProps {
  storyboard: IStoryBoard
}

export const StoryboardTopBar = ({ storyboard }: IProps) => {
  const navigate = useNavigate()
  const [mode, setMode] = useAtom(storyboardModeAtom)

  const onBack = () => {
    recordStoryActionEvent({
      interactionElement: interactionEventsEnum.storyBoardBackButton,
      storyBoardId: storyboard?.id,
    })
    if (mode == 'view') {
      navigate(pagesRoutesEnum.root)
    } else {
      setMode('view')
    }
  }

  return (
    <StyledNav className="title px-4 flex align-items-center text-xl border-bottom-1 border-300 max-w-screen justify-content-between">
      <div className="flex align-items-center gap-2 px-3">
        <Button className="circle-button" onClick={onBack} icon={PrimeIcons.ARROW_LEFT} rounded={true} />
        <h1 className="text-lg text-overflow-ellipsis white-space-nowrap overflow-hidden">{storyboard?.title}</h1>
      </div>

      <div className="flex flex-row align-items-center">
        {storyboard?.styles.map((style, index) => (
          <div className="storyboard-style" key={index}>
            {style.title}
          </div>
        ))}
        {/* TODO(julia): Remove this hack for CogX. */}
        {storyboard?.custom_styles
          .filter((style) => !STOWAWAY_MODIFIERS.includes(style))
          .map((style, index) => (
            <div className="storyboard-style" key={index}>
              {style}
            </div>
          ))}
      </div>
    </StyledNav>
  )
}

const StyledNav = styled.nav`
  z-index: 999;
  background-color: ${({ theme }) => theme.colors.white};
  border-bottom: 1px solid #ecedee;
  color: ${({ theme }) => theme.colors.black};
  height: ${({ theme }) => theme.constants.navbarHeight};

  .storyboard-style {
    color: ${({ theme }) => theme.colors.secondary_100};
    border: 1px solid ${({ theme }) => theme.colors.gray_40};
    border-radius: 8px;
    padding: 5px 10px;
    font-size: 1rem;
    margin-left: 5px;
    border-bottom: 1px solid #ecedee;
  }

  .circle-button {
    border: 0px;
    background: ${({ theme }) => theme.colors.white};
    background-color: ${({ theme }) => theme.colors.white};
    transition: background-color 300ms ease-out;

    &:hover {
      background-color: ${({ theme }) => theme.colors.gray_40};
    }

    &:focus {
      box-shadow: none;
    }
  }
`
