import { pagesRoutesEnum } from 'pages/Router/router.enums'
import { useLocation, useNavigate } from 'react-router-dom'
import { Button } from 'shared/components/Buttons/Button'

export const NotSignedInUserActions = () => {
  const navigate = useNavigate()
  const location = useLocation()
  const authRoutes = [pagesRoutesEnum.login, pagesRoutesEnum.register] as string[]
  const hideActions = authRoutes.includes(location.pathname)
  return hideActions ? (
    <></>
  ) : (
    <div className="flex gap-3">
      <Button onClick={() => navigate(pagesRoutesEnum.login)}>Sign in</Button>
      <Button variant="outline" onClick={() => navigate(pagesRoutesEnum.register)}>
        Sign up
      </Button>
    </div>
  )
}
