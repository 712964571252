import {
  IStoryBoardScriptUnit,
  IStoryBoardScriptUnitFrame,
  IStoryBoardVisualizableUnit,
} from 'features/storyboard/storyboard.types'

function findFirstVisualizableUnitWithFrames(
  script_units: IStoryBoardScriptUnit[],
): IStoryBoardVisualizableUnit | null {
  for (const script_unit of script_units) {
    for (const vunit of script_unit.visualizable_units) {
      if (vunit.frames.length > 0) {
        return vunit
      }
    }
  }
  return null
}

function findFirstFrameWithImage(vunit: IStoryBoardVisualizableUnit | null): IStoryBoardScriptUnitFrame | null {
  if (vunit === null) {
    return null
  }
  for (const frame of vunit.frames) {
    if (frame.link) {
      return frame
    }
  }
  return null
}

export { findFirstVisualizableUnitWithFrames, findFirstFrameWithImage }
