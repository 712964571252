import { atom } from 'jotai'
import { initialStoryForm } from './storyboard.model'
import {
  ICreateStoryBoardDemo,
  IStoryBoardScene,
  IStoryBoardScriptUnit,
  IStoryBoardScriptUnitFrame,
  IStoryBoardVisualizableUnit,
} from './storyboard.types'

export const activeStepAtom = atom(0)
export const storyFormAtom = atom<ICreateStoryBoardDemo>(initialStoryForm)

export const storyboardEventIdsAtom = atom<string[]>([])

export interface IActiveScene {
  sceneIdx: number
  changedBy: 'sidebar' | 'scrollspy' | 'arrows' | 'toolbar_interaction' | null
}
export const activeSceneAtom = atom<IActiveScene>({
  // Index within what is show on the screen (i.e. scenes with visualizations), NOT within *all* scenes.
  sceneIdx: 0,
  changedBy: null,
})

export const storyboardModeAtom = atom<'view' | 'edit' | 'add_shot' | 'upload_shot'>('view')

export interface ImageSize {
  width: number
  height: number
}
export const storyboardImageSizeAtom = atom<ImageSize>({ width: 0, height: 0 })

export interface ISceneChange {
  scene?: IStoryBoardScene
  scriptUnit?: IStoryBoardScriptUnit
  vUnit?: IStoryBoardVisualizableUnit
  frame?: IStoryBoardScriptUnitFrame
  callback?: () => void
}
export const sceneChangeAtom = atom<ISceneChange>({
  scene: undefined,
  scriptUnit: undefined,
  vUnit: undefined,
  frame: undefined,
  callback: undefined,
})
