export enum pagesRoutesEnum {
  root = '/',
  home = '/',
  create = '/create',
  login = '/login',
  register = '/register',
  subscriptions = '/pricing',
  storyboard = '/storyboard',
  projects = '/projects',
  inspiration = '/inspiration',
  paymentSuccess = '/payment/success',
  paymentFailed = '/payment/failed',
}
