import useSWR, { SWRConfiguration } from 'swr'
import useSWRImmutable from 'swr/immutable'
import useSWRInfinite, { SWRInfiniteConfiguration } from 'swr/infinite'
import { getStoryResource } from '../storyboard.api'
/**
 * @parameter key is the api path of the resource
 * Usage example
 *  const { data, isLoading, error } = useGetResources<IStoryBoard>(storyboardPaths.storyboard('x'))
 */
export const useGetResources = <T>(key: string | null, config: SWRConfiguration<T> = {}) => {
  const { data, error, mutate, isLoading, isValidating } = useSWR(key, getStoryResource<T>, { ...config })

  return { data, isLoading, error, mutate, isValidating }
}

/**
 * @parameter key is the api path of the resource
 * Usage example
 *  const { data, isLoading, error } = useGetResources<IStoryBoard>(storyboardPaths.storyboard('x'))
 */
export const useGetResourcesImmutable = <T>(key: string | null, config: SWRConfiguration<T> = {}) => {
  const { data, error, mutate, isValidating, isLoading } = useSWRImmutable(key, getStoryResource<T>, { ...config })

  return { data, isLoading, error, mutate, isValidating }
}

/**
 * See https://swr.vercel.app/docs/pagination.
 *
 * @param getKey is a function that accepts the index and the previous page data, returns the key of a page
 * @param config accepts all the options that useSWR supports, with 4 extra options
 * Usage example
 *  const { data, isLoading } = useGetResources<IStoryBoardScenes>(storyboardPaths.scenes('x'))
 */
export const useGetResourcesInfinite = <T>(getKey: any, config: SWRInfiniteConfiguration<T> = {}) => {
  const { data, error, isLoading, isValidating, mutate, size, setSize } = useSWRInfinite(getKey, getStoryResource<T>, {
    ...config,
  })

  return { data, isLoading, size, setSize, error, isValidating, mutate }
}
