import { useGetResources } from 'features/storyboard/hooks/useGetResources'
import { storyboardPaths } from 'features/storyboard/storyboard.api'
import { IPaginated, IStoryBoardSlim } from 'features/storyboard/storyboard.types'
import { pagesRoutesEnum } from 'pages/Router/router.enums'
import { useNavigate } from 'react-router-dom'
import { NoContentBox } from 'shared/components/ApiStates/EmptyResponse/NoContentBox'
import { LoadingBox } from 'shared/components/ApiStates/Loading/LoadingBox'
import { Button } from 'shared/components/Buttons/Button'
import styled from 'styled-components'
import { StoriesList } from './StoriesList'
import { StoriesWidget } from './StoriesWidget'

export const InspiringStoriesWidget = () => {
  const navigate = useNavigate()
  const { data, isLoading, error } = useGetResources<IPaginated<IStoryBoardSlim[]>>(
    `${storyboardPaths.exploreStories}?page=1&page_size=4`,
  )
  const stories = data?.stories || []
  const totalItems = data?.total_items || 0
  const maxStoryboardsToShow = 4

  if (isLoading) return <LoadingBox />
  return (
    <StoriesWidget
      title="Sample storyboards"
      action={
        totalItems > maxStoryboardsToShow ? (
          <Button variant="ghost" className="underline" onClick={() => navigate(pagesRoutesEnum.projects)}>
            View all
          </Button>
        ) : (
          <></>
        )
      }
    >
      <div className="wrapper">
        {data?.stories?.length ? (
          <StoriesList stories={stories.slice(0, maxStoryboardsToShow)} deletable={false} />
        ) : (
          <></>
        )}
      </div>
    </StoriesWidget>
  )
}
