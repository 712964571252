import cx from 'classnames'
import { Tooltip as PRTooltip, TooltipProps } from 'primereact/tooltip'

interface IProps extends TooltipProps {
  text: string
  icon: JSX.Element
  spanClassName: string
}

export const Tooltip = ({ text, icon, spanClassName, ...props }: IProps) => {
  return (
    <div>
      <PRTooltip target=".tooltip-target" {...props} />
      <span className={cx('tooltip-target flex align-items-center ', spanClassName)} data-pr-tooltip={text}>
        {icon}
      </span>
    </div>
  )
}
