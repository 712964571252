import useSWRMutation, { SWRMutationConfiguration } from 'swr/mutation'
import { putSceneResource } from '../scene.api'

/**
 * @parameter key is the api path of the resource
 * Usage example
 *  const { trigger, isMutating } = usePostMultiPartResource<ICreateStoryBoard, IStoryBoard>(storyboardPaths.storyboard('x'))
 */
export const usePutSceneResource = <T, K>(
  key: string,
  config: SWRMutationConfiguration<K, any, T, string, any> | undefined = {},
) => {
  const { trigger, isMutating } = useSWRMutation(key, putSceneResource<T, K>, { ...config })

  return { trigger, isMutating }
}
