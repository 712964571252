import { StoriesList } from 'features/storyboard/components/Storyboard/StoriesList'
import { useGetResourcesImmutable } from 'features/storyboard/hooks/useGetResources'
import { storyboardPaths } from 'features/storyboard/storyboard.api'
import { IStoryBoardSlim } from 'features/storyboard/storyboard.types'
import { NoContentBox } from 'shared/components/ApiStates/EmptyResponse/NoContentBox'
import { LoadingBox } from 'shared/components/ApiStates/Loading/LoadingBox'
import { Layout } from 'theme/Layouts/Layout'

export const Inspiration = () => {
  const { data, isLoading } = useGetResourcesImmutable<{ stories: IStoryBoardSlim[] }>(
    `${storyboardPaths.exploreStories}?page_size=99999`,
  )
  const stories = data?.stories || []

  if (isLoading) return <LoadingBox />

  return (
    <Layout>
      <div className="p-6">
        <p>
          We took the liberty to reenvision some classics. Picasso was right, anything you can imagine <i>is</i> real.
        </p>
        {stories.length ? (
          <StoriesList stories={stories} deletable={false} />
        ) : (
          <NoContentBox message="Apologies, we're having trouble loading the storyboards." />
        )}
      </div>
    </Layout>
  )
}
