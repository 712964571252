import { storyFormAtom } from 'features/storyboard/storyboard.state'
import { useAtom } from 'jotai'
import { InputText } from 'primereact/inputtext'
import { InputTextarea } from 'primereact/inputtextarea'
import { useEffect, useState } from 'react'

function ScriptPaster() {
  const [title, setTitle] = useState('')
  const [text, setText] = useState('')
  const [storyForm, setStoryForm] = useAtom(storyFormAtom)

  useEffect(() => {
    setStoryForm((prev) => ({ ...prev, title: title }))
  }, [title])

  useEffect(() => {
    setStoryForm((prev) => ({ ...prev, text: text }))
  }, [text])

  return (
    <div className="flex flex-column gap-4 mt-4" style={{ width: '900px' }}>
      <span className="p-float-label">
        <InputText id="title" value={title} onChange={(e) => setTitle(e.target.value)} style={{ width: '100%' }} />
        <label htmlFor="title">Title</label>
      </span>

      <InputTextarea
        id="content"
        value={text}
        onChange={(e) => setText(e.target.value)}
        style={{ width: '100%', maxWidth: '900px', height: '300px' }}
        placeholder="Paste your script here. Separate scenes by new lines."
      />
    </div>
  )
}

export default ScriptPaster
