import cx from 'classnames'
import { usePutSceneResource } from 'features/scene/hooks/usePutSceneResource'
import { scenePaths } from 'features/scene/scene.api'
import { IApplyScene } from 'features/scene/scene.types'
import {
  IStoryBoard,
  IStoryBoardScriptUnitFrame,
  IStoryBoardVisualizableUnit,
} from 'features/storyboard/storyboard.types'
import sceneImagePlaceholderSrc from '../../assets/orange-on-white-logo.svg'

interface StoryboardFramesHistoryProps {
  visuableUnit: IStoryBoardVisualizableUnit
  selectedFrame: IStoryBoardScriptUnitFrame
  setSelectedFrame: any
}

export const StoryboardFramesHistory = (props: StoryboardFramesHistoryProps) => {
  const { trigger: triggerMarkSelected } = usePutSceneResource<IApplyScene, IStoryBoard>(
    scenePaths.frame(props.selectedFrame?.id || ''),
  )

  return (
    <div className="flex align-items-center gap-2">
      <div className="history-links flex-1 flex align-items-center gap-2 overflow-x-auto mx-2">
        {props.visuableUnit.frames.map((frame) => (
          <span
            key={frame.id}
            onClick={() => {
              triggerMarkSelected({ is_selected: true, visualizable_unit: props.visuableUnit.id })
              props.setSelectedFrame(frame)
            }}
          >
            <img
              src={frame.link ? frame.link : sceneImagePlaceholderSrc}
              alt="Transcript image"
              width={32}
              height={32}
              className={cx('border-round-sm cursor-pointer', {
                'border-1 border-800': frame.id === props.selectedFrame?.id,
                'opacity-60': frame.id !== props.selectedFrame?.id,
              })}
            />
          </span>
        ))}
      </div>
    </div>
  )
}
