import { PrimitiveAtom, useAtom } from 'jotai'
import { onDataStringChange } from 'shared/utils/handlers'
import styled from 'styled-components'
import noneOption from '../../../assets/noneOption.png'
import { IRegenerateScene } from '../scene.types'
import SelectItem from './SelectItem'

interface ILightningTab {
  isGenerating: boolean
  formAtom: PrimitiveAtom<IRegenerateScene>
}

const LightningTab = ({ isGenerating, formAtom }: ILightningTab) => {
  const [form, setForm] = useAtom(formAtom)
  const { lightning } = form
  const lighteningOptions = [
    {
      title: '',
      image: noneOption,
    },
    {
      title: 'Key',
      image: 'https://storia-ai.s3.amazonaws.com/lighting/key-lighting.png',
    },
    {
      title: 'Fill',
      image: 'https://storia-ai.s3.amazonaws.com/lighting/fill-lighting.png',
    },
    {
      title: 'Back',
      image: 'https://storia-ai.s3.amazonaws.com/lighting/back-lighting.png',
    },
    {
      title: 'Side',
      image: 'https://storia-ai.s3.amazonaws.com/lighting/side-lighting.png',
    },
    {
      title: 'Practical',
      image: 'https://storia-ai.s3.amazonaws.com/lighting/practical-lighting.png',
    },
    {
      title: 'Hard',
      image: 'https://storia-ai.s3.amazonaws.com/lighting/hard-lighting.png',
    },
    {
      title: 'Soft',
      image: 'https://storia-ai.s3.amazonaws.com/lighting/soft-lighting.png',
    },
    {
      title: 'High Key',
      image: 'https://storia-ai.s3.amazonaws.com/lighting/high-key-lighting.png',
    },
    {
      title: 'Low Key',
      image: 'https://storia-ai.s3.amazonaws.com/lighting/low-key-lighting.png',
    },
  ]

  const onChange = (item: any) => {
    if (!isGenerating) {
      onDataStringChange(setForm)('lightning', item.title)
    }
  }
  return (
    <Div>
      <h2>Select your preferred lightning style.</h2>
      <div className="options">
        {lighteningOptions.map((item, index) => (
          <SelectItem onClick={() => onChange(item)} selected={item.title === lightning} item={item} key={index} />
        ))}
      </div>
    </Div>
  )
}

const Div = styled.div`
  color: ${({ theme }) => theme.colors.secondary_100};
  height: 100%;
  h2 {
    font-weight: 600;
    font-size: 21px;
    line-height: 132%;
    margin: 2rem 0;
    margin-top: 1rem;
  }
  .options {
    max-height: 100%;
    display: grid;
    overflow: auto;
    gap: 1.5rem;
    grid-template-columns: repeat(auto-fill, minmax(136px, 1fr));
  }
`

export default LightningTab
