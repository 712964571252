import sceneImagePlaceholderSrc from 'assets/orange-on-white-logo.svg'
import cx from 'classnames'
import { storyboardClient } from 'features/http'
import { useDeleteResource } from 'features/storyboard/hooks/useDeleteResources'
import { useGetResources } from 'features/storyboard/hooks/useGetResources'
import { storyboardPaths } from 'features/storyboard/storyboard.api'
import {
  ImageSize,
  activeSceneAtom,
  sceneChangeAtom,
  storyboardImageSizeAtom,
  storyboardModeAtom,
} from 'features/storyboard/storyboard.state'
import {
  IStoryBoard,
  IStoryBoardOptions,
  IStoryBoardScene,
  IStoryBoardScriptUnit,
  IStoryBoardScriptUnitFrame,
  IStoryBoardVisualizableUnit,
} from 'features/storyboard/storyboard.types'
import 'index.css'
import { useAtom, useAtomValue, useSetAtom } from 'jotai'
import UploadShotModal from 'pages/UploadShotModal'
import { Accordion, AccordionTab, AccordionTabCloseEvent, AccordionTabOpenEvent } from 'primereact/accordion'
import { PrimeIcons } from 'primereact/api'
import { Menubar } from 'primereact/menubar'
import { Ref, createRef, memo, useEffect, useMemo, useRef, useState } from 'react'
import { BsInfo } from 'react-icons/bs'
import useScrollSpy from 'react-use-scrollspy'
import { Button } from 'shared/components/Buttons/Button'
import { Tooltip } from 'shared/components/Tooltip/Tooltip'
import { moveToElement } from 'shared/utils/dom.utils'
import styled from 'styled-components'
import 'theme/styles/accordion.css'
import { scenePaths } from '../../../scene/scene.api'
import ChangeStyleModal from './ChangeStyleModal'
import { StoryboardDownload } from './StoryboardDownload'
import { StoryboardFramesHistory } from './StoryboardFramesHistory'
import { StoryboardSceneAction } from './StoryboardSceneAction'

interface VUnitProps {
  storyboard: IStoryBoard
  scene: IStoryBoardScene
  scriptUnit: IStoryBoardScriptUnit
  vUnit: IStoryBoardVisualizableUnit
  creditsLeft: number
  refetchScene: () => void
}

function getSelectedFrame(frames: IStoryBoardScriptUnitFrame[]) {
  if (frames.length === 0) {
    return null
  }
  const frameIdx = frames.findIndex((frame) => frame.is_selected)
  if (frameIdx >= 0) {
    return frames[frameIdx]
  } else {
    return frames[frames.length - 1]
  }
}

function VisualUnitComponent(props: VUnitProps) {
  const frames = props.vUnit.frames
  const [selectedFrame, setSelectedFrame] = useState<IStoryBoardScriptUnitFrame | null>(getSelectedFrame(frames))
  const [activeScene, setActiveScene] = useAtom(activeSceneAtom)

  useEffect(() => {
    setSelectedFrame(getSelectedFrame(frames))
  }, [frames])

  const imageSrc = selectedFrame?.link
  const isPending = selectedFrame && selectedFrame.link === null && !selectedFrame.failed_to_generate
  const hasFrame = selectedFrame?.id != undefined && selectedFrame.link != null
  const vUnitDeletable = props.scriptUnit.visualizable_units.length > 1
  const storyboardImageSize = useAtomValue<ImageSize>(storyboardImageSizeAtom)

  const setMode = useSetAtom(storyboardModeAtom)
  const setSceneChange = useSetAtom(sceneChangeAtom)

  const editShot = () => {
    if (selectedFrame) {
      setSceneChange({
        scene: props.scene,
        scriptUnit: props.scriptUnit,
        vUnit: props.vUnit,
        frame: selectedFrame,
        callback: props.refetchScene,
      })
      setActiveScene({ ...activeScene, changedBy: 'toolbar_interaction' })
      setMode('edit')
    }
  }
  const addShot = () => {
    setSceneChange({
      scene: props.scene,
      scriptUnit: props.scriptUnit,
      vUnit: props.vUnit,
      callback: props.refetchScene,
    })
    setActiveScene({ ...activeScene, changedBy: 'toolbar_interaction' })
    setMode('add_shot')
  }

  const [uploadModalIsVisible, setUploadModalIsVisible] = useState<boolean>(false)

  const { trigger: triggerDeleteVUnit } = useDeleteResource<void, void>(scenePaths.visualUnit(props.vUnit.id), {
    onSuccess: () => {
      console.log('Successfully deleted vunit in the BE')
    },
    onError: (error) => {
      console.error('Failed to delete vunit in the BE:', error)
    },
  })

  const { trigger: triggerDeleteFrame } = useDeleteResource<void, void>(scenePaths.frame(selectedFrame?.id || ''), {
    onSuccess: async () => {
      console.log('Successfully deleted frame in the BE')
      if (frames.length == 1 && vUnitDeletable) {
        await triggerDeleteVUnit()
      }
      props.refetchScene()
    },
    onError: (error) => {
      console.error('Failed to delete frame in the BE:', error)
    },
  })

  const menuItems: any = [
    {
      label: 'Edit',
      icon: PrimeIcons.PENCIL,
      disabled: isPending,
      visible: hasFrame,
      command: editShot,
    },
    {
      label: 'Delete',
      icon: PrimeIcons.TRASH,
      disabled: isPending,
      visible: hasFrame || vUnitDeletable,
      command: triggerDeleteFrame,
    },
    {
      label: 'Add shot',
      icon: PrimeIcons.PLUS,
      disabled: isPending,
      visible: true,
      items: [
        { label: 'AI-generated', icon: PrimeIcons.BOLT, command: addShot, disabled: props.creditsLeft <= 0 },
        {
          label: 'Upload',
          icon: PrimeIcons.UPLOAD,
          command: () => {
            setUploadModalIsVisible(true)
          },
        },
      ],
    },
  ]

  return (
    <div key={props.vUnit.id} className="flex align-items-center justify-content-center py-2">
      {/* Contains a single shot and its carousel of alternative images. */}
      <div className="flex flex-column align-items-center justify-content-center shadow-3 border-round-xl">
        {/* This relative div is needed for the buttons show up in the top right of the image, not of the enclosing div. */}
        <div className="relative">
          <img
            src={imageSrc ? imageSrc : sceneImagePlaceholderSrc}
            alt="AI-generated cinematic shot"
            className={cx(frames.length > 1 ? 'border-round-top-xl' : 'border-round-xl')}
            style={{
              display: 'block',
              maxHeight: Math.min(600, storyboardImageSize.height) || 512 + 'px',
              maxWidth: Math.min(800, storyboardImageSize.width) || 512 + 'px',
            }}
          />
          {/* Note that this will display buttons on a placeholder image (when imgSrc is null). That is by design. */}
          {!props.storyboard.is_inspirational && !isPending && (
            <div>
              <div className="absolute top-0 right-0 flex justify-content-right px-2 py-2">
                <UploadShotModal
                  isVisible={uploadModalIsVisible}
                  setIsVisible={setUploadModalIsVisible}
                  scriptUnit={props.scriptUnit}
                  vUnit={props.vUnit}
                  refetchScene={props.refetchScene}
                >
                  <Menubar model={menuItems} />
                </UploadShotModal>
              </div>

              {hasFrame && (
                <div className="absolute bottom-0 right-0 flex justify-content-right shadow-3 bg-white border-round-3xl px-1 py-1 m-2">
                  <Tooltip
                    spanClassName="px-0"
                    text={selectedFrame?.prompt || ''}
                    icon={<BsInfo className="black" size="20" />}
                  />
                </div>
              )}
            </div>
          )}
        </div>
        {/* Optional carousel of alternative images. */}
        {!props.storyboard.is_inspirational && frames.length > 1 && selectedFrame && (
          <div className="pt-2 pb-1 bg-white flex align-items-center">
            <StoryboardFramesHistory
              visuableUnit={props.vUnit}
              selectedFrame={selectedFrame}
              setSelectedFrame={setSelectedFrame}
            />
          </div>
        )}
      </div>
    </div>
  )
}

interface SceneProps {
  storyboard: IStoryBoard
  sceneIdx: number
  scene: IStoryBoardScene
  sectionRef: Ref<HTMLDivElement>
  creditsLeft: number
  refetchScene: () => void
}

function SceneComponent(props: SceneProps) {
  const [vUnits, setVUnits] = useState<IStoryBoardVisualizableUnit[]>([])
  useEffect(() => {
    setVUnits(props.scene.script_units[0].visualizable_units)
  }, [props.scene])

  const [indicesOfExpandedUnits, setIndicesOfExpandedUnits] = useState<number[]>([])
  useEffect(() => {
    const indices = []
    for (let i = 0; i < vUnits.length; i++) {
      if (!vUnits[i].is_hidden) {
        indices.push(i)
      }
    }
    setIndicesOfExpandedUnits(indices)
  }, [vUnits])

  // Hack to force the accordion to re-render when the indices change.
  const [accordionKey, setAccordionKey] = useState<number>(0)
  useEffect(() => {
    setAccordionKey((prevKey) => prevKey + 1)
  }, [indicesOfExpandedUnits])

  return (
    <div
      className={cx('transcript-row grid align-items-start px-4 py-2')}
      key={props.sceneIdx}
      id={`frame-${props.sceneIdx}`}
      ref={props.sectionRef}
    >
      {/* Div for the image column */}
      <div className={'col-12 md:col-6'}>
        {props.storyboard.is_inspirational ? (
          <div>
            {vUnits?.map((vUnit, vIdx) => (
              <VisualUnitComponent
                key={vIdx}
                storyboard={props.storyboard}
                scene={props.scene}
                scriptUnit={props.scene.script_units[0]}
                vUnit={vUnit}
                creditsLeft={props.creditsLeft}
                refetchScene={props.refetchScene}
              />
            ))}
          </div>
        ) : (
          <Accordion
            key={accordionKey}
            multiple
            activeIndex={indicesOfExpandedUnits}
            onTabOpen={(e: AccordionTabOpenEvent) => {
              const vUnit = vUnits[e.index]
              storyboardClient.post(scenePaths.setVUnitIsHidden(vUnit.id, false))
              vUnit.is_hidden = false // Local update, to avoid re-fetching
            }}
            onTabClose={(e: AccordionTabCloseEvent) => {
              const vUnit = vUnits[e.index]
              storyboardClient.post(scenePaths.setVUnitIsHidden(vUnits[e.index].id, true))
              vUnit.is_hidden = true // Local update, to avoid re-fetching
            }}
          >
            {vUnits?.map((vUnit, vIdx) => (
              // Problem: When UPLOADING a new shot, the tab is not expanded by default.
              // (In contrast, when GENERATING a new shot, the behavior is correct.)
              <AccordionTab key={vIdx} header={`Scene ${props.scene.index + 1}, Shot ${vIdx + 1}`}>
                <VisualUnitComponent
                  storyboard={props.storyboard}
                  scene={props.scene}
                  scriptUnit={props.scene.script_units[0]}
                  vUnit={vUnit}
                  creditsLeft={props.creditsLeft}
                  refetchScene={props.refetchScene}
                />
              </AccordionTab>
            ))}
          </Accordion>
        )}
      </div>

      <StoryboardSceneAction scene={props.scene} />
    </div>
  )
}

const MemoSceneComponent = memo(SceneComponent, (prev, next) => {
  return prev.scene === next.scene
})

interface StoryboardTranscriptProps {
  storyboard: IStoryBoard
  visualScenes: IStoryBoardScene[]
  refetchScene: (idx: number) => void
}

export const StoryboardTranscript = (props: StoryboardTranscriptProps) => {
  const [activeScene, setActiveScene] = useAtom(activeSceneAtom)

  // We will only listen to the scrollspy when the mouse is over the storyboard transcript.
  const [isMouseOver, setIsMouseOver] = useState(false)

  // Register the mouse listeners after the component *first* renders.
  useEffect(() => {
    const storyboardElement = document.getElementById('storyboard-transcript')
    const mouseOverListener = (e: Event) => {
      setIsMouseOver(true)
    }
    const mouseLeaveListener = (e: Event) => {
      setIsMouseOver(false)
    }
    storyboardElement?.addEventListener('mouseover', mouseOverListener)
    storyboardElement?.addEventListener('mouseleave', mouseLeaveListener)

    // Remove event listeners when the component unmounts.
    return () => {
      storyboardElement?.removeEventListener('mouseover', mouseOverListener)
      storyboardElement?.removeEventListener('mouseleave', mouseLeaveListener)
    }
  }, [])

  // Move to the right scene when the component renders.
  useEffect(() => {
    if (activeScene.changedBy === 'toolbar_interaction' || activeScene.changedBy === null) {
      moveToElement(`frame-${activeScene.sceneIdx}`, 'storyboard-transcript')
    }
  })

  const sectionRefs = useMemo(() => props.visualScenes.map(createRef<HTMLDivElement>), [props.visualScenes])
  const scrollElement = useRef<HTMLDivElement>(null)

  const scrollSpy: number | undefined = useScrollSpy({
    activeSectionDefault: 0,
    sectionElementRefs: sectionRefs,
    // offsetPx doesn't seem to have any effect...
    scrollingElement: scrollElement,
  })

  useEffect(() => {
    if (scrollSpy && isMouseOver) {
      setActiveScene({
        sceneIdx: scrollSpy as number,
        changedBy: 'scrollspy',
      })
    }
  }, [scrollSpy])

  const { data: options } = useGetResources<IStoryBoardOptions>(storyboardPaths.options)

  return (
    <div>
      <Div ref={scrollElement} className="transcript" id="storyboard-transcript">
        {props.visualScenes.map((scene, sceneIdx) => {
          return (
            <MemoSceneComponent
              key={sceneIdx}
              storyboard={props.storyboard}
              sceneIdx={sceneIdx}
              scene={scene}
              sectionRef={sectionRefs[sceneIdx]}
              creditsLeft={options?.max_amount_of_scenes || 0}
              refetchScene={() => props.refetchScene(scene.index)}
            />
          )
        })}
      </Div>
      {!props.storyboard?.is_inspirational && (
        <footer
          className="flex gap-3 align-items-center justify-content-end grid"
          style={{ position: 'absolute', inset: 'auto 0 0' }}
        >
          <div className="col-6"></div>
          <div className="border-top-1 border-300 p-3 col-12 md:col-6 flex flex-wrap align-items-center gap-3 justify-content-center bg-white">
            {/* To save space on this bar, we're temporarily hiding the delete button. */}
            {/* <DeleteStoryboardModal id={id}>
                  <Button size="large" variant="outline">Delete</Button>
                </DeleteStoryboardModal> */}
            {/* <ChangeStyleModal id={storyboard?.id || ''}>
                  <Button>Change Style</Button>
                </ChangeStyleModal> */}
            {/* Not sure why, but nesting buttons under a span prevents them from getting stretched. */}
            <span>
              <StoryboardDownload scenes={props.visualScenes} />
            </span>
            <span>
              <ChangeStyleModal id={props.storyboard.id}>
                <Button>Change Style</Button>
              </ChangeStyleModal>
            </span>
            <span>
              <Button variant="outline">
                <a href="mailto:founders@storia.ai?subject=StoriaBoard Feedback">Send Feedback</a>
              </Button>
            </span>
            <span>
              <Button variant="outline">
                <a href="https://docs.google.com/forms/d/e/1FAIpQLSd35ACMRy8RXIDSommr0j-LF39D3eED5AqBprW8V4cnnPbbBw/viewform?usp=sharing">
                  Hire Human
                </a>
              </Button>
            </span>
          </div>
        </footer>
      )}
    </div>
  )
}

const Div = styled.div`
  position: relative;
  height: 100%;
  max-height: calc(100vh - 59px);
  max-width: 2440px;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: stretch;
  gap: 2rem;
  padding-top: 4vh;
  padding-bottom: 40vh;

  .p-accordion {
    color: red;
    backgroundcolor: red;

    .p-accordion-header {
      color: red;
      backgroundcolor: red;
    }
  }

  .p-menubar {
    border-radius: 1.5rem;
    padding: 3px;

    .p-menuitem {
      border-radius: 1.5rem;

      .p-menuitem-link {
        padding: 10px;

        &:hover {
          border-radius: 1.5rem;
        }
        &:not(:hover) {
          border-radius: 1.5rem;
        }
        &:focus {
          border-radius: 1.5rem;
          box-shadow: none !important;
        }
      }
    }
  }

  .transcript-row {
    .vertical-line {
      border-left: 1px solid ${({ theme }) => theme.colors.gray_40};
      height: 25px;
    }

    .white-button {
      border: 0px;
      border-radius: 1.5rem; // equivalent to border-round-3xl
      margin: 0px;
      background-color: ${({ theme }) => theme.colors.white};
      transition: background-color 300ms ease-out;

      &:hover {
        background-color: ${({ theme }) => theme.colors.gray_40};
      }

      &:focus {
        box-shadow: none !important;
      }
    }

    .history-links {
      img {
        width: 24px;
        height: 24px;
      }
    }
  }

  .downloadable {
    position: absolute;
    bottom: 100vh;
  }
`
