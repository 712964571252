import * as styled from 'styled-components'

export const PrimereactThemeResets = styled.createGlobalStyle`
  .p-checkbox .p-checkbox-box.p-highlight {
    border-color: ${({ theme }) => theme.colors.primary_100};
    background: ${({ theme }) => theme.colors.primary_100};
  }

  .p-fileupload .p-fileupload-content {
    border: 2px dashed ${({ theme }) => theme.colors.primary_30};
    background-color: ${({ theme }) => theme.colors.primary_2};
    border-radius: 4px;
  }

  .p-dropdown {
    font-weight: 400;
    font-size: 14px;
    color: ${({ theme }) => theme.colors.gray_85};
    border: 1px solid ${({ theme }) => theme.colors.gray_70};
    border-radius: 8px;
  }
  .p-steps-number {
    color: aquamarine;
  }
  .p-tag.p-tag-warning {
    background: ${({ theme }) => theme.colors.primary_100};
  }
  .p-multiselect {
    border: 1px solid ${({ theme }) => theme.colors.gray_70};
    font-weight: 400;
    font-size: 14px;
    color: ${({ theme }) => theme.colors.gray_85};
    border-radius: 8px;
    display: flex;
    align-items: center;
  }
  .p-dialog {
    border-radius: 30px;
  }
  .p-dialog .p-dialog-header {
    border-top-left-radius: 30px;
    border-top-right-radius: 30px;
  }
  .p-dialog .p-dialog-content:last-of-type,
  .p-dialog-footer {
    border-bottom-right-radius: 30px !important;
    border-bottom-left-radius: 30px !important;
  }
  .p-dialog-title {
    display: flex;
    justify-content: center;
  }
  .p-dialog-header-icons {
    position: absolute;
    right: 5px;
    top: 5px;
  }

  .p-tabview {
    height: calc(100% - 49px);
  }

  .p-tabview-panels {
    overflow: auto;
    max-height: 100%;
  }

  .p-tabview .p-tabview-nav li.p-highlight .p-tabview-nav-link {
    border-color: ${({ theme }) => theme.colors.primary_100};
    color: ${({ theme }) => theme.colors.primary_100};
  }
  .p-tabview .p-tabview-nav li .p-tabview-nav-link:not(.p-disabled):focus {
    box-shadow: none;
  }

  .p-progressbar {
    display: none;
  }

  .p-fileupload .p-fileupload-row > div {
    padding: 0;
  }

  .p-radiobutton {
    .p-radiobutton-box {
      &.p-highlight {
        background-color: ${({ theme }) => theme.colors.primary_100};
      }

      .p-radiobutton-icon {
        background-color: ${({ theme }) => theme.colors.gray_85};
      }
    }
  }
  .p-datatable .p-datatable-thead > tr > th {
    background: none;
    color: #828282;
    font-size: 13px;
    font-weight: 400;
  }
  .p-datatable .p-sortable-column .p-sortable-column-icon {
    color: #4f4f4f;
    font-size: 10px;
  }
  .p-datatable .p-datatable-tbody > tr > td {
    padding: 8px 1rem;
  }
  .p-component-overlay {
    backdrop-filter: blur(10px);
  }

  .p-slider .p-slider-handle {
    border-color: ${({ theme }) => theme.colors.primary_100};
  }
  .p-slider .p-slider-range {
    background: ${({ theme }) => theme.colors.primary_100};
  }
  .p-steps .p-steps-item.p-highlight .p-steps-number {
    background: ${({ theme }) => theme.colors.secondary_100};
    color: ${({ theme }) => theme.colors.white};
  }
  .p-multiselect-items {
    width: 100%;
    max-width: 640px;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
  }
  .p-multiselect-item {
    white-space: normal;
  }
  .p-multiselect-panel .p-multiselect-items .p-multiselect-item .p-checkbox {
    display: none;
  }
  .p-multiselect-panel .p-multiselect-items .p-multiselect-item {
    padding: 0.5rem;
  }
  .p-multiselect-items-wrapper {
    max-height: 300px !important;
  }
  .p-chips .p-chips-multiple-container {
    width: 100%;
    gap: 0.5rem;
  }

  .p-password {
    .p-password-input {
      width: 100%;
    }
  }
  .p-fileupload.p-fileupload-advanced .p-message {
    display: none;
  }

  .p-tooltip .p-tooltip-text {
    box-shadow: none;
  }
`
