import cx from 'classnames'
import { useGetResourcesImmutable } from 'features/storyboard/hooks/useGetResources'
import { storyboardPaths } from 'features/storyboard/storyboard.api'
import { storyFormAtom } from 'features/storyboard/storyboard.state'
import { IStoryBoardOptions } from 'features/storyboard/storyboard.types'
import { useAtom } from 'jotai'
import { LoadingBox } from 'shared/components/ApiStates/Loading/LoadingBox'
import styled from 'styled-components'

const ScriptsLibrary = () => {
  const [storyForm, setStoryForm] = useAtom(storyFormAtom)

  const { data, isLoading } = useGetResourcesImmutable<IStoryBoardOptions>(storyboardPaths.options)
  if (isLoading) return <LoadingBox />
  return (
    <Div>
      <div className="flex flex-wrap gap-3">
        {data?.scripts?.map((file) => {
          return (
            <div
              key={file.id}
              onClick={() => {
                setStoryForm((c) => ({ ...c, library: file.id }))
              }}
              className={cx({ active: storyForm.library === file.id })}
            >
              <img width={240} height={159} src={file.image || 'https://picsum.photos/240/160'} alt={file.title} />
              <p className="title">{file.title}</p>
              <p className="text-500">{file.author && file.year && `${file.author}, ${file.year}`}</p>
            </div>
          )
        })}
      </div>
    </Div>
  )
}

const Div = styled.div`
  overflow-y: auto;

  h2 {
    font-size: 18px;
    line-height: 150%;
    color: ${({ theme }) => theme.colors.secondary_100};
    text-align: center;
    margin-bottom: 1rem;
  }
  > div {
    .active {
      box-shadow: 0px 4px 32px rgba(253, 154, 25, 0.15);
      border-color: ${({ theme }) => theme.colors.primary_100};
    }
    > div {
      width: 240px;
      padding: 0.5rem;
      border: 1px solid rgba(35, 47, 62, 0.2);
      border-radius: 4px;
      cursor: pointer;
      > p {
        color: ${({ theme }) => theme.colors.gray_60};
      }
      .title {
        color: ${({ theme }) => theme.colors.secondary_100};
      }
      > img {
        border-radius: 4px;
      }
      span {
        border: 1px solid rgba(35, 47, 62, 0.2);
        font-weight: 400;
        font-size: 14px;
        line-height: 114%;
        color: ${({ theme }) => theme.colors.gray_60};
        padding: 4px 8px;
        border-radius: 8px;
      }
    }
  }
`

export default ScriptsLibrary
