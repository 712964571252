import { storyboardClient } from 'features/http'
import { changePageInteractionEventsEnum, eventTypeEnum, interactionEventsEnum } from './eventTracking.enums'
import { IUserScroll } from './eventTracking.types'

export enum eventTrackingPathsEnum {
  events = 'user-actions/',
}

/**
 * Actions performed/requested by users that manipulate a storyboard
 * @param data
 * @param pageOverwrite overwrite the default page that is created inside the function, useful when the action changes the page, because the internal page prop would get the new pathname instead of the current pathname
 */
export const recordStoryActionEvent = (
  data: { interactionElement?: interactionEventsEnum; [k: string]: any },
  pageOverwrite?: string,
) => {
  const body = { page: pageOverwrite || window.location.pathname, data, type: eventTypeEnum.storyAction }
  storyboardClient.post(eventTrackingPathsEnum.events, body)
}

// handles change page events, ex: click nav links or Start story button
export const recordChangePageEvent = ({
  page,
  to,
  interactionElement,
  ...extraProps
}: {
  page: string
  to: string
  interactionElement: changePageInteractionEventsEnum
  [k: string]: any
}) => {
  const body = { page, type: eventTypeEnum.changePage, data: { to, interactionElement, ...extraProps } }
  storyboardClient.post(eventTrackingPathsEnum.events, body)
}

export const recordInputEvent = (interactionElement: string, oldValue: string, newValue: string) => {
  const body = { page: window.location.pathname, oldValue, newValue }
  console.log('body', body)
}

export const recordScrollEvent = (depth: IUserScroll) => {
  const body = { page: window.location.pathname, depth }
  console.log('body', body)
}

// class UserActions(Base):
// __tablename__ = "user_actions"

// id = Column(GUID, primary_key=True, server_default=GUID_SERVER_DEFAULT_POSTGRESQL, index=True)
// user = Column(String, nullable=False)
// page = Column(String, nullable=False)
// type = Column(String, nullable=False)
// data = Column(JSON) | null
// created_at = Column(DateTime, server_default=func.now())
// updated_at = Column(DateTime, onupdate=func.now())
