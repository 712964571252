import { IStoryBoard, IStoryBoardScene } from 'features/storyboard/storyboard.types'
import { Dialog } from 'primereact/dialog'
import { Dispatch, SetStateAction, useState } from 'react'
import { Button } from 'shared/components/Buttons/Button'
import { SceneSelectionTable } from '../StorybordCreator/SceneSelectionTable'

interface IProps {
  storyboard: IStoryBoard
  scenes: IStoryBoardScene[]
  isAddingScenes: boolean
  addMoreScenesTrigger: any
  setShowModal: Dispatch<SetStateAction<boolean>>
}

export const AddMoreScenesModal = ({
  storyboard,
  scenes,
  addMoreScenesTrigger,
  isAddingScenes,
  setShowModal,
}: IProps) => {
  const [sceneIndices, setSceneIndices] = useState<number[]>([])
  const newSceneIndices = sceneIndices.filter((index) => !storyboard.scene_indices.includes(index))

  const handleAddMoreScenes = async () => {
    await addMoreScenesTrigger({ scene_indices: newSceneIndices })
    setShowModal(false)
  }

  return (
    <Dialog
      visible={true}
      onHide={() => setShowModal(false)}
      style={{ minHeight: '90%' }}
      footer={
        <div className="flex justify-content-center align-items-center pt-3">
          <Button className="w-12rem" size="medium" variant="outline" onClick={() => setShowModal(false)}>
            Cancel
          </Button>
          <Button
            className="w-12rem"
            size="medium"
            isLoading={isAddingScenes}
            onClick={handleAddMoreScenes}
            disabled={!newSceneIndices.length}
          >
            Add extra scenes
          </Button>
        </div>
      }
    >
      <SceneSelectionTable
        preselectedScenes={storyboard.scene_indices}
        scenes={scenes}
        requestInProgress={isAddingScenes}
        onSelectionChange={(scene_indices) => setSceneIndices(scene_indices)}
      />
    </Dialog>
  )
}
