import cx from 'classnames'
import { recordChangePageEvent, recordStoryActionEvent } from 'features/eventTracking/eventTracking.api'
import { changePageInteractionEventsEnum, interactionEventsEnum } from 'features/eventTracking/eventTracking.enums'
import { useGetResources } from 'features/storyboard/hooks/useGetResources'
import { usePostResource } from 'features/storyboard/hooks/usePostResources'
import { storyboardPaths } from 'features/storyboard/storyboard.api'
import { subscriptionsPlansEnum } from 'features/storyboard/storyboard.enums'
import { activeSceneAtom, storyboardEventIdsAtom } from 'features/storyboard/storyboard.state'
import { IStoryBoard, IStoryBoardOptions, IStoryBoardScene } from 'features/storyboard/storyboard.types'
import { useAtom } from 'jotai'
import { pagesRoutesEnum } from 'pages/Router/router.enums'
import { useState } from 'react'
import { NavLink } from 'react-router-dom'
import { toast } from 'react-toastify'
import { LoadingAnimation } from 'shared/components/ApiStates/Loading/LoadingAnimation'
import styled from 'styled-components'
import { ReactComponent as StoriaLogo } from '../../../../assets/full-logo-orange-with-black.svg'
import sceneImagePlaceholderSrc from '../../assets/orange-on-white-logo.svg'
import { AddMoreScenesModal } from './AddMoreScenesModal'
import { findFirstFrameWithImage, findFirstVisualizableUnitWithFrames } from './Utils'

interface IProps {
  storyboard: IStoryBoard
  scenes: IStoryBoardScene[]
  visualScenes: IStoryBoardScene[]
}

export const StoryboardViewAside = ({ storyboard, scenes, visualScenes }: IProps) => {
  const [activeScene, setActiveScene] = useAtom(activeSceneAtom)
  const [activeStoryboardEvents, setStoryBoardActiveEvents] = useAtom(storyboardEventIdsAtom)
  const isComplete = storyboard.total === storyboard.scene_indices.length

  const [showSceneSelectionModal, setShowSceneSelectionModal] = useState(false)
  const isStoryNotEditable = storyboard?.is_inspirational

  const { data: options } = useGetResources<IStoryBoardOptions>(storyboardPaths.options)

  // Note that `addMoreScenesTrigger` returns immediately after issuing the image generation calls.
  // We will know when they're ready when we get a completion event from Firebase.
  const { trigger: addMoreScenesTrigger, isMutating } = usePostResource<{ scene_indices: number[] }, IStoryBoard>(
    storyboardPaths.storyboardGenerateMoreScenes(storyboard.id),
    {
      onError(error) {
        const { code, message, status } = error
        recordStoryActionEvent({
          interactionElement: interactionEventsEnum.generateMoreStoryScenesFailed,
          error: { code, message, status },
        })
        toast.error('Could not generate more scenes')
      },
      onSuccess() {
        setStoryBoardActiveEvents((c) => [...c, storyboard.id])
      },
    },
  )

  const { trigger: startPaymentAsync, isMutating: isStartingPayment } = usePostResource<
    { type: subscriptionsPlansEnum; success_redirect_url: string; failed_redirect_url: string },
    { payment_link: string }
  >(storyboardPaths.payment, {
    onError(error) {
      const { code, message, status } = error
      recordStoryActionEvent({
        interactionElement: interactionEventsEnum.generatePaymentLinkFailed,
        error: { code, message, status },
      })
      toast.error('Could not generate a payment link')
    },
    onSuccess(data) {
      console.log('data', data)
      window.open(data.payment_link, '_blank')
    },
  })

  const onUpgrade = async (type: subscriptionsPlansEnum) => {
    const success_redirect_url =
      process.env.NODE_ENV === 'development'
        ? `http://localhost:3000${pagesRoutesEnum.paymentSuccess}`
        : `${window.location.protocol}//${window.location.hostname}${pagesRoutesEnum.paymentSuccess}`
    const failed_redirect_url =
      process.env.NODE_ENV === 'development'
        ? `http://localhost:3000${pagesRoutesEnum.paymentFailed}`
        : `${window.location.protocol}//${window.location.hostname}${pagesRoutesEnum.paymentFailed}`

    startPaymentAsync({ type, success_redirect_url, failed_redirect_url })
  }

  const isCreatingMoreScenes = isMutating || activeStoryboardEvents.includes(storyboard.id)
  const isLoadingAction = isCreatingMoreScenes || isStartingPayment
  return (
    <Aside
      className="hidden md:block scrollbar-hidden text-center"
      showAddMoreScenes={isStoryNotEditable || isComplete}
    >
      {showSceneSelectionModal && (
        <AddMoreScenesModal
          storyboard={storyboard}
          scenes={scenes}
          addMoreScenesTrigger={addMoreScenesTrigger}
          isAddingScenes={isMutating}
          setShowModal={setShowSceneSelectionModal}
        />
      )}
      <StyledStoryboardViewLogo>
        <NavLink to={pagesRoutesEnum.home}>
          <StoriaLogo width={120} height={30} />
        </NavLink>
      </StyledStoryboardViewLogo>
      <div className="nav-header">
        <div className="shots mb-2 py-2 px-2 border-1 border-300 border-round-lg flex align-items-center justify-content-between">
          <span className="text-600">Scenes</span> {visualScenes.length.toString().padStart(2, '0')}
        </div>
      </div>

      <div id="stories-aside" className="nav-links scrollbar-hidden">
        {visualScenes.map(({ id, index, script_units }, idx) => {
          const isActive = activeScene.sceneIdx === idx
          const visualizableUnit = findFirstVisualizableUnitWithFrames(script_units)
          const imageSrc = findFirstFrameWithImage(visualizableUnit)?.link
          return (
            <div
              key={id}
              className={cx('story-link border-0 border-300 border-round-lg shadow-2 relative surface-500', {
                active: isActive,
              })}
              onClick={() => {
                setActiveScene({
                  sceneIdx: idx,
                  changedBy: 'sidebar',
                })
              }}
              id={`thumbnail-${idx}`}
              role="button"
            >
              <div className="flex flex-row align-items-center">
                <div className="scene-index">{index + 1}</div>
                <img
                  src={imageSrc ? imageSrc : sceneImagePlaceholderSrc}
                  className="border-round-lg"
                  alt="Shot thumbnail"
                />
              </div>
            </div>
          )
        })}
      </div>

      {!isStoryNotEditable && !isComplete && (
        <div className="nav-footer mt-2">
          <div
            className={cx('story-link upgrade-button border-round-lg', {
              'cursor-pointer': !isCreatingMoreScenes && !isStartingPayment,
              'pointer-events-none': isLoadingAction,
            })}
            onClick={() => {
              recordChangePageEvent({
                page: window.location.pathname,
                to: pagesRoutesEnum.subscriptions,
                interactionElement: changePageInteractionEventsEnum.upgradeToProStoryboardViewAside,
              })
              options && options.max_amount_of_scenes > 0
                ? setShowSceneSelectionModal(true)
                : onUpgrade(subscriptionsPlansEnum.regular)
            }}
            role="button"
          >
            <div className="upgrade-button-bg border-round-lg">
              {isLoadingAction && <LoadingAnimation size={0.8} />}

              {!isLoadingAction && (
                <div className="icon">
                  <i className="pi pi-plus"></i>
                </div>
              )}
            </div>
          </div>

          <p className="mt-2 white-space-normal">Add more scenes</p>
          <span className="text-xs text-500">({options?.max_amount_of_scenes} credits left)</span>
        </div>
      )}
    </Aside>
  )
}

const Aside = styled.aside<{ showAddMoreScenes: boolean }>`
  max-height: 100%;
  height: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  gap: 2rem;
  border-right: 1px solid #232f3e;
  padding-left: 0.5rem;

  .nav-header {
    margin-inline: 36px;
  }

  > .nav-links {
    display: flex;
    flex-direction: column;
    gap: 8px;
    overflow-y: auto;
    width: 100%;
    max-height: ${({ showAddMoreScenes }) => (showAddMoreScenes ? 'calc(100vh - 110px)' : 'calc(100vh - 230px)')};
    padding-inline: 36px;
    padding-bottom: 0.75rem;

    > .story-link {
      cursor: pointer;
      height: 60px;
      position: relative;

      .scene-index {
        position: absolute;
        left: -2rem;
      }

      img {
        height: 60px;
        object-fit: cover;
        filter: grayscale(0%);
        transition: filter 0.3s ease-in-out;
        transition: opacity 0.3s ease-in-out;
      }

      &:not(.active) {
        img {
          filter: grayscale(100%);
          opacity: 0.3;
        }

        .scene-index {
          opacity: 0.3;
        }
      }
    }
  }

  > .nav-footer {
    padding-inline: 32px;

    .upgrade-button {
      height: 60px;
      padding: 6px;

      border-radius: 4px;
      border: 1px solid ${({ theme }) => theme.colors.primary_100};

      .upgrade-button-bg {
        background: linear-gradient(0deg, rgba(255, 132, 18, 0.2), rgba(255, 132, 18, 0.2)), #e9eaec;
        border-radius: 1.33px;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;

        .icon {
          border-radius: 100vmax;
          width: 28.44px;
          height: 28.44px;
          background-color: white;
          color: ${({ theme }) => theme.colors.primary_100};
          display: grid;
          place-items: center;
        }
      }
    }

    p {
      font-size: 13px;
      line-height: 125%;
      white-space: nowrap;

      > b {
        span {
          color: ${({ theme }) => theme.colors.primary_100};
        }
      }
    }
  }
`

const StyledStoryboardViewLogo = styled.div`
  padding-top: 13px;
  height: ${({ theme }) => theme.constants.navbarHeight};
`
