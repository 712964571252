import { Location } from 'features/storyboard/storyboard.types'
import { Dispatch, SetStateAction, useEffect, useRef } from 'react'
import Slider from 'react-slick'
import { Button } from 'shared/components/Buttons/Button'
import LocationCard from './LocationCard'

import 'slick-carousel/slick/slick-theme.css'
import 'slick-carousel/slick/slick.css'

interface LocationStepProps {
  locations: Location[]
  setLocations: Dispatch<SetStateAction<Location[]>>
}

function LocationStep({ locations, setLocations }: LocationStepProps) {
  const carouselRef = useRef<Slider>(null)

  const addLocation = () => {
    const newLocation: Location = { name: '', bio: '', description: '', manual: true }
    setLocations([...locations, newLocation])
  }

  const deleteLocation = (idx: number) => {
    setLocations([...locations.slice(0, idx), ...locations.slice(idx + 1)])
  }

  const updateLocation = (idx: number, location: Location) => {
    locations[idx] = location // This doesn't trigger a rerender, but that's fine.
  }

  useEffect(() => {
    // Scroll to last location, if it was just added.
    if (locations.length > 0 && locations[locations.length - 1].manual) {
      carouselRef.current?.slickGoTo(locations.length - 1)
    }
  }, [locations])

  return (
    <div className="flex flex-column gap-4">
      <div className="flex flex-column gap-1 max-width align-items-center">
        <h2>Describe your locations (optional).</h2>
        <p>This will guide the visual appearance of the backgrounds in the storyboard.</p>
      </div>
      {/* For some reason, the arrows in the Slider component don't show up... */}
      <Slider
        ref={carouselRef}
        arrows={true}
        dots={true}
        infinite={false}
        speed={500}
        slidesToShow={3}
        slidesToScroll={1}
      >
        {locations.map((loc, idx) => (
          <LocationCard
            key={idx}
            location={loc}
            updateLocation={(loc) => updateLocation(idx, loc)}
            deleteLocation={() => deleteLocation(idx)}
          />
        ))}
      </Slider>

      <div className="flex justify-content-center mt-4">
        <Button variant="outline" onClick={addLocation}>
          Add location
        </Button>
      </div>
    </div>
  )
}

export default LocationStep
