import { HTMLAttributes } from 'react'
import styled from 'styled-components'
interface ISelectItem extends HTMLAttributes<HTMLSpanElement> {
  item: { image: string; title: string }
  selected?: boolean
}
const SelectItem = ({ item: { image, title }, selected, ...props }: ISelectItem) => {
  return (
    <Span selected={selected} {...props}>
      <img src={image} alt="title" />
      <h3>{title === '' ? 'None' : title}</h3>
    </Span>
  )
}
const Span = styled.span<{ selected?: boolean }>`
  position: relative;
  width: 136px;
  padding: 0.5rem;
  border: 1px solid ${({ theme, selected }) => (selected ? theme.colors.black : theme.colors.gray_40)};
  filter: ${({ selected }) => !selected && 'grayscale(100%)'};
  border-radius: 2px;
  cursor: pointer;
  h3 {
    font-weight: 400;
    font-size: 14px;
    line-height: 132%;
    margin-top: 0.5rem;
    text-align: center;
  }
`

export default SelectItem
