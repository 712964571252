import { DetailedHTMLProps, HTMLAttributes } from 'react'
import styled from 'styled-components'
import { LoadingAnimation } from './LoadingAnimation'

interface IProps extends Omit<DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement>, 'ref'> {
  size?: number
  className?: string
  message?: string | JSX.Element
}

export const LoadingBox = ({ message, size, ...props }: IProps) => {
  return (
    <Div {...props}>
      <div className="text-center">
        <LoadingAnimation size={size} />
      </div>

      {message && <div>{message}</div>}
    </Div>
  )
}

const Div = styled.div`
  position: absolute !important;
  inset: 0 0 0 0;
  height: 100%;
  width: 100%;
  display: grid;
  place-content: center;
  gap: 1em;
  background-color: inherit;
  z-index: 1;
  backdrop-filter: blur(1px);
`
