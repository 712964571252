import { ReactNode } from 'react'

interface IProps {
  title: string
  action: JSX.Element
  children: ReactNode | ReactNode[]
}

export const StoriesWidget = ({ title, action, children }: IProps) => {
  return (
    <div>
      <div className="flex align-items-center justify-content-between">
        {title} {action}
      </div>

      <div className="relative">{children}</div>
    </div>
  )
}
