export enum changePageInteractionEventsEnum {
  homePageNavLink = 'homePageNavLink',
  myProjectsNavLink = 'myProjectsNavLink',
  inspirationNavLink = 'inspirationNavLink',
  viewAllInspiringStories = 'viewAllInspiringStories',
  startStoryNavButton = 'startStoryNavButton',
  createStoryHomePageTitleButton = 'createStoryHomePageTitleButton',
  createStoryHomePageNoStoriesCallToActionButton = 'createStoryHomePageNoStoriesCallToActionButton',
  upgradeToProStoryboardViewAside = 'upgradeToProStoryboardViewAside',
  upgradeToProCreateStoryGenerateTab = 'upgradeToProCreateStoryGenerateTab',
  upgradeToProNavDropdownTab = 'upgradeToProNavDropdownTab',
  upgradeToProPricingPage = 'upgradeToProPricingPage',
  viewInspirationStory = 'viewInspirationStory',
}

export enum interactionEventsEnum {
  storyBoardDownloadButton = 'storyBoardDownloadButton',
  storyBoardDeleteButton = 'storyBoardDeleteButton',
  storyBoardBackButton = 'storyBoardBackButton',
  createStoryAborted = 'createStoryAborted',
  createStoryFailed = 'createStoryFailed',
  updateStoryFailed = 'updateStoryFailed',
  generateMoreStoryScenesFailed = 'generateMoreStoryScenesFailed',
  generatePaymentLinkFailed = 'generatePaymentLinkFailed',
  editStoryDiscardButton = 'editStoryDiscardButton',
}

export enum eventTypeEnum {
  storyAction = 'storyAction',
  changePage = 'changePage',
}
