import cx from 'classnames'
import styled, { css } from 'styled-components'
import { LoadingAnimation } from '../ApiStates/Loading/LoadingAnimation'

interface IStyleProps {
  variant?: 'primary' | 'ghost' | 'outline' | 'shadow' | 'greyShadow'
  size?: 'medium' | 'large'
  isLoading?: boolean
  iconOnly?: boolean
}

interface IProps extends IStyleProps, React.ButtonHTMLAttributes<HTMLButtonElement> {}

export const Button = ({ variant = 'primary', size = 'medium', isLoading, children, className, ...props }: IProps) => {
  return (
    <StyledButton
      variant={variant}
      size={size}
      isLoading={isLoading}
      className={cx(className, { loading: isLoading })}
      {...props}
    >
      {isLoading && <LoadingAnimation size={0.8} />} {children}
    </StyledButton>
  )
}

const StyledButton = styled.button<IStyleProps>`
  transition: background-color 300ms ease-out;
  cursor: pointer;
  border-radius: 8px;

  svg {
    fill: currentColor;
  }

  ${({ size }) =>
    ({
      medium: 'font-weight: 400; font-size: 16px; line-height: 20px; padding: 6px 16px;',
      large: 'font-weight: 600; font-size: 18px; line-height: 28px; padding: 8px 16px;',
    }[size!])};

  ${({ iconOnly }) =>
    iconOnly &&
    css`
      > .pi {
        font-size: 1.5rem;
      }

      > svg {
        width: 1.5rem;
        height: 1.5rem;
      }
    `};

  ${({ variant, theme }) =>
    ({
      primary: `background: linear-gradient(270deg, rgba(0, 0, 0, 0.8) 0%, rgba(0, 0, 0, 0) 100%), ${theme.colors.primary_100};
background-blend-mode: soft-light, normal; color: ${theme.colors.gray_10};`,
      shadow: `background-color: ${theme.colors.primary_25}`,
      greyShadow: `background-color: ${theme.colors.gray_25}`,
      ghost: `background-color: transparent;`,
      outline: `background-color: white; outline: 1px solid ${theme.colors.primary_100}; color: ${theme.colors.primary_100};`,
    }[variant!])};

  &:hover {
    ${({ variant, theme }) =>
      ({
        primary: `background: ${theme.colors.primary_100};`,
        shadow: `background-color: ${theme.colors.primary_10}`,
        greyShadow: `background-color: ${theme.colors.primary_100}`,
        ghost: `background-color: ${theme.colors.primary_100}; `,
        outline: `background-color: ${theme.colors.gray_25};`,
      }[variant!])};
  }

  &:disabled,
  &.loading {
    pointer-events: none;
    ${({ variant, theme }) =>
      ({
        greyShadow: `background-color: ${theme.colors.gray_25}; color: ${theme.colors.gray_55};`,
        primary: `background-color: ${theme.colors.gray_25}; color: ${theme.colors.gray_55};`,
        shadow: `background-color: ${theme.colors.gray_25}; color: ${theme.colors.gray_55};`,
        ghost: `color: ${theme.colors.gray_55}; `,
        outline: `color: ${theme.colors.gray_55}; outline: 1px solid ${theme.colors.gray_40};`,
      }[variant!])};
  }
`
