import { TabPanel, TabView, TabViewTabChangeEvent } from 'primereact/tabview'
interface ITabs {
  tabs: { name: string | React.ReactNode; children: React.ReactNode | React.ReactNode[] }[]
  className?: string
  setSelectedTab?: (index: number) => void
  activeTab?: number
}

const Tabs = ({ tabs, className, activeTab, setSelectedTab }: ITabs) => {
  const onTabChange =
    activeTab !== undefined
      ? (e: TabViewTabChangeEvent): void => {
          setSelectedTab && setSelectedTab(e.index)
        }
      : undefined
  const onBeforeTabChange =
    activeTab === undefined
      ? (e: TabViewTabChangeEvent): void => {
          setSelectedTab && setSelectedTab(e.index)
        }
      : undefined

  return (
    <TabView className={className} onTabChange={onTabChange} onBeforeTabChange={onBeforeTabChange}>
      {tabs.map((tab, index) => (
        <TabPanel key={index} header={tab.name}>
          {tab.children}
        </TabPanel>
      ))}
    </TabView>
  )
}

export default Tabs
