import { useFirebase } from 'features/auth/hooks/useFirebase'
import { recordStoryActionEvent } from 'features/eventTracking/eventTracking.api'
import { interactionEventsEnum } from 'features/eventTracking/eventTracking.enums'
import { Unsubscribe, doc, onSnapshot } from 'firebase/firestore'
// import { throttle } from 'lodash'
import { useEffect, useState } from 'react'
import { IFirebaseEvent } from '../storyboard.types'

export const useInlineStoryboardScenesEvent = (resourceId?: string | null) => {
  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState('')
  const [isCompleted, setIsCompleted] = useState(false)
  const [activeEvent, setActiveEvent] = useState<null | IFirebaseEvent>(null)
  const [activeEventId, setActiveEventId] = useState<string | null>(null)

  const { db } = useFirebase()

  useEffect(() => {
    let unsubscribe: null | Unsubscribe = null
    if (resourceId) {
      if (!isLoading) setIsLoading(true)

      const q = doc(db, 'events', resourceId)
      unsubscribe = onSnapshot(q, async (doc) => {
        if (!doc.data()) {
          return
        }
        const activeEvent = doc.data() as IFirebaseEvent
        setActiveEvent(activeEvent)
        setActiveEventId(doc.id)
        const eventType = activeEvent.event as IFirebaseEvent['event']

        console.log('activeEvent', activeEvent)
        if (activeEvent.failed_to_generate) {
          setError('Failed to generate')

          if (eventType === 'story') {
            recordStoryActionEvent({
              interactionElement: interactionEventsEnum.createStoryFailed,
              scriptId: resourceId,
            })

            // await mutate()
            setIsLoading(false)
          }
        } else if (activeEvent.total > 0 && activeEvent.completed < activeEvent.total) {
          // throttle(() => mutate(), 500)
        } else if (activeEvent.total > 0 && activeEvent.completed === activeEvent.total) {
          setIsCompleted(true)
          setIsLoading(false)
        }
      })
    }

    return () => {
      unsubscribe && unsubscribe()
    }
  }, [resourceId])

  return { isLoading, error, activeEvent, isCompleted, activeEventId }
}
