import { recordStoryActionEvent } from 'features/eventTracking/eventTracking.api'
import { interactionEventsEnum } from 'features/eventTracking/eventTracking.enums'
import { useDeleteResource } from 'features/storyboard/hooks/useDeleteResources'
import { storyboardPaths } from 'features/storyboard/storyboard.api'
import { Dialog } from 'primereact/dialog'
import { ReactNode, useState } from 'react'
import { toast } from 'react-toastify'
import { Button } from 'shared/components/Buttons/Button'
import styled from 'styled-components'
import { mutate } from 'swr'
interface IDeleteStoryboardModal {
  children: ReactNode | ReactNode[]
  id: string
}

export const DeleteStoryboardModal = ({ children, id }: IDeleteStoryboardModal) => {
  const [visible, setVisible] = useState<boolean>(false)

  const { trigger, isMutating } = useDeleteResource<void, void>(storyboardPaths.storyboard(id), {
    onError(err) {
      console.log(err)
      toast.error('Could not delete storyboard...')
    },
    onSuccess() {
      mutate(storyboardPaths.storyboards)
      mutate(`${storyboardPaths.storyboards}?page=1&page_size=4`)
      setVisible(false)
    },
  })
  const onCancel = () => {
    setVisible(false)
  }

  const onSubmit = async () => {
    recordStoryActionEvent({
      interactionElement: interactionEventsEnum.storyBoardDeleteButton,
      storyBoardId: id,
    })
    await trigger()
  }

  const onOpen = () => {
    setVisible(true)
  }

  return (
    <div onClick={(e) => e.stopPropagation()}>
      <span onClick={onOpen}>{children}</span>
      <StyledDialog
        header={<h2>Delete Storyboard</h2>}
        visible={visible}
        onHide={onCancel}
        style={{ width: '450px' }}
        resizable={false}
        draggable={false}
      >
        <div className="flex flex-column align-items-center gap-3">
          <p>Are you sure you want to delete this storyboard?</p>
          <div className="flex gap-3">
            <Button onClick={onCancel} variant="outline" className="mt-5 w-full" size="large">
              Cancel
            </Button>
            <Button isLoading={isMutating} onClick={onSubmit} className="mt-5 w-full" size="large">
              Delete
            </Button>
          </div>
        </div>
      </StyledDialog>
    </div>
  )
}

const StyledDialog = styled(Dialog)`
  h2 {
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 150%;
    color: ${({ theme }) => theme.colors.secondary_100};
    text-align: center;
  }
  h3 {
    font-weight: 400;
    font-size: 16px;
    line-height: 150%;
    color: ${({ theme }) => theme.colors.secondary_100};
    text-align: center;
  }

  p {
    padding: 0.5rem 0;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 150%;
    color: ${({ theme }) => theme.colors.gray_60};
    text-align: center;
  }
  .option {
    color: ${({ theme }) => theme.colors.gray_60};
    border: 1px solid ${({ theme }) => theme.colors.gray_40};
    border-radius: 8px;
    padding: 5px 10px;
    span {
      color: ${({ theme }) => theme.colors.secondary_100};
    }
  }
`
