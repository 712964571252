import { IStoryBoardScene } from 'features/storyboard/storyboard.types'

export const getUniqueCharactersForScene = (scene: IStoryBoardScene) => {
  const characterSet: Set<string> = new Set()
  for (const unit of scene.script_units) {
    for (const turn of unit.turns) {
      // Remove anything in brackets (e.g. (V.O.), (S.O.), (cont'd)).
      const cleanCharacter = turn.character.replace(/ *\([^)]*\) */g, '').trim()
      characterSet.add(cleanCharacter)
    }
  }
  return characterSet
}

export const getUniqueCharacters = (scenes: IStoryBoardScene[]) => {
  const characterSet: Set<string> = new Set()
  for (const scene of scenes) {
    for (const character of getUniqueCharactersForScene(scene)) {
      characterSet.add(character)
    }
  }
  return characterSet
}

export const getCharactersAndCounts = (scenes: IStoryBoardScene[]) => {
  const characters = []
  for (const scene of scenes) {
    for (const unit of scene.script_units) {
      for (const turn of unit.turns) {
        // Remove anything in brackets (e.g. (V.O.), (S.O.), (cont'd)).
        const cleanCharacter = turn.character.replace(/ *\([^)]*\) */g, '').trim()
        characters.push(cleanCharacter)
      }
    }
  }
  const characterCounts: { [key: string]: number } = {}
  characters.forEach((c) => {
    characterCounts[c] = characterCounts[c] ? characterCounts[c] + 1 : 1
  })
  const countsSorted = Object.entries(characterCounts).sort(([_, a], [__, b]) => b - a)
  return countsSorted
}

export function getLocationsAndCounts(scenes: IStoryBoardScene[]) {
  const locations = scenes.map((scene) => scene.location && scene.location.replace('’', "'"))
  const locationCounts: { [key: string]: number } = {}
  locations.forEach((c) => {
    locationCounts[c] = locationCounts[c] ? locationCounts[c] + 1 : 1
  })
  console.log(locationCounts)
  const countsSorted = Object.entries(locationCounts).sort(([_, a], [__, b]) => b - a)
  return countsSorted
}
