import { throttle } from 'lodash'
import { useEffect, useState } from 'react'
import { breakPointsEnum } from 'shared/enums/styles.enums'

export default function useWindowScreenSize(
  mobile = breakPointsEnum.mobile,
  tablet = breakPointsEnum.tablet,
  smallDesktop = breakPointsEnum.smallDesktop,
) {
  const getDevice = () => {
    if (window.innerWidth < mobile) return 'mobile'
    if (window.innerWidth < tablet) return 'tablet'
    if (window.innerWidth < smallDesktop) return 'smallDesktop'
    return 'desktop'
  }
  const handleResize = throttle(() => {
    setDevice(getDevice())
  }, 300)

  const [device, setDevice] = useState<'mobile' | 'tablet' | 'smallDesktop' | 'desktop'>(getDevice())

  useEffect(() => {
    window.addEventListener('resize', handleResize)

    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  const isBiggerThenMobile = device !== 'mobile'
  const isBiggerThenTablet = !['mobile', 'tablet'].includes(device)
  const isMediumDevice = ['tablet', 'smallDesktop'].includes(device)
  return { device, isBiggerThenMobile, isBiggerThenTablet, isMediumDevice }
}
