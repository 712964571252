import checkIcon from 'assets/checkIcon.png'
import { useNavigate } from 'react-router-dom'
import { Button } from 'shared/components/Buttons/Button'
import styled from 'styled-components'
import { Layout } from 'theme/Layouts/Layout'
import { pagesRoutesEnum } from './Router/router.enums'

export const PaymentFailed = () => {
  const navigate = useNavigate()
  return (
    <Layout>
      <Div listIcon={checkIcon}>
        <h2>Payment Failed</h2>
        <p className="flex align-items-center gap-3 my-4">
          Apologies, something went wrong. You were not charged. Please try again.
        </p>
        <Button onClick={() => navigate(pagesRoutesEnum.subscriptions)} className="mt-2" size="large">
          Try Again
        </Button>
      </Div>
    </Layout>
  )
}

const Div = styled.div<{ listIcon: string }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;

  > h2 {
    margin: -4rem auto 0;
    font-size: 36px;
    line-height: 42px;
    color: ${({ theme }) => theme.colors.secondary_100};
  }
  > p {
    font-size: 16px;
    line-height: 150%;
    text-align: center;
    color: ${({ theme }) => theme.colors.secondary_100};
  }
`
