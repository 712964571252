import { breakPointsEnum } from 'shared/enums/styles.enums'
import styled from 'styled-components'

export const StyledContainer = styled.div`
  position: fixed;
  isolation: isolate;
  display: flex;
  align-items: end;
  z-index: 2;
  inset: ${({ theme }) => theme.constants.navbarHeight} 0 0;
  color: ${({ theme }) => theme.colors.grey_100};
  background-color: ${({ theme }) => theme.colors.grey_100}40;

  > .results {
    position: relative;
    background: #ffffff;
    box-shadow: 0px 10px 25px rgba(0, 0, 0, 0.1);
    border-radius: 20px 20px 0px 0px;
    padding: 40px 20px;
    max-height: 100%;
    width: 100%;
    margin-inline: auto;
    z-index: 1;
    overflow-y: auto;

    > .mobile-toggler {
      position: absolute;
      top: 20px;
      left: 50%;
      transform: translateX(-50%);
      width: 78px;
      height: 0;
      border: 3px solid ${({ theme }) => theme.colors.gray_25};
    }

    > .close-icon {
      display: none;
    }
  }

  @media (min-width: ${breakPointsEnum.tablet}px) {
    top: ${({ theme }) => theme.constants.navbarHeight};
    margin-inline: auto;
    align-items: start;

    > .results {
      min-width: 800px;
      max-width: 930px;
      border-radius: 0;

      > .mobile-toggler {
        display: none;
      }

      > .close-icon {
        display: block;
        position: absolute;
        inset: 0 5px auto auto;
      }
    }
  }
`
