import { DetailedHTMLProps, HTMLAttributes } from 'react'
import styled, { css } from 'styled-components'

export interface INoContentBoxProps
  extends Omit<DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement>, 'ref'> {
  message?: string
  image?: JSX.Element | string
  title?: string
  actions?: JSX.Element
  maxWidth?: string
  minHeight?: string
}

export const NoContentBox = ({ message, image, title, actions, maxWidth, minHeight }: INoContentBoxProps) => {
  return (
    <Div data-testid="no-content-container" maxWidth={maxWidth} minHeight={minHeight}>
      {image}

      {title ||
        (message && (
          <div className="details">
            {title && (
              <p data-testid="title" className="title">
                {title}
              </p>
            )}
            {message && (
              <p data-testid="message" className="message">
                {message}
              </p>
            )}
          </div>
        ))}
      {actions && <div className="actions">{actions}</div>}
    </Div>
  )
}

const Div = styled.div<{ maxWidth?: string; minHeight?: string }>`
  position: absolute !important;
  inset: 0 0 0 0;
  height: 100%;
  width: 100%;
  display: flex;
  text-align: center;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 1.25em;
  position: relative;

  .details {
    ${({ maxWidth }) =>
      maxWidth &&
      css`
        max-width: ${maxWidth};
      `}

    ${({ minHeight }) =>
      minHeight &&
      css`
        min-height: ${minHeight};
      `}
    white-space: pre-wrap;

    > .title {
      color: ${({ theme }) => theme.colors.error};
      font-size: 32px;
      font-weight: bold;
      margin-top: 1.25;
    }

    > .message {
      padding-inline: 2rem;
      font-size: 16px;
      max-width: 500px;
      color: ${({ theme }) => theme.colors.gray_85};
    }
  }
`
