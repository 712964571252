import { breakPointsEnum } from 'shared/enums/styles.enums'
import styled, { css } from 'styled-components'
import { NavDropdownStyleProps } from './NavDropdown'

export const StyledBackground = styled.div`
  position: fixed;
  width: 100vw;
  z-index: 2;
  inset: ${({ theme }) => theme.constants.navbarHeight} 0 0;
  background-color: ${({ theme }) => theme.colors.grey_100}40;
`
export const StyledContainer = styled.div<NavDropdownStyleProps>`
  position: relative;
  background-color: ${({ backgroundColor }) => backgroundColor};
`

export const StyledDropdownBody = styled.div<NavDropdownStyleProps>`
  z-index: 3;
  position: fixed;
  width: 100vw;
  inset: ${({ theme }) => theme.constants.navbarHeight} 0 auto;
  max-height: calc(100vh - ${({ theme }) => theme.constants.navbarHeight});
  background-color: ${({ backgroundColor }) => backgroundColor};
  overflow-y: auto;
  box-shadow: 0px 10px 25px rgba(0, 0, 0, 0.1);
  border-radius: 10px;

  > .close-button {
    position: absolute;
    inset: 0.5rem 0 auto auto;
    opacity: 0.5;
  }

  @media (min-width: ${breakPointsEnum.mobile}px) {
    ${({ extendOnTablet }) =>
      !extendOnTablet &&
      css`
        position: absolute;
        width: auto;
        min-width: 250px;
        inset: 42px 0 auto auto;
        height: auto;
      `}
  }

  @media (min-width: ${breakPointsEnum.tablet}px) {
    width: auto;
    min-width: 250px;
  }
`
