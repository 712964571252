import CameraAngleTab from 'features/scene/components/CameraAngleTab'
import ChangeStyleTab from 'features/scene/components/ChangeStyleTab'
import ImageTab from 'features/scene/components/ImageTab'
import LightningTab from 'features/scene/components/LightningTab'
import PromptTab from 'features/scene/components/PromptTab'
import { usePutSceneResource } from 'features/scene/hooks/usePutSceneResource'
import { scenePaths } from 'features/scene/scene.api'
import { initialRegenerateSceneForm } from 'features/scene/scene.model'
import { generateVUnitFormAtom, generatedImageAtom } from 'features/scene/scene.state'
import { IApplyVUnit, IExtraSceneRes, ISceneFrame } from 'features/scene/scene.types'
import { useDeleteResource } from 'features/storyboard/hooks/useDeleteResources'
import { useGetResources } from 'features/storyboard/hooks/useGetResources'
import { usePostMultiPartResource } from 'features/storyboard/hooks/usePostResources'
import { sceneChangeAtom, storyboardEventIdsAtom, storyboardModeAtom } from 'features/storyboard/storyboard.state'
import { useAtom, useAtomValue, useSetAtom } from 'jotai'
import { Skeleton } from 'primereact/skeleton'
import { useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import { Button } from 'shared/components/Buttons/Button'
import Tabs from 'shared/components/Tabs/Tabs'
import styled from 'styled-components'

const GenerateScenePage = () => {
  const [generateVUnitForm, setGenerateVUnitForm] = useAtom(generateVUnitFormAtom)
  const [vUnitId, setvUnitId] = useState<string>()
  const [generatedImageId, setGeneratedImageId] = useState<string | null>()
  const setGeneratedImageForm = useSetAtom(generatedImageAtom)
  const setStoryBoardActiveEvent = useSetAtom(storyboardEventIdsAtom)
  const setMode = useSetAtom(storyboardModeAtom)
  const sceneChange = useAtomValue(sceneChangeAtom)
  const { scriptUnit, vUnit: sourceVUnit, callback } = sceneChange

  // Default the prompt to the prompt of the first frame in the visual unit.
  useEffect(() => {
    if (generateVUnitForm.prompt === undefined && (sourceVUnit?.frames.length || 0) > 0) {
      setGenerateVUnitForm((c) => ({ ...c, prompt: sourceVUnit?.frames[0].prompt || '' }))
    }
  }, [])

  const { trigger: triggerDeleteSourceVUnit } = useDeleteResource<void, void>(
    scenePaths.visualUnit(sourceVUnit?.id || ''),
    {
      onSuccess() {
        console.log('Successfully deleted source vunit in the BE')
      },
      onError(error) {
        console.error('Failed to delete source vunit in the BE:', error)
      },
    },
  )

  const { trigger: triggerNewVUnitAndFrame } = usePostMultiPartResource<FormData, IExtraSceneRes>(
    scenePaths.addFrameToNewVUnit(scriptUnit?.id || ''),
    {
      onError(error) {
        console.error('Failed to generate new frame:', error)
        toast.error('Unable to generate new shot. Please try again.')
      },
      onSuccess(data) {
        // This will then get handled by useStoryboardsEvents() in App.tsx
        console.log('Successfully generated new frame')
        setStoryBoardActiveEvent((c) => [...c, data.frame_id])
        setGeneratedImageId(data.frame_id)
        setvUnitId(data.v_unit_id)
        setGeneratedImageForm(generateVUnitForm)
      },
    },
  )

  const { trigger: triggerCommitVUnit, isMutating: isMutatingVUnit } = usePutSceneResource<IApplyVUnit, { id: string }>(
    scenePaths.visualUnit(vUnitId!),
    {
      onSuccess() {
        console.log('Successfully committed vunit to the script unit.')
      },
      onError(error) {
        console.error('Failed to commit vunit to the script unit:', error)
      },
    },
  )

  const { data: generatedImage } = useGetResources<ISceneFrame>(
    generatedImageId ? scenePaths.frameGET(generatedImageId) : null,
  )
  const imageIsAvailable = generatedImage && generatedImage.link !== null
  const isGenerating = (generatedImage && generatedImage.link === null) || false

  const onGenerate = async () => {
    console.log('Generating new frame...')
    const form = new FormData()
    generateVUnitForm.image && form.append('image', generateVUnitForm.image)
    form.append('body', JSON.stringify(generateVUnitForm))
    await triggerNewVUnitAndFrame(form)
  }

  const tabs = [
    {
      name: 'Description',
      children: <PromptTab formAtom={generateVUnitFormAtom} isGenerating={isGenerating} />,
    },
    {
      name: 'Style',
      children: <ChangeStyleTab formData={generateVUnitForm} setFormData={setGenerateVUnitForm} />,
    },
    {
      name: 'Camera Angle',
      children: <CameraAngleTab formAtom={generateVUnitFormAtom} isGenerating={isGenerating} />,
    },
    {
      name: 'Lighting',
      children: <LightningTab formAtom={generateVUnitFormAtom} isGenerating={isGenerating} />,
    },
    {
      name: 'Inspiration Image',
      children: (
        <ImageTab
          header="Upload an image to inspire our AI."
          formData={generateVUnitForm}
          setFormData={setGenerateVUnitForm}
        />
      ),
    },
  ]

  useEffect(() => {
    setGeneratedImageId(null)
    return () => {
      setGenerateVUnitForm(initialRegenerateSceneForm)
      setGeneratedImageForm(null)
    }
  }, [])

  return (
    <Div>
      <div className="grid content md:overflow-hidden">
        {/* LHS */}
        <div className="col-12 md:col-6 px-3 md:h-full">
          <Tabs tabs={tabs} />
        </div>
        {/* RHS */}
        <div className="col-12 md:col-6 p-3 flex flex-column align-items-center gap-2">
          {!imageIsAvailable && <Skeleton height="70vh" animation={isGenerating ? 'wave' : 'none'} />}
          {imageIsAvailable && <img src={generatedImage.link} style={{ height: '70vh' }} />}
          <Button disabled={generateVUnitForm.prompt === ''} isLoading={isGenerating} onClick={onGenerate}>
            Generate
          </Button>
        </div>
      </div>
      <footer
        className="flex gap-3 align-items-center justify-content-end grid"
        style={{ position: 'absolute', inset: 'auto 0 0' }}
      >
        <div className="border-top-1 border-300 p-3 col-12 md:col-6 flex flex-wrap align-items-center gap-3 justify-content-center bg-white">
          <Button
            disabled={!vUnitId || generatedImage?.link === null}
            isLoading={isMutatingVUnit}
            onClick={async () => {
              await triggerCommitVUnit({ script_uuid: scriptUnit?.id || '' })
              if (
                sourceVUnit?.frames.length === 0 ||
                sourceVUnit?.frames[sourceVUnit?.frames.length - 1].link === null
              ) {
                await triggerDeleteSourceVUnit()
                scriptUnit?.visualizable_units.pop()
              }
              if (callback) {
                callback()
              }
              setMode('view')
            }}
          >
            Add to storyboard
          </Button>
          <Button onClick={() => setMode('view')} variant="outline">
            Discard
          </Button>
        </div>
      </footer>
    </Div>
  )
}

export default GenerateScenePage

const Div = styled.div`
  position: relative;
  height: 100%;
  max-height: calc(100vh - 59px);
  max-width: 2440px;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: stretch;
  gap: 2rem;
  padding-top: 4vh;
`
