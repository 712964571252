import { useEffect, useState } from 'react'

import { useAtom } from 'jotai'

import {
  Auth,
  GoogleAuthProvider,
  createUserWithEmailAndPassword,
  getAuth,
  signInWithEmailAndPassword,
  signInWithPopup,
  signOut,
} from 'firebase/auth'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import { sessionAtom } from '../auth.state'

export const useAuth = () => {
  const auth: Auth = getAuth()
  const navigate = useNavigate()

  const [session, setSession] = useAtom(sessionAtom)
  const [errorCode, setErrorCode] = useState('')

  useEffect(() => {
    auth.onAuthStateChanged(
      (user) => {
        setSession(user)
      },
      (error) => {
        console.log('error', error)
      },
      () => {
        console.log('completed')
      },
    )
  }, [auth, setSession])

  const signIn = async ({ email, password }: { email: string; password: string }) => {
    await signInWithEmailAndPassword(auth, email, password)
      .then((userCredential) => {
        const user = userCredential.user
        setSession(user)
        navigate('/')
      })
      .catch((error) => {
        const errorCode = error.code
        const errorMessage = error.message
        console.log({ errorCode, errorMessage })
        setErrorCode(errorCode)
      })
  }

  const socialSignIn = async () => {
    const provider = new GoogleAuthProvider()
    signInWithPopup(auth, provider)
      .then((result) => {
        // This gives you a Google Access Token. You can use it to access the Google API.
        const credential = GoogleAuthProvider.credentialFromResult(result)
        console.log('credential', credential)
        // See https://stackoverflow.com/questions/38335127/firebase-auth-id-token-has-incorrect-aud-claim
        console.log('ID token for current user (use this to log into FastAPI Swagger)', auth.currentUser?.getIdToken())

        navigate('/')
      })
      .catch((error) => {
        // Handle Errors here.
        const errorCode = error.code
        const errorMessage = error.message
        // The email of the user's account used.
        const email = error.customData.email
        // The AuthCredential type that was used.
        const credential = GoogleAuthProvider.credentialFromError(error)
        console.log({ errorCode, errorMessage, email, credential })
        setErrorCode(errorCode)
      })
  }

  const logout = async () => {
    signOut(auth)
      .then(() => {
        navigate('/login')
      })
      .catch((error) => {
        const errorCode = error.code
        const errorMessage = error.message
        toast.error(errorMessage)
        console.log({ errorCode, errorMessage })
      })
    setSession(null)
  }

  const forgotPassword = async (username: string) => {
    // await Auth.forgotPassword(username)
  }

  const forgotPasswordSubmit = async (username: string, code: string, password: string) => {
    // await Auth.forgotPasswordSubmit(username, code, password)
  }

  const signUp = async ({ email, password }: { email: string; password: string }) => {
    return createUserWithEmailAndPassword(auth, email, password)
      .then((userCredential) => {
        // Signed in
        const user = userCredential.user
        setSession(user)
        return user
      })
      .catch((error) => {
        const errorCode = error.code
        const errorMessage = error.message
        console.log({ errorCode, errorMessage })
        setErrorCode(errorCode)
        return null
      })
  }

  const confirmSignUp = async (email: string, code: string) => {
    // return await Auth.confirmSignUp(email.toLowerCase(), code)
  }

  // CognitoUser type is bugged
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  // const setAuth = (cognitoUser: any) => {
  //   setSession({
  //     token: cognitoUser.signInUserSession.idToken.jwtToken,
  //     username: cognitoUser.username,
  //     groups: cognitoUser.signInUserSession.idToken.payload['cognito:groups'],
  //     client_id: cognitoUser.signInUserSession.accessToken.payload['client_id'],
  //   })
  // }

  return {
    // employeeLogin,
    session,
    signOut,
    signIn,
    signUp,
    confirmSignUp,
    forgotPassword,
    forgotPasswordSubmit,
    logout,
    socialSignIn,
    errorCode,
    // phoneSignIn,
    // verifySMSCode,
    // currentChallenge,
  }
}
