import { useRef } from 'react'

import { Button } from 'shared/components/Buttons/Button'
import useOnClickOutside from 'shared/hooks/useOnClickOutside'
import { StyledContainer } from './styles'
import { NavDialogProps } from './types'

export const NavDialog: React.FC<NavDialogProps> = ({ onClose, children }) => {
  const mobileTogglerRef = useRef(null)
  const resultsRef = useRef(null)

  useOnClickOutside(resultsRef, () => onClose())

  const handleSwipe = (e: React.TouchEvent<HTMLDivElement>) => {
    if (e.touches.length > 0 && e.changedTouches.length > 0) {
      const touchStartX = e.touches[0].clientX
      const touchStartY = e.touches[0].clientY
      const touchEndX = e.changedTouches[0].clientX
      const touchEndY = e.changedTouches[0].clientY
      const deltaX = touchEndX - touchStartX
      const deltaY = touchEndY - touchStartY

      if (deltaY > 50 && Math.abs(deltaX) < Math.abs(deltaY)) {
        onClose()
      }
    }
  }

  return (
    <StyledContainer>
      <div className="results" ref={resultsRef}>
        <div
          className="mobile-toggler"
          ref={mobileTogglerRef}
          onTouchStart={handleSwipe}
          onTouchEnd={handleSwipe}
          onClick={onClose}
        />

        <div className="close-icon" onClick={onClose}>
          <Button iconOnly>
            <i className="pi pi-close"></i>
          </Button>
        </div>

        {children}
      </div>
    </StyledContainer>
  )
}
