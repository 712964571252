import { useEffect, useRef, useState } from 'react'

import { PinturaEditor } from '@pqina/react-pintura'

import '@pqina/pintura/pintura.css'
import { scenePaths } from 'features/scene/scene.api'
import { IFrameUploadResponse } from 'features/scene/scene.types'
import { usePostMultiPartResource } from 'features/storyboard/hooks/usePostResources'
import { sceneChangeAtom, storyboardModeAtom } from 'features/storyboard/storyboard.state'
import { useAtomValue, useSetAtom } from 'jotai'

import { getEditorDefaults } from '@pqina/pintura'
import { downloadFile } from 'features/storyboard/components/Storyboard/StoryboardDownload'
import { Button } from 'shared/components/Buttons/Button'

const EditScenePage = () => {
  const editorRef = useRef<PinturaEditor>(null)
  const setMode = useSetAtom(storyboardModeAtom)
  const sceneChange = useAtomValue(sceneChangeAtom)
  const [originalFrame, setOriginalFrame] = useState<Blob>()

  useEffect(() => {
    if (sceneChange.frame) {
      downloadFile(sceneChange.frame.link, (blob: Blob) => {
        setOriginalFrame(blob)
      })
    }
  }, [sceneChange.frame])

  const { trigger: triggerFrameUpload, isMutating: frameIsUploading } = usePostMultiPartResource<
    FormData,
    IFrameUploadResponse
  >(scenePaths.uploadFrame(sceneChange.scriptUnit?.id || '', sceneChange.vUnit?.id || '') + '?add_to_source_vunit=1', {
    onError(error) {
      console.log('Error uploading frame.', error)
    },
    onSuccess(data) {
      console.log(`Created frame with ID ${data.frame_id} under visual unit ${data.destination_vunit_id}`)
      sceneChange.callback?.()
      setMode('view')
    },
  })

  // We'll replace this button with our own, to have more control over position and style.
  const EXPORT_BUTTON_LABEL = 'to-be-removed'
  const hideDefaultExportButton = (toolbar: any) => {
    const exportButton: HTMLElement | null = document.querySelector(`[title="${EXPORT_BUTTON_LABEL}"]`)
    if (exportButton) {
      exportButton.style.display = 'none'
    }
    return [...toolbar]
  }

  const config = getEditorDefaults()
  config.locale.labelButtonExport = EXPORT_BUTTON_LABEL

  return (
    <div>
      <div style={{ height: '85vh' }}>
        <PinturaEditor
          {...config}
          src={originalFrame}
          ref={editorRef}
          onProcess={(res: any) => {
            const form = new FormData()
            form.append('image', res.dest)
            form.append('description', sceneChange.frame?.prompt || 'edited frame')
            triggerFrameUpload(form)
          }}
          willRenderToolbar={hideDefaultExportButton}
        />
      </div>
      <footer
        className="flex gap-3 align-items-center justify-content-end grid"
        style={{ position: 'absolute', inset: 'auto 0 0' }}
      >
        <div className="border-top-1 border-300 p-3 col-12 md:col-6 flex flex-wrap align-items-center gap-3 justify-content-center bg-white">
          <Button
            isLoading={frameIsUploading}
            // Find the real export button and click it.
            onClick={() => {
              const exportButton: HTMLElement | null = document.querySelector(`[title="${EXPORT_BUTTON_LABEL}"]`)
              if (exportButton) {
                exportButton.click()
              }
            }}
          >
            Apply changes
          </Button>
          <Button
            onClick={() => {
              setMode('view')
            }}
            variant="outline"
          >
            Discard
          </Button>
        </div>
      </footer>
    </div>
  )
}

export default EditScenePage
