import cx from 'classnames'
import { useGetResourcesImmutable } from 'features/storyboard/hooks/useGetResources'
import { storyboardPaths } from 'features/storyboard/storyboard.api'
import { IStoryBoardModel, IStoryBoardOptions } from 'features/storyboard/storyboard.types'
import { useAtom } from 'jotai'
import { BsDot } from 'react-icons/bs'
import { LoadingBox } from 'shared/components/ApiStates/Loading/LoadingBox'
import styled from 'styled-components'
import storiaLogo from '../../assets/storia.svg'
import { storyFormAtom } from '../../storyboard.state'

// Explanation for model trade-offs.
// This implementation is brittle because it relies on the exact model names.
// Ideally, these should be sent by the BE.
function getModelSpec(modelName: string): JSX.Element {
  if (modelName === 'Midjourney') {
    return (
      <div className="flex flex-column align-items-left">
        <div className="flex flex-column align-items-center">
          <div>Temporarily unavailable</div>
          <div>&nbsp;</div> {/* empty line for proper alignment */}
        </div>
      </div>
      // <div className="flex flex-column align-items-left">
      //   <div>
      //     <BsDot /> Great image quality
      //   </div>
      //   <div>
      //     <BsDot /> Slower
      //   </div>
      // </div>
    )
  } else if (modelName === 'Stable Diffusion') {
    return (
      <div className="flex flex-column align-items-left">
        <div>
          <BsDot /> Regular image quality
        </div>
        <div>
          <BsDot /> Fast
        </div>
      </div>
    )
  } else if (modelName === 'DALL-E') {
    return (
      <div className="flex flex-column align-items-left">
        <div>
          <BsDot /> Embarrassing quality
        </div>
        <div>
          <BsDot /> Fast
        </div>
      </div>
    )
  } else if (modelName === 'Storia') {
    return (
      <div className="flex flex-column align-items-left">
        <div className="flex flex-column align-items-center">
          <div>Coming soon</div>
          <div>&nbsp;</div> {/* empty line for proper alignment */}
        </div>
      </div>
    )
  } else {
    return <></>
  }
}

const AIEngineStep = () => {
  const [storyAtom, setStoryAtom] = useAtom(storyFormAtom)
  const { model: activeModel } = storyAtom

  const { data, isLoading } = useGetResourcesImmutable<IStoryBoardOptions>(storyboardPaths.options)

  const onCardClick = (cardId: string) => {
    setStoryAtom((prev) => {
      return { ...prev, model: cardId }
    })
  }

  if (isLoading) return <LoadingBox />
  // Don't show DALL-E as engine option if on live app
  if (location.hostname !== 'localhost' && data?.models) {
    const nonDalleModels = data?.models.filter((model) => model.text !== 'DALL-E')
    data.models = nonDalleModels
  }

  const storiaModelId = 'storia-model-id'
  let storiaModel: IStoryBoardModel | undefined = data?.models.filter((model) => model.id == storiaModelId)[0]
  if (storiaModel === undefined) {
    storiaModel = {
      id: storiaModelId,
      image: storiaLogo,
      text: 'Storia',
    }
    data?.models.push(storiaModel)
  }

  return (
    <Div>
      <div className="flex flex-column gap-4 align-items-center">
        <h2>Select an AI engine for image generation.</h2>
        <div className="flex gap-3 flex-wrap ">
          {data?.models.map((model) => {
            return (
              <div
                className={cx(
                  'model-card',
                  { 'active-card': model.id === activeModel },
                  { 'disabled-card': model.id === storiaModelId || model.text === 'Midjourney' },
                )}
                onClick={() => {
                  onCardClick(model.id)
                }}
                key={model.id}
                aria-disabled={model.id === storiaModelId || model.text === 'Midjourney'}
              >
                <span>
                  <img className="model-icon" width={32} height={32} src={model.image} alt="model-img" />
                  <h4>{model.text}</h4>
                </span>
                {getModelSpec(model.text)}
              </div>
            )
          })}
        </div>
      </div>
    </Div>
  )
}
const Div = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  max-height: 100%;
  overflow: auto;

  > div {
    width: 100%;
    max-width: 1000px;
    h2 {
      font-weight: 600;
      font-size: 20px;
      line-height: 132%;
      color: ${({ theme }) => theme.colors.secondary_100};
    }
  }

  .model-card {
    opacity: 0.6;
    width: 200px;
    height: 175px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    border: 1px solid rgba(35, 47, 62, 0.5);
    border-radius: 4px;
    padding: 0.5rem;
    gap: 2rem;
    cursor: pointer;

    > span {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 0.5rem;
      p {
        font-weight: 400;
        font-size: 16px;
        line-height: 150%;
        color: ${({ theme }) => theme.colors.gray_70};
      }
      .model-icon {
        width: 32px;
      }
    }
  }

  .disabled-card {
    background: ${({ theme }) => theme.colors.gray_10};
    pointer-events: none; // disable click
  }

  .active-card {
    opacity: 1;
  }
`
export default AIEngineStep
