import { storyboardClient } from 'features/http'
import { ICreateStoryBoard, IStoryBoard, IStoryBoardOptions } from './storyboard.types'

export const storyboardPaths = {
  storyboard: (id: string) => `stories/${id}`,
  storyboardMetadata: (id: string) => `stories/metadata/${id}`,
  getScenes: (id: string, offset: number, limit: number) => `stories/scenes/${id}/${offset}/${limit}`,
  generateImage: (id: string) => `stories/scenes/${id}/generate`,
  addImage: (id: string) => `stories/scenes/${id}`,
  downloadPDF: (id: string) => `stories/download/${id}/`,
  options: 'stories/options',
  storyboards: '/stories',
  newStory: '/stories/new/',
  newStoryFromText: '/stories/new-from-text/',
  storyboardGenerateMoreScenes: (id: string) => `/stories/generate/${id}`,
  updateStory: (id: string) => `/stories/update/${id}`,
  exploreStoryBoard: (id: string) => `explore/stories/${id}`,
  regenerateStoryBoard: (id: string) => `/stories/regenerate/${id}`,
  exploreStories: 'stories/explore',
  payment: 'payment',
  subscriptions: 'subscriptions',
}

export const getStoryResource = async <T>(path: string): Promise<T> => {
  const { data } = await storyboardClient.get(path)

  return data as T
}

export const postStoryResource = async <T, K>(path: string, { arg }: { arg: T }): Promise<K> => {
  const { data } = await storyboardClient.post(path, arg)
  return data as K
}

export const postMultipartStoryResource = async <T, K>(path: string, { arg }: { arg: T }): Promise<K> => {
  const { data } = await storyboardClient.post(path, arg, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  })
  return data as K
}

export const deleteStoryResource = async <K>(path: string): Promise<K> => {
  const { data } = await storyboardClient.delete(path)
  return data as K
}

export const getStoryBoardOptions = async (): Promise<IStoryBoardOptions> => {
  const { data } = await storyboardClient.get(storyboardPaths.options)
  return data
}

export const getStoryboards = async (): Promise<IStoryBoard[]> => {
  const { data } = await storyboardClient.get(storyboardPaths.storyboards)
  return data
}

export const getExploreStoryboards = async (): Promise<IStoryBoard[]> => {
  const { data } = await storyboardClient.get(storyboardPaths.exploreStories)
  return data
}

export const getExploreStoryboard = async (id: string): Promise<IStoryBoard> => {
  const { data } = await storyboardClient.get(storyboardPaths.exploreStoryBoard(id))
  return data
}

export const getStoryboard = async (id: string): Promise<IStoryBoard> => {
  const { data } = await storyboardClient.get(storyboardPaths.storyboard(id))
  return data
}

export const createStory = async (body: ICreateStoryBoard): Promise<IStoryBoard> => {
  const { data } = await storyboardClient.post(storyboardPaths.storyboards, body)
  return data
}

export const addImageToScene = async ({ id, file }: { id: string; file: File }): Promise<IStoryBoard> => {
  const formData = new FormData()
  formData.append('file', file)

  const { data } = await storyboardClient.post(storyboardPaths.addImage(id), formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  })
  return data
}

export const generateImageForScene = async (id: string): Promise<IStoryBoard> => {
  const { data } = await storyboardClient.post(storyboardPaths.generateImage(id))
  return data
}
