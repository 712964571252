import { useAuth } from 'features/auth/hooks/useAuth'
import { recordChangePageEvent, recordStoryActionEvent } from 'features/eventTracking/eventTracking.api'
import { changePageInteractionEventsEnum, interactionEventsEnum } from 'features/eventTracking/eventTracking.enums'
import { useGetResources } from 'features/storyboard/hooks/useGetResources'
import { usePostResource } from 'features/storyboard/hooks/usePostResources'
import { storyboardPaths } from 'features/storyboard/storyboard.api'
import { subscriptionsPlansEnum } from 'features/storyboard/storyboard.enums'
import { IStoryBoardOptions } from 'features/storyboard/storyboard.types'
import { getAuth } from 'firebase/auth'
import { pagesRoutesEnum } from 'pages/Router/router.enums'
import { Avatar } from 'primereact/avatar'
import { useState } from 'react'
import { useAuthState } from 'react-firebase-hooks/auth'
import { MdGeneratingTokens } from 'react-icons/md'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import { Button } from 'shared/components/Buttons/Button'
import styled from 'styled-components'
import { NavDropdown } from './NavDropdown/NavDropdown'

export const UserDropdown = () => {
  const [user] = useAuthState(getAuth())
  const { logout } = useAuth()
  const navigate = useNavigate()

  const { data: options } = useGetResources<IStoryBoardOptions>(storyboardPaths.options)
  const { trigger: startPaymentAsync, isMutating: isStartingPayment } = usePostResource<
    { type: subscriptionsPlansEnum; success_redirect_url: string; failed_redirect_url: string },
    { payment_link: string }
  >(storyboardPaths.payment, {
    onError(error) {
      const { code, message, status } = error
      recordStoryActionEvent({
        interactionElement: interactionEventsEnum.generatePaymentLinkFailed,
        error: { code, message, status },
      })
      toast.error('Could not generate a payment link')
    },
    onSuccess(data) {
      console.log('data', data)
      window.open(data.payment_link, '_blank')
    },
  })

  const [show, setShow] = useState(false)

  const onUpgrade = async (type: subscriptionsPlansEnum) => {
    const success_redirect_url =
      process.env.NODE_ENV === 'development'
        ? `http://localhost:3000${pagesRoutesEnum.paymentSuccess}`
        : `${window.location.protocol}//${window.location.hostname}${pagesRoutesEnum.paymentSuccess}`
    const failed_redirect_url =
      process.env.NODE_ENV === 'development'
        ? `http://localhost:3000${pagesRoutesEnum.paymentFailed}`
        : `${window.location.protocol}//${window.location.hostname}${pagesRoutesEnum.paymentFailed}`

    startPaymentAsync({ type, success_redirect_url, failed_redirect_url })
  }

  return (
    <Div>
      <NavDropdown
        toggler={
          <span className="flex gap-1 align-items-center border-1 pl-1 border-round-2xl cursor-pointer">
            <MdGeneratingTokens /> {options?.max_amount_of_scenes}
            <Avatar
              image={user?.photoURL || 'https://picsum.photos/32/32'}
              imageAlt="user-avatar"
              className="border-circle"
              shape="circle"
            />
          </span>
        }
        className="dropdown"
        show={show}
        setShow={setShow}
      >
        <div className="flex flex-column gap-4 px-5 py-3 border-round-3xl">
          {user && <div>{user.email}</div>}

          {options && (
            <div className="flex align-items-center justify-content-between">
              <span>{options.max_amount_of_scenes} credits</span>

              <Button
                isLoading={isStartingPayment}
                onClick={() => {
                  recordChangePageEvent({
                    page: window.location.pathname,
                    to: pagesRoutesEnum.subscriptions,
                    interactionElement: changePageInteractionEventsEnum.upgradeToProNavDropdownTab,
                  })
                  navigate(pagesRoutesEnum.subscriptions)
                }}
              >
                Buy credits
              </Button>
            </div>
          )}

          <Button
            variant="outline"
            onClick={() => {
              logout()
            }}
            className="mt-2"
          >
            Sign out
          </Button>
        </div>
      </NavDropdown>
    </Div>
  )
}

const Div = styled.div`
  color: black;
`
