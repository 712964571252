import { useFirebase } from 'features/auth/hooks/useFirebase'
import { recordStoryActionEvent } from 'features/eventTracking/eventTracking.api'
import { interactionEventsEnum } from 'features/eventTracking/eventTracking.enums'
import { scenePaths } from 'features/scene/scene.api'
import { generatedImageAtom } from 'features/scene/scene.state'
import { Unsubscribe, collection, onSnapshot, query, where } from 'firebase/firestore'
import { useAtom, useSetAtom } from 'jotai'
import { pagesRoutesEnum } from 'pages/Router/router.enums'
import { useEffect, useMemo, useRef } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { Id, toast } from 'react-toastify'
import { Button } from 'shared/components/Buttons/Button'
import { useTheme } from 'styled-components'
import { mutate } from 'swr'
import { storyboardPaths } from '../storyboard.api'
import { activeSceneAtom, storyboardEventIdsAtom } from '../storyboard.state'
import { IFirebaseEvent } from '../storyboard.types'

export const useStoryboardsEvents = (resourceIds: string[]) => {
  const [activeScene, setActiveScene] = useAtom(activeSceneAtom)

  const { db } = useFirebase()
  const toastRef = useRef<{ [k: string]: Id }>({})
  const navigate = useNavigate()
  const theme = useTheme()

  const { pathname } = useLocation()

  const setGeneratedImageForm = useSetAtom(generatedImageAtom)
  const setActiveStoryBoardEvents = useSetAtom(storyboardEventIdsAtom)

  const successToastTemplate = (id: string) => () =>
    (
      <div className="flex flex-column gap-1">
        <p className="mb-2">Your story is ready.</p>
        {!pathname.startsWith(pagesRoutesEnum.storyboard) && (
          <div>
            <Button
              onClick={() => {
                toast.dismiss(toastRef.current[id])
                delete toastRef.current[id]
                navigate(`${pagesRoutesEnum.storyboard}/${id}`)
              }}
              variant="outline"
              color={theme.colors.success}
            >
              Click here to view
            </Button>
          </div>
        )}
      </div>
    )

  const updateProgressToastTemplate = (activeEvent: IFirebaseEvent) => () =>
    (
      <div>
        <h3 className="mb-2">Preparing your scenes...</h3>
        <p>
          Total: <b>{activeEvent.total}</b> - Completed: <b>{activeEvent.completed}</b>
        </p>
      </div>
    )

  const messagesMap = useMemo(() => {
    return {
      story: {
        loadingStarted: 'We are parsing your script. Hang tight for a few minutes while we do some AI magic.',
        failed: 'Your story failed to generate',
      },
      frame: {
        loadingStarted: 'Your image is generating',
        failed: 'Your image failed to generate',
      },
    }
  }, [])

  useEffect(() => {
    let unsubscribe: null | Unsubscribe = null
    if (resourceIds.length) {
      const q = query(collection(db, 'events'), where('__name__', 'in', resourceIds))
      unsubscribe = onSnapshot(q, (querySnapshot) => {
        querySnapshot.forEach(async (doc) => {
          console.log('doc.data()', doc.data())
          console.log('doc.id', doc.id)

          const activeEvent = doc.data() as IFirebaseEvent
          const eventType = activeEvent.event as IFirebaseEvent['event']
          const currentToastRef = toastRef.current[doc.id]

          if (!currentToastRef) {
            toastRef.current[doc.id] = toast.loading(messagesMap[eventType].loadingStarted)
          }

          if (eventType === 'story') {
            // Refresh the list of stories.
            mutate(storyboardPaths.storyboards)
          }

          if (activeEvent.failed_to_generate) {
            currentToastRef
              ? toast.update(currentToastRef, {
                  type: 'error',
                  render: messagesMap[eventType].failed,
                  isLoading: false,
                  closeButton: true,
                  autoClose: 5000,
                })
              : toast.error(messagesMap[eventType].failed)

            delete toastRef.current[doc.id]

            if (eventType === 'story') {
              recordStoryActionEvent({
                interactionElement: interactionEventsEnum.createStoryFailed,
                scriptId: resourceIds,
              })
              mutate(storyboardPaths.storyboards)
            }

            if (eventType === 'frame') {
              setGeneratedImageForm(null)
            }

            setActiveStoryBoardEvents((c) => c.filter((existingEvent) => existingEvent !== doc.id))
          } else if (activeEvent.total > 0 && activeEvent.completed < activeEvent.total && currentToastRef) {
            //throttle(() => mutate(storyboardPaths.storyboard(doc.id)), 500)
            toast.update(currentToastRef, {
              render: updateProgressToastTemplate(activeEvent),
              closeButton: true,
            })
          } else if (activeEvent.total > 0 && activeEvent.completed === activeEvent.total && currentToastRef) {
            if (eventType === 'story') {
              toast.update(currentToastRef, {
                render: successToastTemplate(doc.id),
                type: 'success',
                isLoading: false,
                closeButton: true,
                autoClose: 10000,
              })
            }

            if (eventType === 'frame') {
              mutate(scenePaths.frameGET(doc.id))
              toast.update(currentToastRef, {
                render: 'Your new image was successfully generated',
                type: 'success',
                isLoading: false,
                closeButton: true,
                autoClose: 5000,
              })
            }

            delete toastRef.current[doc.id]

            setActiveStoryBoardEvents((c) => c.filter((existingEvent) => existingEvent !== doc.id))
          }
        })
      })
    }

    return () => {
      unsubscribe && unsubscribe()
    }
  }, [resourceIds])
}
