import checkIcon from 'assets/checkIcon.png'
import { useGetResourcesImmutable } from 'features/storyboard/hooks/useGetResources'
import { storyboardPaths } from 'features/storyboard/storyboard.api'
import { IStoryBoardOptions } from 'features/storyboard/storyboard.types'
import { useEffect } from 'react'
import { LoadingAnimation } from 'shared/components/ApiStates/Loading/LoadingAnimation'
import { Button } from 'shared/components/Buttons/Button'
import styled from 'styled-components'

export const PaymentSuccess = () => {
  const {
    data: options,
    mutate: revalidateOptions,
    isLoading: isRevalidatingOptions,
  } = useGetResourcesImmutable<IStoryBoardOptions>(storyboardPaths.options)

  useEffect(() => {
    ;(async () => {
      await revalidateOptions()
    })()
  }, [])

  return (
    <Div listIcon={checkIcon}>
      <h2>Payment Successful</h2>
      {isRevalidatingOptions ? (
        <div>
          <LoadingAnimation size={1} />
          <p>Updating your credits... </p>
        </div>
      ) : (
        <div className="flex flex-column align-items-center justify-content-center gap-3 mt-4">
          {/* <h3 className="mb-5 mt-2">Scenes :{options?.max_amount_of_scenes}</h3> */}
          <p>Thank you for purchasing the Creator pack! Your account has been credited.</p>
          <Button onClick={() => window.open('', '_self')?.close()} className="mt-3" size="large">
            Close window
          </Button>
        </div>
      )}
    </Div>
  )
}

const Div = styled.div<{ listIcon: string }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  max-width: 600px;
  margin: -5rem auto auto;

  > h2 {
    font-size: 36px;
    line-height: 42px;
    color: ${({ theme }) => theme.colors.secondary_100};
  }
  > p {
    font-size: 16px;
    line-height: 150%;
    text-align: center;
    color: ${({ theme }) => theme.colors.secondary_100};
  }
`
