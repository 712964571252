import { IStoryBoardScene } from 'features/storyboard/storyboard.types'
import { Badge } from 'primereact/badge'
import { Column, ColumnFilterElementTemplateOptions } from 'primereact/column'
import { DataTable, DataTableExpandedRows } from 'primereact/datatable'
import { Dropdown } from 'primereact/dropdown'
import { InputText } from 'primereact/inputtext'
import { useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import styled from 'styled-components'
import { StoryboardSceneAction } from '../Storyboard/StoryboardSceneAction'
import { getUniqueCharacters, getUniqueCharactersForScene } from './CharacterUtils'
import { ISelectedRow } from './SceneSelectionStep'

const getSummaryForScene = (scene: IStoryBoardScene) => {
  const items: string[] = []
  for (const unit of scene.script_units) {
    items.push(unit.action)
    for (const turn of unit.turns) {
      items.push(turn.line)
    }
  }
  return items.join(' ')
}

interface IProps {
  scenes: IStoryBoardScene[]
  requestInProgress: boolean
  preselectedScenes?: number[]
  onSelectionChange: (scene_indices: number[]) => void
}
export const SceneSelectionTable = ({
  scenes,
  requestInProgress,
  onSelectionChange,
  preselectedScenes = [],
}: IProps) => {
  const [expandedRows, setExpandedRows] = useState<null | DataTableExpandedRows>(null)
  const [summaryFilter, setSummaryFilter] = useState('')

  const characters = scenes.map((scene) => getUniqueCharactersForScene(scene))
  const hideCharacters = characters.every((characters) => characters.size === 0)
  const hideTime = scenes.every((scene) => scene.time === null || scene.time === '')
  const hideLocations = scenes.every((scene) => scene.location === null || scene.location === '')

  const rows: ISelectedRow[] = scenes.map((scene) => {
    return {
      id: scene.id,
      sceneIndex: scene.index + 1,
      location: scene.location,
      time: scene.time,
      summary: getSummaryForScene(scene),
      characters: [...getUniqueCharactersForScene(scene)].sort(),
      // tags: scene.tags,
    }
  })

  // By default, select first 10 rows.
  const [selectedScenes, setSelectedScenes] = useState<ISelectedRow[]>(rows.slice(0, 10))

  useEffect(() => {
    const scene_indices = selectedScenes.map((scene) => scene.sceneIndex - 1)
    onSelectionChange(scene_indices)
  }, [selectedScenes])

  const charactersRowFilterTemplate = (options: ColumnFilterElementTemplateOptions) => {
    return (
      <Dropdown
        filter
        value={options.value}
        options={[...getUniqueCharacters(scenes)].sort()}
        onChange={(e) => options.filterApplyCallback(e.value)}
        itemTemplate={(option) => <p>{option}</p>}
        placeholder="Filter by character"
        className="p-column-filter"
        showClear
        style={{ minWidth: '12rem' }}
      />
    )
  }

  const timeRowFilterTemplate = (options: ColumnFilterElementTemplateOptions) => {
    const choices = [
      ...new Set(
        scenes
          .filter((scene) => scene.time)
          .map((scene) => scene.time)
          .flat(),
      ),
    ]
    return (
      <Dropdown
        filter
        value={options.value}
        options={choices}
        onChange={(e) => options.filterApplyCallback(e.value)}
        itemTemplate={(option) => <p>{option}</p>}
        placeholder="Filter by time"
        className="p-column-filter"
        showClear
        style={{ minWidth: '12rem' }}
      />
    )
  }

  const locationRowFilterTemplate = (options: ColumnFilterElementTemplateOptions) => {
    const choices = [...new Set(scenes.map((scene) => scene.location))]
    return (
      <Dropdown
        filter
        value={options.value}
        options={choices}
        onChange={(e) => options.filterApplyCallback(e.value)}
        itemTemplate={(option) => <p>{option}</p>}
        placeholder="Filter by location"
        className="p-column-filter"
        showClear
        style={{ minWidth: '12rem' }}
      />
    )
  }

  const summaryRowFilterTemplate = () => {
    return (
      <InputText
        onChange={(e) => setSummaryFilter(e.target.value)}
        placeholder="Search in scenes"
        className="p-column-filter"
        value={summaryFilter}
        style={{ minWidth: '12rem' }}
      />
    )
  }

  const sceneIndexRowFilterTemplate = (options: ColumnFilterElementTemplateOptions) => {
    return (
      <InputText
        value={options.value || ''}
        onChange={(e) => options.filterApplyCallback(e.target.value)}
        className="p-column-filter"
        style={{ maxWidth: '4rem' }}
      />
    )
  }

  const allowExpansion = (rowData: ISelectedRow) => {
    const scene = scenes.find((scene) => scene.id === rowData.id)
    return !!rowData.summary && !!scene
  }

  const rowExpansionTemplate = (data: ISelectedRow) => {
    const scene = scenes.find((scene) => scene.id === data.id)
    if (scene) {
      return (
        <div className="flex justify-content-center">
          <StoryboardSceneAction scene={scene} />
        </div>
      )
    }
  }

  const onChangeSelection = (e: any) => {
    const newSelectedItems = e.value.filter((item: ISelectedRow) => !preselectedScenes.includes(item.sceneIndex - 1))

    const preselectedItems = e.value.filter((item: ISelectedRow) => preselectedScenes.includes(item.sceneIndex - 1))

    // Select all was clicked
    const MAX_SCENES = 10
    if (newSelectedItems.length > MAX_SCENES) {
      toast.warn(
        <div className="flex flex-column gap-2 text-base">
          <h3>At most {MAX_SCENES} scenes can be selected.</h3>
          <p>You will be able to add more scenes later.</p>
        </div>,
        { toastId: 'selectAllToast' },
      )
    } else if (newSelectedItems.length > MAX_SCENES) {
      // Attempt to select another cell
      toast.warn(
        <div className="flex flex-column gap-2 text-base">
          <h3>Maximum {MAX_SCENES} scenes can be selected.</h3>
          <p>Please deselect one scene in order to select this one</p>
        </div>,
        { toastId: 'selectAnotherToast' },
      )
    }

    setSelectedScenes([...preselectedItems, ...newSelectedItems.slice(0, MAX_SCENES)])
  }

  const filteredRows = summaryFilter
    ? rows.filter((row) => row.summary.toLowerCase().includes(summaryFilter.toLowerCase()))
    : rows

  const colWidth = 250 // in pixels
  let summaryColWidth = colWidth
  if (hideLocations) summaryColWidth += colWidth
  if (hideTime) summaryColWidth += colWidth
  if (hideCharacters) summaryColWidth += colWidth

  return (
    <Div>
      <div className="flex flex-row gap-2">
        {selectedScenes
          .sort((s1, s2) => {
            if (s1.sceneIndex < s2.sceneIndex) return -1
            if (s1.sceneIndex == s2.sceneIndex) return 0
            return 1
          })
          //     severity?: 'success' | 'info' | 'warning' | 'danger' | null | undefined;
          .map((scene, idx) => (
            <Badge key={idx} value={scene.sceneIndex} style={{ background: '#fff3e0' }} />
          ))}
      </div>
      <DataTable
        style={{ minHeight: '100%' }}
        tableStyle={{ minHeight: '100%' }}
        size="small"
        //showGridlines
        stripedRows
        rowExpansionTemplate={rowExpansionTemplate}
        expandedRows={expandedRows || undefined}
        globalFilterFields={['location', 'summary', 'time', 'characters']}
        onRowToggle={(e) => setExpandedRows(e.data as DataTableExpandedRows)}
        value={filteredRows}
        isDataSelectable={(e: any) => !preselectedScenes.includes(e.data.sceneIndex - 1)}
        //header={} // TODO(julia): Add the title of the screenplay here.
        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
        dataKey="id"
        selection={selectedScenes}
        onSelectionChange={onChangeSelection}
        filterDisplay="row"
        emptyMessage="No scenes found."
        currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
        disabled={requestInProgress}
        showSelectionElement={(data) => (!preselectedScenes.includes(data.sceneIndex - 1) ? true : null)}
      >
        <Column
          className="table-no-select-all"
          selectionMode="multiple"
          //headerStyle={{ width: '3rem' }}
          showClearButton={false}
          showAddButton={false}
          showApplyButton={false}
          showFilterMatchModes={false}
          showFilterMenu={false}
          showFilterMenuOptions={false}
          showFilterOperator={false}
          header={false}
          filterHeader={false}
        />
        <Column
          field="sceneIndex"
          //header="Index"
          //filter
          //filterElement={sceneIndexRowFilterTemplate}
          style={{ maxWidth: '70px' }}
        />
        <Column
          field="location"
          //header="Location"
          filter
          filterPlaceholder="Search by location"
          filterElement={locationRowFilterTemplate}
          showFilterMenu={false}
          showClearButton={false}
          style={{ minWidth: `${colWidth}px`, maxWidth: `${colWidth}px` }}
          hidden={hideLocations}
        />
        <Column
          field="time"
          //header="Time"
          filter
          filterPlaceholder="Search by time"
          filterElement={timeRowFilterTemplate}
          showFilterMenu={false}
          showClearButton={false}
          style={{ minWidth: `${colWidth}px`, maxWidth: `${colWidth}px` }}
          hidden={hideTime}
        />
        <Column expander={allowExpansion} style={{ width: '1rem' }} />
        <Column
          field="summary"
          filterField="summary"
          //header="Scene"
          filter
          filterPlaceholder="Search scene"
          filterElement={summaryRowFilterTemplate}
          expander={allowExpansion}
          body={(data: ISelectedRow) => {
            return (
              <div
                className="cursor-pointer white-space-nowrap overflow-hidden text-overflow-ellipsis"
                style={{ width: summaryColWidth }}
              >
                {data.summary}
              </div>
            )
          }}
          showFilterMenu={false}
          showClearButton={false}
          style={{ width: summaryColWidth }}
        />
        <Column
          field="characters"
          //header="Characters"
          body={(data: ISelectedRow) => {
            return data?.characters?.join(', ')
          }}
          filter
          filterElement={charactersRowFilterTemplate}
          showFilterMenu={false}
          showClearButton={false}
          style={{ minWidth: `${colWidth}px`, maxWidth: `${colWidth}px` }}
          hidden={hideCharacters}
        />
      </DataTable>
    </Div>
  )
}

const Div = styled.div`
  height: 30rem;

  //Hide the "select all" option, since we only allow 10 scenes at a time.
  .p-datatable .p-datatable-wrapper .p-datatable-table .p-datatable-thead .table-no-select-all .p-checkbox {
    display: none !important;
  }

  .scene-action {
    max-width: 600px;
  }

  .p-datatable-loading-overlay.p-component-overlay {
    min-height: 100%;
  }
`
