import { FileUploadSelectEvent } from 'primereact/fileupload'
import { useEffect, useRef } from 'react'
import { toast } from 'react-toastify'
import FileUploader from 'shared/components/DocumentUploader/FileUploader'
import styled from 'styled-components'
import { IRegenerateScene } from '../scene.types'

interface IImageTabProps {
  header: string
  formData: IRegenerateScene
  setFormData: (prev: any) => void
}
const ImageTab = ({ header, formData, setFormData }: IImageTabProps) => {
  // states

  //utils
  const maxFileSize = 1000000 * 10
  const uploaderRef = useRef<any>(null)

  //handlers
  const onTemplateSelect = (e: FileUploadSelectEvent) => {
    const files = e.files
    if (files[0].size < maxFileSize) {
      setFormData((prev: IRegenerateScene) => {
        return { ...prev, image: files[0] }
      })
    }
  }

  const onTemplateRemove = (file: any, callback: any) => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { image: _, ...rest } = formData

    setFormData(rest)
    callback()
  }

  const onValidationFail = (file: File) => {
    toast.error(`${file.name} too big. Maximum upload size is 100MB`)
  }

  useEffect(() => {
    if (formData?.image) {
      uploaderRef?.current.setFiles([formData?.image])
    }
  }, [])

  return (
    <Div>
      {header && <h2>{header}</h2>}
      <div className="flex align-items-center justify-content-center">
        <FileUploader
          preamble=<span>Drag and drop your image here, or </span>
          forwardedRef={uploaderRef}
          onTemplateRemove={onTemplateRemove}
          onSelect={onTemplateSelect}
          onValidationFail={onValidationFail}
          maxFileSize={maxFileSize}
          accept="image/*"
        />
      </div>

      {formData.image && (
        <div className="image">
          <img src={URL.createObjectURL(formData.image)} alt="inspirational_image" />
        </div>
      )}
    </Div>
  )
}

const Div = styled.div`
  color: ${({ theme }) => theme.colors.secondary_100};
  width: 600px;
  display: flex;
  flex-direction: column;
  gap: 1rem;

  h2 {
    font-weight: 600;
    font-size: 21px;
    line-height: 132%;
  }
  .image {
    align-self: center;
    max-width: 600px;
    max-height: 600px;
    img {
      border-radius: 12px;
    }
  }
`

export default ImageTab
