import useSWRMutation, { SWRMutationConfiguration } from 'swr/mutation'
import { deleteStoryResource } from '../storyboard.api'

/**
 * @parameter key is the api path of the resource
 * Usage example
 *  const { trigger, isMutating } = usePostMultiPartResource<ICreateStoryBoard, IStoryBoard>(storyboardPaths.storyboard('x'))
 */
export const useDeleteResource = <K, T>(
  key: string,
  config: SWRMutationConfiguration<K, any, T, string, any> | undefined = {},
) => {
  const { trigger, isMutating } = useSWRMutation(key, deleteStoryResource<K>, { ...config })

  return { trigger, isMutating }
}
