import { PrimitiveAtom, useAtom } from 'jotai'
import { onDataStringChange } from 'shared/utils/handlers'
import styled from 'styled-components'
import noneOption from '../../../assets/noneOption.png'
import { IRegenerateScene } from '../scene.types'
import SelectItem from './SelectItem'
interface ICameraAngleTab {
  isGenerating: boolean
  formAtom: PrimitiveAtom<IRegenerateScene>
}

const CameraAngleTab = ({ isGenerating, formAtom }: ICameraAngleTab) => {
  const [form, setForm] = useAtom(formAtom)
  const { camera_angle } = form
  const cameraAngleOptions = [
    {
      title: '',
      image: noneOption,
    },
    {
      title: 'Extreme Close Up',
      image: 'https://storia-ai.s3.amazonaws.com/shots/extreme-close-up.jpeg',
    },
    {
      title: 'Close Up',
      image: 'https://storia-ai.s3.amazonaws.com/shots/close-up.jpeg',
    },
    {
      title: 'Medium Close Up',
      image: 'https://storia-ai.s3.amazonaws.com/shots/medium-close-up.jpeg',
    },
    {
      title: 'Medium Shot',
      image: 'https://storia-ai.s3.amazonaws.com/shots/medium.jpeg',
    },
    {
      title: 'Cowboy Shot',
      image: 'https://storia-ai.s3.amazonaws.com/shots/cowboy.jpeg',
    },
    {
      title: 'Medium Full Shot',
      image: 'https://storia-ai.s3.amazonaws.com/shots/medium-full.jpeg',
    },
    {
      title: 'Full Shot',
      image: 'https://storia-ai.s3.amazonaws.com/shots/full.jpeg',
    },
    {
      title: 'Wide Shot',
      image: 'https://storia-ai.s3.amazonaws.com/shots/wide.jpeg',
    },
    {
      title: 'Extreme Wide Shot',
      image: 'https://storia-ai.s3.amazonaws.com/shots/extreme-wide.jpeg',
    },
  ]

  const onChange = (item: any) => {
    if (!isGenerating) {
      onDataStringChange(setForm)('camera_angle', item.title)
    }
  }

  return (
    <Div>
      <h2>Select your preferred camera angle.</h2>
      <div className="options">
        {cameraAngleOptions.map((item, index) => (
          <SelectItem onClick={() => onChange(item)} selected={item.title === camera_angle} item={item} key={index} />
        ))}
      </div>
    </Div>
  )
}

const Div = styled.div`
  color: ${({ theme }) => theme.colors.secondary_100};
  h2 {
    font-weight: 600;
    font-size: 21px;
    line-height: 132%;
    margin: 2rem 0;
    margin-top: 1rem;
  }
  .options {
    display: grid;
    gap: 1.5rem;
    grid-template-columns: repeat(auto-fill, minmax(136px, 1fr));
  }
`

export default CameraAngleTab
