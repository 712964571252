import { User } from 'firebase/auth'
import { Navigate, Outlet } from 'react-router-dom'
import { pagesRoutesEnum } from './router.enums'

interface IProps {
  user?: User | null
}
export const UnauthenticatedRoute = ({ user }: IProps) => {
  return user ? <Navigate to={pagesRoutesEnum.home} /> : <Outlet />
}
