import { useStoryboardsEvents } from 'features/storyboard/hooks/useStoryboardEvents'
import { storyboardEventIdsAtom } from 'features/storyboard/storyboard.state'
import { useAtomValue } from 'jotai'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import usePageTracking from 'shared/hooks/useChangePageTracking'
import './App.css'
import { Router } from './pages/Router/Router'

function App() {
  const activeStoryboardEvents = useAtomValue(storyboardEventIdsAtom)
  useStoryboardsEvents(activeStoryboardEvents)
  usePageTracking()

  return (
    <>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={true}
        newestOnTop={false}
        closeOnClick
        pauseOnFocusLoss
        pauseOnHover
        theme="light"
        className="pt-6"
      />
      <Router />
    </>
  )
}

export default App
