import { User } from 'firebase/auth'
import { Navigate, Outlet } from 'react-router-dom'
import { pagesRoutesEnum } from './router.enums'

interface IProps {
  user?: User | null
}
export const ProtectedRoutes = ({ user }: IProps) => {
  return user ? <Outlet /> : <Navigate to={pagesRoutesEnum.login} replace={false} />
}
