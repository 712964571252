import { StoriesList } from 'features/storyboard/components/Storyboard/StoriesList'
import { useGetResources } from 'features/storyboard/hooks/useGetResources'
import { storyboardPaths } from 'features/storyboard/storyboard.api'
import { IStoryBoardSlim } from 'features/storyboard/storyboard.types'
import { NoContentBox } from 'shared/components/ApiStates/EmptyResponse/NoContentBox'
import { LoadingBox } from 'shared/components/ApiStates/Loading/LoadingBox'
import { Layout } from 'theme/Layouts/Layout'

export const MyProjects = () => {
  const { data, isLoading } = useGetResources<{ stories: IStoryBoardSlim[] }>(storyboardPaths.storyboards)
  const stories = data?.stories || []

  if (isLoading) return <LoadingBox />
  return (
    <Layout>
      <div className="p-6">
        {stories.length ? (
          <div>
            <p>These are the storyboards you created. Your masterpiece would look great on the big screen.</p>
            <StoriesList stories={stories} deletable={true} />
          </div>
        ) : (
          <NoContentBox message="You haven't created any storyboards yet." />
        )}
      </div>
    </Layout>
  )
}
