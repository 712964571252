import { useEffect } from 'react'
import ReactGA from 'react-ga4'
import { useLocation } from 'react-router-dom'

const usePageTracking = () => {
  const location = useLocation()
  ReactGA.initialize('G-N2MWC8DNZX')

  useEffect(() => {
    const page = location.pathname + location.search
    ReactGA.send({ hitType: 'pageview', page, title: location.pathname })
  }, [location])
}

export default usePageTracking
