import { recordChangePageEvent } from 'features/eventTracking/eventTracking.api'
import { changePageInteractionEventsEnum } from 'features/eventTracking/eventTracking.enums'
import { pagesRoutesEnum } from 'pages/Router/router.enums'
import { useNavigate } from 'react-router-dom'
import { Button } from 'shared/components/Buttons/Button'
import { UserDropdown } from './UserDropdown'

export const SignedInUserActions = () => {
  const navigate = useNavigate()

  return (
    <div className="flex align-items-center gap-3">
      <Button
        className="hidden md:block"
        onClick={() => {
          recordChangePageEvent({
            page: window.location.pathname,
            to: pagesRoutesEnum.create,
            interactionElement: changePageInteractionEventsEnum.startStoryNavButton,
          })
          navigate(pagesRoutesEnum.create)
        }}
      >
        Create Storyboard
      </Button>

      <UserDropdown />
    </div>
  )
}
