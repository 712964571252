import { InputText } from 'primereact/inputtext'
import { Password } from 'primereact/password'
import { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { onDataChange } from 'shared/utils/handlers'
import { Layout } from 'theme/Layouts/Layout'
import { useAuth } from '../features/auth/hooks/useAuth'
import { Button } from '../shared/components/Buttons/Button'
import { pagesRoutesEnum } from './Router/router.enums'

export const Register = () => {
  const { signUp: signUp, socialSignIn: socialSignIn, errorCode: errorCode } = useAuth()
  const [emailErrorMessage, setEmailErrorMessage] = useState('')
  const [passwordErrorMessage, setPasswordErrorMessage] = useState('')

  const [data, setData] = useState({
    email: '',
    password: '',
  })

  // Clear out the error message if the user removed the wrong credentials.
  useEffect(() => {
    if (!data.email) {
      setEmailErrorMessage('')
    }
    if (!data.password) {
      setPasswordErrorMessage('')
    }
  }, [data])

  useEffect(() => {
    if (errorCode == 'auth/invalid-email') {
      setEmailErrorMessage('The email address is not valid.')
    }
    if (errorCode === 'auth/weak-password') {
      setPasswordErrorMessage('The password should be at least 6 characters.')
    } else {
      // We ignore certain error codes, like when the user closes up the popup window to sign up with Google.
      console.log(errorCode)
    }
  }, [errorCode])

  const onSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    // TODO: add loading button.
    // Make sure this really is asynchronous.
    await signUp(data)
  }

  const { email, password } = data
  return (
    <Layout>
      <div className="flex flex-column align-items-stretch max-w-30rem mx-auto py-7 px-5 gap-5">
        <div className="text-center flex flex-column gap-3">
          <h1>Sign up</h1>
          <p className="text-center">
            Already have an account?{' '}
            <Link className="underline font-bold" to={pagesRoutesEnum.login}>
              Sign in
            </Link>{' '}
          </p>
        </div>

        <form onSubmit={onSubmit} className="flex flex-column gap-5">
          <span className="p-float-label">
            <InputText
              id="email"
              name="email"
              value={email}
              onChange={onDataChange(setData)}
              className={emailErrorMessage ? 'w-full p-inputtext-lg p-invalid' : 'w-full p-inputtext-lg'}
            />
            <small id="email-help" style={{ color: 'red' }}>
              {emailErrorMessage}
            </small>
            <label htmlFor="email">Email address</label>
          </span>

          <span className="p-float-label">
            <Password
              type="password"
              id="password"
              name="password"
              value={password}
              onChange={onDataChange(setData)}
              feedback={false}
              toggleMask
              className={passwordErrorMessage ? 'w-full p-inputtext-lg p-invalid' : 'w-full p-inputtext-lg'}
            />
            <small id="password-help" style={{ color: 'red' }}>
              {passwordErrorMessage}
            </small>
            <label htmlFor="password">Password</label>
          </span>

          <Button size="large" type="submit">
            Sign up
          </Button>
        </form>
        <Button size="large" onClick={socialSignIn} variant="outline">
          <i className="pi pi-google"></i> Sign up with Google
        </Button>
      </div>
    </Layout>
  )
}
