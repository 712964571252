import cx from 'classnames'
import { recordChangePageEvent } from 'features/eventTracking/eventTracking.api'
import { changePageInteractionEventsEnum } from 'features/eventTracking/eventTracking.enums'
import { getAuth } from 'firebase/auth'
import { useAuthState } from 'react-firebase-hooks/auth'
import { NavLink, useLocation } from 'react-router-dom'
import styled from 'styled-components'
import { ReactComponent as StoriaLogo } from '../../assets/full-logo-orange-with-black.svg'
import { pagesRoutesEnum } from '../../pages/Router/router.enums'
import { NotSignedInUserActions } from './NotSignedInUserActions'
import { SignedInUserActions } from './SignedInUserActions'

export const Nav = () => {
  const [user] = useAuthState(getAuth())
  const location = useLocation()

  const signedInLinks = [
    {
      label: 'My Storyboards',
      path: pagesRoutesEnum.projects,
      icon: 'pi pi-briefcase',
      id: changePageInteractionEventsEnum.myProjectsNavLink,
    },
  ]

  const links = [
    {
      label: 'Home',
      path: pagesRoutesEnum.root,
      icon: 'pi pi-image',
      id: changePageInteractionEventsEnum.homePageNavLink,
    },
    ...(user ? signedInLinks : []),
    {
      label: 'Pricing',
      path: pagesRoutesEnum.subscriptions,
      icon: 'pi pi-briefcase',
      id: changePageInteractionEventsEnum.upgradeToProPricingPage,
    },
  ]

  return (
    <StyledNav className="px-4 flex align-items-center justify-content-between">
      <NavLink className="flex-1 hidden md:block" to={pagesRoutesEnum.home}>
        <StyledStoriaLogo width={120} height={30} />
      </NavLink>

      <div className="main-nav flex gap-4 h-full align-items-center justify-content-center flex-1">
        {links.map(({ label, path, id }, index) => (
          <NavLink
            id={id}
            to={path}
            key={index}
            onClick={(e: React.MouseEvent<HTMLAnchorElement>) =>
              recordChangePageEvent({
                page: location.pathname,
                to: path,
                interactionElement: (e.target as HTMLAnchorElement).id as changePageInteractionEventsEnum,
              })
            }
            className={({ isActive }) =>
              cx('flex align-items-center relative h-full hover white-space-nowrap', { active: isActive })
            }
          >
            {label}
          </NavLink>
        ))}
      </div>
      <div className="align-items-center justify-content-end gap-3 ml-auto flex-1 flex">
        {user ? <SignedInUserActions /> : <NotSignedInUserActions />}
      </div>
    </StyledNav>
  )
}

const StyledNav = styled.nav`
  z-index: 999;
  background-color: ${({ theme }) => theme.colors.white};
  border-bottom: 1px solid #ecedee;
  color: ${({ theme }) => theme.colors.black};
  height: ${({ theme }) => theme.constants.navbarHeight};
  position: fixed;
  inset: 0 0 auto;

  > .main-nav {
    a.active,
    a:hover {
      &:after {
        content: '';
        position: absolute;
        inset: auto 0 0;
        border-bottom: 1px solid ${({ theme }) => theme.colors.primary_100};
      }
    }
  }
`
const StyledStoriaLogo = styled(StoriaLogo)`
  transform: translateX(20px);
  height: 30px;
`
