import { useRef } from 'react'

import cx from 'classnames'

import { DetailedHTMLProps, Dispatch, HTMLAttributes, SetStateAction } from 'react'
import { Button } from 'shared/components/Buttons/Button'
import useOnClickOutside from 'shared/hooks/useOnClickOutside'
import useWindowScreenSize from 'shared/hooks/useWindowScreenSize'
import { NavDialog } from '../NavDialog/NavDialog'
import { StyledBackground, StyledContainer, StyledDropdownBody } from './styles'

export interface NavDropdownProps
  extends NavDropdownStyleProps,
    Omit<DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement>, 'ref'> {
  toggler: JSX.Element
  setShow: Dispatch<SetStateAction<boolean>>
  show: boolean
  hasCloseButton?: boolean
  hasMobileDialog?: boolean
}

export interface NavDropdownStyleProps {
  backgroundColor?: string
  extendOnTablet?: boolean
}

export const NavDropdown: React.FC<NavDropdownProps> = ({
  show,
  setShow,
  toggler,
  children,
  hasCloseButton,
  hasMobileDialog,
  extendOnTablet,
  backgroundColor = '#fff',
  ...htmlProps
}) => {
  const { isBiggerThenMobile } = useWindowScreenSize()
  const bodyRef = useRef<HTMLDivElement | null>(null)
  useOnClickOutside(bodyRef, () => setShow(false))

  return (
    <>
      {show && <StyledBackground />}
      <div ref={bodyRef}>
        <StyledContainer {...htmlProps}>
          <div role="button" className={cx('toggler', { active: show })} onClick={() => setShow((c) => !c)}>
            {toggler}
          </div>

          {show && isBiggerThenMobile && (
            <StyledDropdownBody backgroundColor={backgroundColor} extendOnTablet={extendOnTablet}>
              {hasCloseButton && (
                <Button iconOnly className="close-button">
                  <i className="pi pi-close"></i>
                </Button>
              )}

              {children}
            </StyledDropdownBody>
          )}
        </StyledContainer>

        {show &&
          !isBiggerThenMobile &&
          (hasMobileDialog ? (
            <NavDialog onClose={() => setShow(false)}>{children}</NavDialog>
          ) : (
            <StyledDropdownBody backgroundColor={backgroundColor} extendOnTablet={extendOnTablet}>
              {hasCloseButton && (
                <Button iconOnly className="close-button">
                  <i className="pi pi-close"></i>
                </Button>
              )}

              {children}
            </StyledDropdownBody>
          ))}
      </div>
    </>
  )
}
