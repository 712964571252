import { Button } from 'primereact/button'
import { FileUpload, FileUploadErrorEvent, FileUploadProps } from 'primereact/fileupload'
import { Ref, useEffect, useRef } from 'react'
import styled from 'styled-components'
interface IFileUploaderProps extends FileUploadProps {
  preamble: JSX.Element
  forwardedRef?: Ref<any>
  onTemplateRemove: (file: any, callback: any) => void
}
const FileUploader = ({ forwardedRef, onTemplateRemove, preamble, ...props }: IFileUploaderProps) => {
  const chooseBtnRef = useRef<any>(null)
  const cancelBtnRef = useRef<any>(null)

  const headerTemplate = (options: any) => {
    const { className, chooseButton /* uploadButton*/, cancelButton } = options
    return (
      <>
        <div ref={chooseBtnRef} className={className}>
          {chooseButton}
        </div>
        <div ref={cancelBtnRef} className={className}>
          {cancelButton}
        </div>
      </>
    )
  }

  const itemTemplate = (file: any, props: any) => {
    return (
      <div className="flex flex-wrap gap-3">
        <div className="flex align-items-center">
          <span className="flex flex-column gap-2 text-left ml-3">
            {file.name}
            <span className="border-round-xl border-500 px-2 py-1 border-1 w-7rem ">{props.formatSize}</span>
          </span>
        </div>

        <Button
          type="button"
          icon="pi pi-times"
          className="p-button-outlined p-button-rounded p-button-danger ml-auto"
          onClick={() => onTemplateRemove(file, props.onRemove)}
        />
      </div>
    )
  }

  const emptyTemplate = () => {
    return (
      <Div>
        <p>
          {preamble}
          <span
            onClick={() => {
              chooseBtnRef.current?.children[0].click()
            }}
            className="primary_100 cursor-pointer"
          >
            browse to choose a file.
          </span>
        </p>
      </Div>
    )
  }

  useEffect(() => {
    return () => {
      cancelBtnRef.current?.children[0].click()
    }
  }, [])

  return (
    <StyledFileUpload
      ref={forwardedRef}
      name="demo[]"
      url="/api/upload"
      headerTemplate={headerTemplate}
      headerClassName="hidden"
      itemTemplate={itemTemplate}
      emptyTemplate={emptyTemplate}
      onError={(event: FileUploadErrorEvent) => {
        console.log('event', event)
      }}
      {...props}
    />
  )
}

const StyledFileUpload = styled(FileUpload)`
  max-width: 900px;
  width: 100%;
`

const Div = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 300px;
  p {
    font-weight: 500;
    font-size: 16px;
    text-align: center;
    letter-spacing: 0.005em;
  }
`

export default FileUploader
