import { InspiringStoriesWidget } from 'features/storyboard/components/Storyboard/InspiringStoriesWidget'
import { NoStoriesWidget } from 'features/storyboard/components/Storyboard/NoStoriesWidget'
import { Layout } from '../theme/Layouts/Layout'

export const Home = () => {
  return (
    <Layout>
      <div className="p-3">
        <div className="mb-3">
          <div className="flex flex-column align-items-center gap-3">
            <NoStoriesWidget />
            <a
              href="https://www.producthunt.com/posts/storiaboard?utm_source=badge-featured&utm_medium=badge&utm_souce=badge-storiaboard"
              target="_blank"
            >
              <img
                src="https://api.producthunt.com/widgets/embed-image/v1/featured.svg?post_id=415601&theme=neutral"
                alt="StoriaBoard - Pre&#0045;visualize&#0032;your&#0032;story&#0032;100x&#0032;faster&#0032;and&#0032;cheaper | Product Hunt"
                style={{ width: '250px', height: '54px' }}
                width="250"
                height="54"
              />
            </a>
          </div>
        </div>

        <div className="relative">
          <InspiringStoriesWidget />
        </div>
      </div>
    </Layout>
  )
}
