import { useFirebase } from 'features/auth/hooks/useFirebase'
import { recordStoryActionEvent } from 'features/eventTracking/eventTracking.api'
import { interactionEventsEnum } from 'features/eventTracking/eventTracking.enums'
import { storyFormAtom, storyboardEventIdsAtom } from 'features/storyboard/storyboard.state'
import { getAuth } from 'firebase/auth'
import { useAtomValue } from 'jotai'
import { CreateStoryPage } from 'pages/CreateStoryPage'
import { Inspiration } from 'pages/Inspiration'
import { MyProjects } from 'pages/MyStoryboards'
import { PaymentFailed } from 'pages/PaymentFailed'
import { PaymentSuccess } from 'pages/PaymentSuccess'
import { useEffect, useState } from 'react'
import { useAuthState } from 'react-firebase-hooks/auth'
import { Navigate, Route, Routes, useLocation } from 'react-router-dom'
import { LoadingBox } from 'shared/components/ApiStates/Loading/LoadingBox'
import { Home } from '../Home'
import { Login } from '../Login'
import { Register } from '../Register'
import { StoryboardPage } from '../StoryboardPage'
import { Subscriptions } from '../Subscriptions'
import { ProtectedRoutes } from './ProtectRoutes'
import { UnauthenticatedRoute } from './UnauthenticatedRoutes'
import { pagesRoutesEnum } from './router.enums'

export const Router = () => {
  useFirebase()
  const [user, loading] = useAuthState(getAuth())

  const storyForm = useAtomValue(storyFormAtom)
  const storyBoardActiveEvent = useAtomValue(storyboardEventIdsAtom)

  const [prevLocation, setPreviousLocation] = useState<pagesRoutesEnum>(pagesRoutesEnum.root)
  const location = useLocation()

  useEffect(() => {
    // User left the create story page and there is not active event
    if (
      prevLocation === pagesRoutesEnum.create &&
      location.pathname !== pagesRoutesEnum.create &&
      !storyBoardActiveEvent
    ) {
      recordStoryActionEvent(
        {
          interactionElement: interactionEventsEnum.createStoryAborted,
          storyBoardConfig: {
            ...storyForm,
            file: storyForm?.file?.name || null,
            fileSize: storyForm?.file?.size || null,
          },
        },
        window.location.pathname,
      )
    }
    setPreviousLocation(location.pathname as pagesRoutesEnum)
  }, [location])

  if (loading) return <LoadingBox />
  return (
    <Routes>
      {/* Always available routes */}
      <Route path="/" element={<Home />} />
      <Route path={pagesRoutesEnum.subscriptions} element={<Subscriptions />} />
      <Route path={pagesRoutesEnum.inspiration} element={<Inspiration />} />
      <Route element={<UnauthenticatedRoute user={user} />}>
        <Route path={pagesRoutesEnum.login} element={<Login />} />
        <Route path={pagesRoutesEnum.register} element={<Register />} />
      </Route>

      <Route element={<ProtectedRoutes user={user} />}>
        <Route path={pagesRoutesEnum.create} element={<CreateStoryPage />} />
        <Route path={pagesRoutesEnum.paymentSuccess} element={<PaymentSuccess />} />
        <Route path={pagesRoutesEnum.paymentFailed} element={<PaymentFailed />} />
        <Route path={`${pagesRoutesEnum.storyboard}/:id`} element={<StoryboardPage />} />
        <Route path={pagesRoutesEnum.projects} element={<MyProjects />} />
        <Route path="*" element={<Navigate to="/" />} />
      </Route>
    </Routes>
  )
}
