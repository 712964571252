import { storyboardClient } from 'features/http'

export const scenePaths = {
  regenerateFrame: (id: string) => `/stories/scenes/script-units/visualisable-units/${id}`,
  frame: (id: string) => `/stories/scenes/script-units/visualisable-units/frames/${id}`,
  frameGET: (id: string) => `/stories/scenes/script-units/visualisable-units/frames/${id}/`,
  frameUpdatePrompt: (id: string) => `/stories/scenes/script-units/visualisable-units/frames/update-prompt/${id}/`,
  addFrameToNewVUnit: (id: string) => `/stories/scenes/script-units/${id}`,
  visualUnit: (id: string) => `/stories/scenes/script-units/visualisable-units/${id}/`,
  setVUnitIsHidden: (id: string, is_hidden: boolean) =>
    `/stories/scenes/script-units/visualisable-units/set-hidden/${id}/${is_hidden}`,
  uploadFrame: (script_id: string, source_vunit_id: string) =>
    `/stories/scenes/script-units/visualisable-units/frames/upload/${script_id}/${source_vunit_id}`,
}

export const postSceneResource = async <T, K>(path: string, { arg }: { arg: T }): Promise<K> => {
  const { data } = await storyboardClient.post(path, arg)
  return data as K
}

export const putSceneResource = async <T, K>(path: string, { arg }: { arg: T }): Promise<K> => {
  const { data } = await storyboardClient.put(path, arg)
  return data as K
}
