import { usePostResource } from 'features/storyboard/hooks/usePostResources'
import { PrimitiveAtom, useAtom } from 'jotai'
import { InputTextarea } from 'primereact/inputtextarea'
import { ChangeEvent } from 'react'
import { MdOutlineContentCopy } from 'react-icons/md'
import { toast } from 'react-toastify'
import { onDataChange } from 'shared/utils/handlers'
import styled from 'styled-components'
import { scenePaths } from '../scene.api'
import { IRegenerateScene } from '../scene.types'

interface IPromptTab {
  isGenerating: boolean
  formAtom: PrimitiveAtom<IRegenerateScene>
  frameId?: string
}

const PromptTab = ({ isGenerating, formAtom, frameId }: IPromptTab) => {
  const [form, setForm] = useAtom(formAtom)
  const { prompt } = form

  const onChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
    if (!isGenerating) {
      onDataChange(setForm)(e)
    }
  }

  const { trigger: triggerFramePromptUpdate } = usePostResource<void, void>(
    `${scenePaths.frameUpdatePrompt(frameId || '')}?new_prompt=${prompt}`,
    {
      onSuccess() {
        toast.info('Successfully updated frame prompt.')
      },
    },
  )

  return (
    <Div>
      <h2>Describe what to capture in the shot.</h2>
      <InputTextarea
        name="prompt"
        className="w-full text-input"
        autoResize
        rows={5}
        value={prompt}
        placeholder="Add your description here."
        onChange={onChange}
      />
    </Div>
  )
}

const Div = styled.div`
  color: ${({ theme }) => theme.colors.secondary_100};
  h2 {
    font-weight: 600;
    font-size: 21px;
    line-height: 132%;
    margin: 2rem 0;
    margin-top: 1rem;
  }
  h3 {
    font-weight: 400;
    font-size: 18px;
    line-height: 150%;
    color: ${({ theme }) => theme.colors.secondary_100};
  }
  > div {
    > div {
      position: relative;
      width: 100%;
      background: linear-gradient(0deg, rgba(255, 255, 255, 0.4), rgba(255, 255, 255, 0.4)), #eeeeee;
      border-radius: 8px;
      padding: 1rem;
      border: 1px solid #ffffff;
    }
  }
`

const StyledMdOutlineContentCopy = styled(MdOutlineContentCopy)`
  position: absolute;
  right: 5px;
  bottom: 5px;
  font-size: 1.5rem;
  cursor: pointer;
  rows: 5;
`

export default PromptTab
