import { IStoryBoardScene, IStoryBoardScriptUnit } from './storyboard.types'

// TODO(julia): Remove this hack for CogX.
export const STOWAWAY_MODIFIERS = [
  'award winning photography, extremely photorealistic, analog style, 4k, 8k, highly detailed, sharp focus, hyper realistic',
]

const findFirstScriptUnitWithFrames = (scriptUnits?: IStoryBoardScriptUnit[]): IStoryBoardScriptUnit | null => {
  if (!scriptUnits) {
    return null
  }
  for (const scriptUnit of scriptUnits) {
    for (const vUnit of scriptUnit.visualizable_units) {
      if (vUnit?.frames?.length > 0) {
        return scriptUnit
      }
    }
  }
  return null
}

const findFirstImageUrl = (scenes: IStoryBoardScene[]): string | null => {
  for (const scene of scenes) {
    for (const scriptUnit of scene.script_units) {
      for (const vUnit of scriptUnit.visualizable_units) {
        if (vUnit.frames.length > 0 && vUnit.frames[0].link) {
          return vUnit.frames[0].link
        }
      }
    }
  }
  return null
}

export { findFirstImageUrl, findFirstScriptUnitWithFrames }
