import ImageTab from 'features/scene/components/ImageTab'
import { scenePaths } from 'features/scene/scene.api'
import { regenerateFormAtom } from 'features/scene/scene.state'
import { IFrameUploadResponse } from 'features/scene/scene.types'
import { usePostMultiPartResource } from 'features/storyboard/hooks/usePostResources'
import { storyboardEventIdsAtom } from 'features/storyboard/storyboard.state'
import {
  IStoryBoardScriptUnit,
  IStoryBoardScriptUnitFrame,
  IStoryBoardVisualizableUnit,
} from 'features/storyboard/storyboard.types'
import { useAtom, useSetAtom } from 'jotai'
import { Dialog } from 'primereact/dialog'
import { InputTextarea } from 'primereact/inputtextarea'
import { ReactNode } from 'react'
import { Button } from 'shared/components/Buttons/Button'
import { onDataChange } from 'shared/utils/handlers'
import styled from 'styled-components'

interface IUploadShotModalProps {
  children: ReactNode | ReactNode[]
  isVisible: boolean
  setIsVisible: any
  scriptUnit: IStoryBoardScriptUnit
  vUnit: IStoryBoardVisualizableUnit
  refetchScene: () => void
}

const UploadShotModal = (props: IUploadShotModalProps) => {
  const setStoryBoardActiveEvent = useSetAtom(storyboardEventIdsAtom)

  const [uploadForm, setUploadForm] = useAtom(regenerateFormAtom)
  const { prompt } = uploadForm

  const { trigger: triggerFrameUpload, isMutating: frameIsUploading } = usePostMultiPartResource<
    FormData,
    IFrameUploadResponse
  >(scenePaths.uploadFrame(props.scriptUnit.id || '', props.vUnit.id || ''), {
    onError(error) {
      console.log('Error uploading frame.', error)
    },
    onSuccess(data) {
      console.log(`Created frame with ID ${data.frame_id} under visual unit ${data.destination_vunit_id}`)
      // Will ensure it auto-scrolls to this frame when we go back to the storyboard.
      setStoryBoardActiveEvent((c) => [...c, data.frame_id])
      props.refetchScene()
      // Clean up the form.
      setUploadForm({
        prompt: '',
        camera_angle: '',
        lightning: '',
        image: undefined,
        style_overwrite: [],
        custom_style_overwrite: [],
      })
    },
  })

  const addLocalFrame = (localImageUrl: string, prompt: string) => {
    const localFrame: IStoryBoardScriptUnitFrame = {
      // Note that, because we don't have the real ID assigned by the BE here, actions like deletion that
      // are executed immediately after this frame is in view (but the scenes haven't mutated yet) won't work.
      // For full consistency, we'd have to disable the delete button in the meantime, or hold on to the deletion
      // request until we have the real ID from the BE.
      id: 'local-frame',
      link: localImageUrl,
      is_selected: true,
      failed_to_generate: false,
      prompt: prompt,
      style_overwrite: null,
      custom_style_overwrite: null,
      created_at: '',
    }

    if (props.vUnit.frames.length === 0 || (props.vUnit.frames.length === 1 && props.vUnit.frames[0].link === null)) {
      props.vUnit.frames = [localFrame]
    } else {
      const newVUnit: IStoryBoardVisualizableUnit = {
        id: 'local-vunit',
        action_substring: '',
        frames: [localFrame],
        is_hidden: false,
      }
      props.scriptUnit.visualizable_units.push(newVUnit)
    }
  }

  const onSubmit = async () => {
    const form = new FormData()
    uploadForm.image && form.append('image', uploadForm.image)
    const description = prompt || 'uploaded frame'
    form.append('description', description)
    triggerFrameUpload(form)
    if (uploadForm.image) {
      addLocalFrame(URL.createObjectURL(uploadForm.image), description)
    }
    props.setIsVisible(false)
  }

  const onCancel = () => {
    props.setIsVisible(false)
  }

  const footerTemplate = (
    <div className="flex p-3 justify-content-end w-full gap-3">
      <Button onClick={onCancel} variant="outline" size="large">
        {' '}
        Cancel{' '}
      </Button>
      <Button disabled={!uploadForm.image} isLoading={frameIsUploading} onClick={onSubmit} size="large">
        {' '}
        Add shot{' '}
      </Button>
    </div>
  )

  return (
    <div onClick={(e) => e.stopPropagation()}>
      <span>{props.children}</span>
      <StyledDialog
        className="w-11 md:w-7 "
        header={<h2>Upload a shot.</h2>}
        footer={footerTemplate}
        visible={props.isVisible}
        onHide={() => {
          props.setIsVisible(false)
        }}
        resizable={false}
      >
        <div className="flex flex-column align-items-center justify-content-center gap-3">
          <ImageTab header="" formData={uploadForm} setFormData={setUploadForm} />

          <InputTextarea
            name="prompt"
            className="w-full text-input"
            autoResize
            rows={3}
            value={prompt}
            placeholder="Optionally, describe your shot here."
            onChange={(e) => {
              onDataChange(setUploadForm)(e)
            }}
            style={{ maxWidth: '600px' }}
          />
        </div>
      </StyledDialog>
    </div>
  )
}

const StyledDialog = styled(Dialog)`
  h2 {
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 150%;
    color: ${({ theme }) => theme.colors.secondary_100};
    text-align: center;
  }
  h3 {
    font-weight: 400;
    font-size: 16px;
    line-height: 150%;
    color: ${({ theme }) => theme.colors.secondary_100};
    text-align: center;
  }

  p {
    padding: 0.5rem 0;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 150%;
    color: ${({ theme }) => theme.colors.gray_60};
    text-align: center;
  }
  .option {
    color: ${({ theme }) => theme.colors.gray_60};
    border: 1px solid ${({ theme }) => theme.colors.gray_40};
    border-radius: 8px;
    padding: 5px 10px;
    span {
      color: ${({ theme }) => theme.colors.secondary_100};
    }
  }
  button {
    /* Prevents this weird yellow circle around the "x" in the style modal */
    &:focus {
      box-shadow: none !important;
    }
  }
`

export default UploadShotModal
