import useSWRMutation, { SWRMutationConfiguration } from 'swr/mutation'
import { postMultipartStoryResource, postStoryResource } from '../storyboard.api'

/**
 * @parameter key is the api path of the resource
 * Usage example
 *  const { trigger, isMutating } = usePostMultiPartResource<ICreateStoryBoard, IStoryBoard>(storyboardPaths.storyboard('x'))
 */
export const usePostMultiPartResource = <T, K>(
  key: string,
  config: SWRMutationConfiguration<K, any, T, string, any> | undefined = {},
) => {
  const { trigger, isMutating } = useSWRMutation(key, postMultipartStoryResource<T, K>, { ...config })

  return { trigger, isMutating }
}

/**
 * @parameter key is the api path of the resource
 * Usage example
 *  const { trigger, isMutating } = usePostMultiPartResource<ICreateStoryBoard, IStoryBoard>(storyboardPaths.storyboard('x'))
 */
export const usePostResource = <T, K>(
  key: string,
  config: SWRMutationConfiguration<K, any, T, string, any> | undefined = {},
) => {
  const { trigger, isMutating } = useSWRMutation(key, postStoryResource<T, K>, { ...config })

  return { trigger, isMutating }
}
