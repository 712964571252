import cx from 'classnames'
import { format } from 'date-fns'
import { storyboardStatusEnum } from 'features/storyboard/storyboard.enums'
import { IStoryBoardSlim } from 'features/storyboard/storyboard.types'
import { DetailedHTMLProps, HTMLAttributes } from 'react'
import { NoContentBox } from 'shared/components/ApiStates/EmptyResponse/NoContentBox'
import { LoadingBox } from 'shared/components/ApiStates/Loading/LoadingBox'
import { Button } from 'shared/components/Buttons/Button'
import styled from 'styled-components'
import bgSrc from '../../assets/eyes_snowflake_overlay.png'
import { DeleteStoryboardModal } from './DeleteStoryboardModal'

interface IStoryCard extends Omit<DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement>, 'ref'> {
  story: IStoryBoardSlim
  deletable?: boolean
}
const StoryCard = ({ story, deletable, ...props }: IStoryCard) => {
  const { image } = story
  const hasStyle = story.custom_styles.length > 0 || story.styles.length > 0
  const timeAgo = format(new Date(story.created_at), 'MMMM dd, yyyy, HH:mm')

  return (
    <Div {...props} className="shadow-1">
      <div className={cx('image relative', { 'full-story': image.length === 4 })}>
        {[storyboardStatusEnum.progress, storyboardStatusEnum.pending].includes(story.status) && <LoadingBox />}
        {story.status === storyboardStatusEnum.failed && (
          <NoContentBox image={<i className="pi pi-exclamation-circle text-6xl error"></i>} />
        )}

        {image.length === 4 ? (
          image.map((imageSrc, i) => <img key={i} src={imageSrc} alt="Scene frame" />)
        ) : (
          <img className="border-round" alt="Card" src={image[0] || bgSrc} />
        )}
      </div>

      <div className="pt-3">
        <div>
          <h3 className="text-md font-semibold">{story.title}</h3>
          <p className="text-500 mt-1">
            {story.author}
            {story.year ? `, ${story.year}` : ``}
          </p>
          {deletable ? <p className="text-400 mt-1">{!story.year && timeAgo}</p> : <></>}
          <div className="mt-3 flex flex-wrap align-items-center gap-2">
            {hasStyle}
            {story.custom_styles.map((style, index) => (
              <span key={index} className="border-1 border-300 px-2 py-1 border-round-xl text-500">
                {style}
              </span>
            ))}
            {story.styles.map((style) => (
              <span key={style.id} className="border-1 border-300 px-2 py-1 border-round-xl text-500">
                {style.title}
              </span>
            ))}
          </div>
        </div>
      </div>

      {/* TODO(julia): Dismiss the "processing" notification if the storyboard is deleted while being generated. */}
      {deletable && (
        <DeleteStoryboardModal id={story.id}>
          <Button size="large" className="remove-button">
            <i className="pi pi-trash"></i>
          </Button>
        </DeleteStoryboardModal>
      )}
    </Div>
  )
}

const Div = styled.div`
  background: #ffffff;
  border: 1.01133px solid rgba(35, 47, 62, 0.1);
  border-radius: 4.04534px;
  padding: 12px;
  display: flex;
  flex-direction: column;
  height: 100%;

  > .image {
    width: 242.72px;
    height: 158.78px;

    &.full-story {
      display: grid;
      grid-template-columns: repeat(2, calc(242.72px / 2));
      grid-template-rows: repeat(2, calc(158.78px / 2));

      img {
        flex-basis: 122px;
        margin-bottom: 0;
        max-width: 100%;
        max-height: 100%;
        object-fit: cover;
      }
    }

    img {
      width: 100%;
      max-width: 100%;
      height: 100%;
      object-position: center;
      object-fit: cover;
    }
  }

  .remove-button {
    display: none;
    position: absolute;
    inset: 1rem 1rem auto auto;
    z-index: 2;
  }

  &:hover {
    .remove-button {
      display: block;
    }
  }
`

export default StoryCard
