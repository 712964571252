import { recordChangePageEvent } from 'features/eventTracking/eventTracking.api'
import { changePageInteractionEventsEnum } from 'features/eventTracking/eventTracking.enums'
import { pagesRoutesEnum } from 'pages/Router/router.enums'
import { useNavigate } from 'react-router-dom'
import { Button } from 'shared/components/Buttons/Button'
import styled from 'styled-components'
import bgSrc from '../../assets/eyes_snowflake_overlay.png'

export const NoStoriesWidget = () => {
  const navigate = useNavigate()
  return (
    <Div className="flex align-items-center justify-content-center flex-column border-round-md text-white text-center gap-4 shadow-4">
      <div className="line-height-3">
        <h2 className="text-3xl">Your masterpiece would look great on the big screen</h2>
        <p className="text-xl">Make it happen</p>
      </div>

      <Button
        size="large"
        onClick={() => {
          recordChangePageEvent({
            page: window.location.pathname,
            to: pagesRoutesEnum.create,
            interactionElement: changePageInteractionEventsEnum.createStoryHomePageNoStoriesCallToActionButton,
          })
          navigate(pagesRoutesEnum.create)
        }}
      >
        Create Storyboard
      </Button>
    </Div>
  )
}

const Div = styled.div`
  width: 100%;
  height: max(314px, 35vh);
  background-image: url(${bgSrc});
  background-size: cover;
  background-position: center;
  object-fit: cover;
`
