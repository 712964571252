import axios, { AxiosError, InternalAxiosRequestConfig } from 'axios'
import { getAuth } from 'firebase/auth'

const defaultHeaders = { 'Content-type': 'application/json' }

export const authMiddleware = async (config: InternalAxiosRequestConfig) => {
  const auth = getAuth()
  const accessToken = await auth.currentUser?.getIdToken()

  if (config?.headers && accessToken) {
    config.headers.Authorization = `Bearer ${accessToken}`
  }

  return config
}

export const responseMiddleware = <T>(res: T) => res
export const responseErrorMiddleware = async (err: AxiosError) => {
  const status = err.response?.status || 500

  if (status === 401) {
    const auth = getAuth()
    await auth.signOut()
    location.replace('/login')
  }
  return Promise.reject(err)
}

export const storyboardClient = axios.create({
  baseURL: process.env.REACT_APP_STORYBOARD_URL,
  headers: {
    ...defaultHeaders,
  },
})

storyboardClient.interceptors.request.use(authMiddleware)
storyboardClient.interceptors.response.use(undefined, responseErrorMiddleware)
