import { storyboardStatusEnum } from 'features/storyboard/storyboard.enums'
import { activeSceneAtom, storyboardImageSizeAtom, storyboardModeAtom } from 'features/storyboard/storyboard.state'
import { IStoryBoard, IStoryBoardScene } from 'features/storyboard/storyboard.types'
import { findFirstImageUrl } from 'features/storyboard/storyboard.utils'
import { useAtom, useAtomValue, useSetAtom } from 'jotai'
import EditScenePage from 'pages/EditScenePage'
import GenerateScenePage from 'pages/GenerateScenePage'
import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { NoContentBox } from 'shared/components/ApiStates/EmptyResponse/NoContentBox'
import { Button } from 'shared/components/Buttons/Button'
import { moveToElement, scrollToElement } from 'shared/utils/dom.utils'
import styled from 'styled-components'
import { StoryboardTopBar } from './StoryboardTopBar'
import { StoryboardTranscript } from './StoryboardTranscript'
import { StoryboardViewAside } from './StoryboardViewAside'

interface IProps {
  storyboard: IStoryBoard
  scenes: IStoryBoardScene[]
  visualScenes: IStoryBoardScene[]
  refetchScene: (idx: number) => void
}

export const StoryboardView = (props: IProps) => {
  const mode = useAtomValue(storyboardModeAtom)
  const setStoryboardImageSize = useSetAtom(storyboardImageSizeAtom)
  const [activeScene, setActiveScene] = useAtom(activeSceneAtom)
  const navigate = useNavigate()

  useEffect(() => {
    const img = new Image()
    img.src = findFirstImageUrl(props.scenes) || ''
    img.onload = () => {
      const { naturalWidth, naturalHeight } = img
      setStoryboardImageSize({ width: naturalWidth, height: naturalHeight })
    }
  }, [props.visualScenes])

  useEffect(() => {
    if (activeScene.changedBy !== null && activeScene.changedBy !== 'sidebar') {
      moveToElement(`thumbnail-${activeScene.sceneIdx}`, 'stories-aside')
    }
    if (activeScene.changedBy != null && activeScene.changedBy !== 'scrollspy') {
      scrollToElement(`frame-${activeScene.sceneIdx}`, 'storyboard-transcript')
    }
  }, [activeScene.sceneIdx, props.visualScenes])

  useEffect(() => {
    const handleKeyDown = (e: KeyboardEvent) => {
      if (['ArrowDown', 'ArrowUp'].includes(e.code)) e.preventDefault()
      if (e.code === 'ArrowDown') {
        if (props.visualScenes?.[activeScene.sceneIdx + 1]) {
          setActiveScene({
            sceneIdx: activeScene.sceneIdx + 1,
            changedBy: 'arrows',
          })
        } else if (props.visualScenes?.[0]) {
          setActiveScene({
            sceneIdx: 0,
            changedBy: 'arrows',
          })
        }
      } else if (e.code === 'ArrowUp') {
        if (props.visualScenes?.[activeScene.sceneIdx - 1]) {
          setActiveScene({
            sceneIdx: activeScene.sceneIdx - 1,
            changedBy: 'arrows',
          })
        } else if (props.visualScenes?.[props.visualScenes.length - 1]) {
          setActiveScene({
            sceneIdx: props.visualScenes.length - 1,
            changedBy: 'arrows',
          })
        }
      }
    }

    if (props.scenes.length) {
      document.addEventListener('keydown', handleKeyDown)
    }

    return () => {
      document.removeEventListener('keydown', handleKeyDown)
    }
  }, [activeScene.sceneIdx, props.visualScenes])

  if (props.storyboard?.status === storyboardStatusEnum.failed)
    return (
      <NoContentBox
        message="You script failed to parse, please report to the administrator."
        image={<i className="pi pi-exclamation-circle text-6xl error"></i>}
        actions={<Button onClick={() => navigate(-1)}>Back</Button>}
      />
    )

  return (
    <Div>
      {props.storyboard && (
        <StoryboardViewAside storyboard={props.storyboard} scenes={props.scenes} visualScenes={props.visualScenes} />
      )}

      <main className="flex flex-column">
        {props.storyboard && (
          <>
            <StoryboardTopBar storyboard={props.storyboard} />
            {mode == 'view' && (
              <StoryboardTranscript
                storyboard={props.storyboard}
                visualScenes={props.visualScenes}
                refetchScene={props.refetchScene}
              />
            )}
            {mode == 'edit' && <EditScenePage />}
            {mode == 'add_shot' && <GenerateScenePage />}
          </>
        )}
      </main>
    </Div>
  )
}

const Div = styled.div`
  max-height: 100vh;
  height: 100%;
  display: grid;
  grid-template-columns: 200px 1fr;
  overflow: hidden;

  > main {
    position: relative;
    height: 100%;

    > footer {
      position: absolute;
      inset: auto 0 0;

      > div > button {
        width: 160px;
      }
    }
  }

  @media only screen and (max-width: 770px) {
    grid-template-columns: 1fr;
  }
`
