export enum subscriptionsPlansEnum {
  free = 0,
  regular = 1,
  enterprise = 2,
}

export enum storyboardStatusEnum {
  pending = 'pending',
  failed = 'failed',
  progress = 'progress',
  completed = 'completed',
}
