import * as styled from 'styled-components'

export const GlobalStyles = styled.createGlobalStyle`
  html,
  * {
    scroll-behavior: smooth;
  }

  html,
  body {
    padding: 0;
    margin: 0 auto;
    font-family: 'Open Sans';
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-style: normal;
    font-size: 16px;
    color: ${({ theme }) => theme.colors.black};
    height: 100%;
  }

  #root {
    height: 100%;
  }

  * {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
  }

  a {
    color: inherit;
    text-decoration: none;
  }

  button {
    border: none;
    outline: none;
  }

  img {
    object-fit: cover;
    max-width: 100%;
    width: 100%;
  }

  ::-webkit-scrollbar {
    width: 7px;
    height: 3px;
    background: #ffffff;
    border: 1px solid #e9eaec;
    border-radius: 4px;
  }

  ::-webkit-scrollbar-thumb {
    width: 3px;
    background: #ececee;
    border-radius: 4px;
  }

  ::-webkit-scrollbar-thumb:hover {
    background: ${({ theme }) => theme.colors.secondary_100};
  }

  /* Hide scrollbar for Chrome, Safari and Opera */
  .scrollbar-hidden::-webkit-scrollbar {
    display: none;
  }

  /* Hide scrollbar for IE, Edge and Firefox */
  .scrollbar-hidden {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }

  .p-dialog .p-dialog-header-icons {
    top: 0.75rem;
    right: 0.75rem;
  }

  .CookieDeclaration {
    padding: 2rem;
  }

  .active-scroll-spy {
    background-color: ${({ theme }) => theme.colors.primary_100};
    border-radius: 15px;
  }
`
