import { Character } from 'features/storyboard/storyboard.types'
import { Dispatch, SetStateAction, useEffect, useRef } from 'react'
import Slider from 'react-slick'
import { Button } from 'shared/components/Buttons/Button'
import CharacterCard from './CharacterCard'

import 'slick-carousel/slick/slick-theme.css'
import 'slick-carousel/slick/slick.css'

interface CharacterStepProps {
  characters: Character[]
  setCharacters: Dispatch<SetStateAction<Character[]>>
}

function CharacterStep({ characters, setCharacters }: CharacterStepProps) {
  const carouselRef = useRef<Slider>(null)

  const addCharacter = () => {
    const newCharacter: Character = { name: '', bio: '', gender: '', age: '', race: '', description: '', manual: true }
    setCharacters([...characters, newCharacter])
  }

  const deleteCharacter = (idx: number) => {
    setCharacters([...characters.slice(0, idx), ...characters.slice(idx + 1)])
  }

  const updateCharacter = (idx: number, character: Character) => {
    characters[idx] = character // This doesn't trigger a rerender, but that's fine.
  }

  useEffect(() => {
    // Scroll to last character, if it was just added.
    if (characters.length > 0 && characters[characters.length - 1].manual) {
      carouselRef.current?.slickGoTo(characters.length - 1)
    }
  }, [characters])

  return (
    <div className="flex flex-column gap-4">
      <div className="flex flex-column gap-1 max-width align-items-center">
        <h2>Describe your characters (optional).</h2>
        <p>This will guide the visual appearance of your characters in the storyboard.</p>
      </div>
      {/* For some reason, the arrows in the Slider component don't show up... */}
      <Slider
        ref={carouselRef}
        arrows={true}
        dots={true}
        infinite={false}
        speed={500}
        slidesToShow={3}
        slidesToScroll={1}
      >
        {characters.map((c, idx) => (
          <CharacterCard
            key={idx}
            character={c}
            updateCharacter={(c: Character) => updateCharacter(idx, c)}
            deleteCharacter={() => deleteCharacter(idx)}
          />
        ))}
      </Slider>
      <div className="flex justify-content-center mt-4">
        <Button variant="outline" onClick={addCharacter}>
          Add character
        </Button>
      </div>
    </div>
  )
}

export default CharacterStep
