import { IStoryBoardScene, IStoryBoardScriptUnit } from 'features/storyboard/storyboard.types'
import styled from 'styled-components'

interface IProps {
  scene: IStoryBoardScene
}
export const StoryboardSceneAction = ({ scene }: IProps) => {
  return (
    <Div className="scene-action md:scene-action-md md:overflow-y-auto col-12 md:col-6">
      <div className="py-2">
        {/* Setting the font bold deviates from the screenplay industry standard, but makes it look better. */}
        {(scene.region || scene.location || scene.time) && (
          <div className="pb-3">
            <span style={{ fontWeight: 'bold' }}>
              {scene.region} {!!scene.region && !!scene.location}
              {scene.location}
              {scene.time == '' || scene.time == null ? '' : ' -- ' + scene.time}
            </span>
          </div>
        )}

        {scene.script_units.map((script: IStoryBoardScriptUnit, i) => (
          <div className="line-height-2" key={i}>
            <div className="pb-3">
              {/* TODO(julia): Why doesn't the global style of the Div apply here? */}
              <p style={{ fontSize: '14px', color: 'black' }}>{script.action}</p>
            </div>

            <div>
              {script.turns.map((turn, i) => {
                return (
                  <div key={i} style={{ width: '66%', margin: 'auto' }} className="pb-3">
                    <div style={{ textAlign: 'center' }}>
                      <b>{turn.character}</b>
                    </div>
                    <div>{turn.line}</div>
                  </div>
                )
              })}
            </div>
          </div>
        ))}
      </div>
    </Div>
  )
}

const Div = styled.div`
  font-family: CourierPrime;
  /* The industry standard for screenplays is Courier 12, but 12 looks too small. */
  font-size: 14px;
  color: black;

  &.scene-action-md {
    max-height: 40vh;
    /* max-width: 800px; */
  }
`
