import { storyFormAtom } from 'features/storyboard/storyboard.state'
import { useAtom } from 'jotai'
import { FileUploadSelectEvent } from 'primereact/fileupload'
import { useEffect, useRef } from 'react'
import { toast } from 'react-toastify'
import FileUploader from 'shared/components/DocumentUploader/FileUploader'

const DocumentUploader = () => {
  // states
  const [storyForm, setStoryForm] = useAtom(storyFormAtom)

  //utils
  const maxFileSize = 1000000 * 100
  const uploaderRef = useRef<any>(null)

  //handlers
  const onTemplateSelect = (e: FileUploadSelectEvent) => {
    const files = e.files
    if (files[0].size < maxFileSize) {
      setStoryForm((prev) => {
        return { ...prev, file: files[0] }
      })
    }
  }

  const onTemplateRemove = (file: any, callback: any) => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { file: _, ...rest } = storyForm

    setStoryForm(rest)
    callback()
  }

  const onValidationFail = (file: File) => {
    toast.error(`${file.name} too big. Maximum upload size is 100MB`)
  }

  useEffect(() => {
    if (storyForm?.file) {
      uploaderRef?.current.setFiles([storyForm?.file])
    }
  }, [])

  return (
    <FileUploader
      preamble=<span>
        Drag and drop your{' '}
        <u>
          <a href="https://finaldraft.com" target="_blank">
            Final Draft
          </a>
        </u>{' '}
        PDF here, or{' '}
      </span>
      forwardedRef={uploaderRef}
      onTemplateRemove={onTemplateRemove}
      onSelect={onTemplateSelect}
      onValidationFail={onValidationFail}
      maxFileSize={maxFileSize}
      accept="application/pdf"
    />
  )
}

export default DocumentUploader
