import { Character } from 'features/storyboard/storyboard.types'
import { Card } from 'primereact/card'
import { InputText } from 'primereact/inputtext'
import { InputTextarea } from 'primereact/inputtextarea'
import { useEffect, useState } from 'react'
import { Button } from 'shared/components/Buttons/Button'
import { onDataChange } from 'shared/utils/handlers'
import styled from 'styled-components'

interface CharacterCardProps {
  character: Character
  updateCharacter: (c: Character) => void
  deleteCharacter: () => void
}

function CharacterCard({ character, updateCharacter, deleteCharacter }: CharacterCardProps) {
  const [localCharacter, setLocalCharacter] = useState<Character>(character)
  const { name, bio, gender, age, race, description } = localCharacter

  useEffect(() => {
    updateCharacter(localCharacter)
  }, [localCharacter])

  const cardHeader = (
    <div className="flex flex-column align-items-center gap-1">
      <p>&nbsp;</p> {/* Hack to get some top margin. */}
      {!character.manual ? (
        <div
          style={{
            maxWidth: '90%',
            overflow: 'hidden',
            position: 'relative',
            display: 'inline-block',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
          }}
        >
          <h2>{name}</h2>
        </div>
      ) : (
        <InputText
          placeholder="Character Name"
          value={name}
          name="name"
          onChange={onDataChange(setLocalCharacter)}
          style={{ textAlign: 'center' }}
        />
      )}
      <p>{bio} &nbsp;</p>
    </div>
  )

  return (
    <Div>
      <Card style={{ margin: '10px', position: 'relative', backgroundColor: '#fff3e0' }} header={cardHeader}>
        <div className="flex flex-column gap-2">
          <InputText placeholder="Gender" value={gender} name="gender" onChange={onDataChange(setLocalCharacter)} />
          <InputText placeholder="Age" value={age} name="age" onChange={onDataChange(setLocalCharacter)} />
          <InputText placeholder="Race" value={race} name="race" onChange={onDataChange(setLocalCharacter)} />
          <InputTextarea
            placeholder="Description"
            value={description}
            name="description"
            rows={5}
            onChange={onDataChange(setLocalCharacter)}
          />
        </div>
        {character.manual && (
          <Button size="large" className="remove-button" onClick={deleteCharacter}>
            <i className="pi pi-trash"></i>
          </Button>
        )}
      </Card>
    </Div>
  )
}

export default CharacterCard

const Div = styled.div`
  .remove-button {
    display: none;
    position: absolute;
    inset: 0 0 auto auto;
    z-index: 2;
    margin: 15px;
  }

  &:hover {
    .remove-button {
      display: block;
    }
  }
`
