import { IStoryBoardScene, IStoryBoardVisualizableUnit } from 'features/storyboard/storyboard.types'
import { saveAs } from 'file-saver'
import { TextOptionsLight, jsPDF } from 'jspdf'
import JSZip from 'jszip'
import { useState } from 'react'
import { toast } from 'react-toastify'
import { Button } from 'shared/components/Buttons/Button'

// See https://stackoverflow.com/questions/17274655/how-to-download-zip-and-save-multiple-files-with-javascript-and-get-progress
export function downloadFile(url: string, onSuccess: any) {
  const xhr = new XMLHttpRequest()
  //xhr.onprogress = calculateAndUpdateProgress;
  xhr.open('GET', url, true)
  xhr.setRequestHeader('Cache-Control', 'no-cache, no-store, max-age=0')
  xhr.responseType = 'blob'
  xhr.onreadystatechange = function () {
    if (xhr.readyState == 4 && xhr.status == 200) {
      onSuccess(xhr.response)
    }
  }
  xhr.send()
}

function blobToBase64(blob: Blob, callback: any) {
  const reader = new FileReader()
  reader.onload = function () {
    const dataUrl = reader.result as string
    const base64 = dataUrl.split(',')[1]
    callback(base64)
  }
  reader.readAsDataURL(blob)
}

interface StoryboardDownloadProps {
  scenes: IStoryBoardScene[]
}

type ImageToDownload = {
  url: string
  sceneIdx: number
  frameIdx: number // index within scene
}

export function StoryboardDownload(props: StoryboardDownloadProps) {
  const [isDownloading, setIsDownloading] = useState(false)

  const onDownload = async () => {
    setIsDownloading(true)

    const getSelectedFrame = (vUnit: IStoryBoardVisualizableUnit) => {
      // Normally we should just choose the frame that has frame.is_selected = true.
      // But there was a BE bug a while back, which might make multiple frames selected.
      // In that case, we select the last frame.
      const selectedFrames = vUnit.frames.filter((frame) => frame.link && frame.is_selected)
      if (selectedFrames.length > 1) {
        return selectedFrames[selectedFrames.length - 1]
      }
      return selectedFrames[0]
    }

    const imagesToDownload: ImageToDownload[] = []
    for (const [sceneIdx, scene] of props.scenes.entries()) {
      let frameIdx = 0
      for (const scriptUnit of scene.script_units) {
        for (const vUnit of scriptUnit.visualizable_units) {
          if (!vUnit.is_hidden) {
            const frame = getSelectedFrame(vUnit)
            if (frame && frame.link) {
              imagesToDownload.push({
                url: frame.link,
                sceneIdx: sceneIdx,
                frameIdx: frameIdx,
              })
            }
            frameIdx += 1
          }
        }
      }
    }

    const zip = new JSZip()
    let count = 0

    const pdfDoc = new jsPDF({ unit: 'px', orientation: 'portrait' })
    pdfDoc.setFontSize(12)
    pdfDoc.setFont('courier', 'normal')

    const textHeight = 10
    const pdfWidthMargin = 20
    const pdfHeightMargin = 20
    let y = pdfHeightMargin

    // Do not modify y outside of these images, because it would get very error prone.
    const addImage = (binaryData: string, paddingAfter: number) => {
      const imgProperties = pdfDoc.getImageProperties(binaryData)
      const pdfImgHeight = 150
      const pdfImgWidth = (imgProperties.width * pdfImgHeight) / imgProperties.height

      if (y + pdfImgHeight > pdfDoc.internal.pageSize.height - pdfHeightMargin) {
        pdfDoc.addPage()
        y = pdfHeightMargin
      }
      pdfDoc.addImage(binaryData, 'PNG', pdfWidthMargin, y, pdfImgWidth, pdfImgHeight)
      y += pdfImgHeight + paddingAfter
    }
    const addTextLines = (
      textLines: string[],
      marginWidth: number,
      paddingAfter: number,
      options: TextOptionsLight = {},
    ) => {
      for (const textLine of textLines) {
        if (y + textHeight > pdfDoc.internal.pageSize.height - pdfHeightMargin) {
          pdfDoc.addPage()
          y = pdfHeightMargin
        }
        pdfDoc.text(textLine, marginWidth, y, options)
        y += textHeight
      }
      y += paddingAfter
    }

    function onDownloadComplete(blobData: Blob) {
      if (count < imagesToDownload.length) {
        blobToBase64(blobData, function (binaryData: any) {
          const fileName =
            'frames/scene_' +
            (imagesToDownload[count].sceneIdx + 1) +
            '_frame_' +
            (imagesToDownload[count].frameIdx + 1) +
            '.png'

          zip.file(fileName, binaryData, { base64: true })
          addImage(binaryData, 10)

          // If the next frame is from the first scene, don't yet add the text.
          const shouldAddScene =
            count == imagesToDownload.length - 1 ||
            imagesToDownload[count].sceneIdx != imagesToDownload[count + 1].sceneIdx
          if (shouldAddScene) {
            const textWidth = pdfDoc.internal.pageSize.width - 2 * pdfWidthMargin
            const dialogWidth = (textWidth * 2) / 3
            const dialogMargin = pdfWidthMargin + (textWidth - dialogWidth) / 2

            const scene = props.scenes[imagesToDownload[count].sceneIdx]

            if (scene.region && scene.location && scene.time) {
              const markers = scene.region + ' ' + scene.location + ' -- ' + scene.time
              addTextLines([markers], pdfWidthMargin, 5)
            }

            for (const scriptUnit of scene.script_units) {
              const actionLines = pdfDoc.splitTextToSize(scriptUnit.action, textWidth + 10)
              addTextLines(actionLines, pdfWidthMargin, 10)

              for (const turn of scriptUnit.turns) {
                // It would be nice to make the character names bold, but that's not supported by jsPDF.
                addTextLines([turn.character], pdfDoc.internal.pageSize.width / 2, 0, { align: 'center' })

                const turnLines = pdfDoc.splitTextToSize(turn.line, dialogWidth)
                addTextLines(turnLines, dialogMargin, 10)
              }
            }
          }

          if (count < imagesToDownload.length - 1) {
            count++
            downloadFile(imagesToDownload[count].url, onDownloadComplete)
          } else {
            zip.file('storyboard.pdf', pdfDoc.output('blob'))
            zip.generateAsync({ type: 'blob' }).then(function (content) {
              saveAs(content, 'storyboard.zip')
            })
            setIsDownloading(false)
          }
        })
      }
    }

    if (imagesToDownload.length > 0) {
      downloadFile(imagesToDownload[count].url, onDownloadComplete)
    } else {
      toast.error('There are no shots to download.')
      setIsDownloading(false)
    }
  }

  return (
    <Button isLoading={isDownloading} onClick={onDownload}>
      Download
    </Button>
  )
}
