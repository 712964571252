import { useGetResources } from 'features/storyboard/hooks/useGetResources'
import { usePostResource } from 'features/storyboard/hooks/usePostResources'
import { storyboardPaths } from 'features/storyboard/storyboard.api'
import { storyboardEventIdsAtom } from 'features/storyboard/storyboard.state'
import { ICreateStoryBoardDemo, IRegenerateStoryBoard, IStoryBoard } from 'features/storyboard/storyboard.types'
import { useSetAtom } from 'jotai'
import { pagesRoutesEnum } from 'pages/Router/router.enums'
import { Dialog } from 'primereact/dialog'
import { ReactNode, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import { Button } from 'shared/components/Buttons/Button'
import styled from 'styled-components'
import StyleStep from '../StorybordCreator/StyleStep'

interface IChangeStyleModal {
  children: ReactNode | ReactNode[]
  id: string
}

const ChangeStyleModal = ({ children, id }: IChangeStyleModal) => {
  const [visible, setVisible] = useState<boolean>(false)
  const setStoryBoardActiveEvents = useSetAtom(storyboardEventIdsAtom)

  const navigate = useNavigate()

  const { data: storyboard } = useGetResources<IStoryBoard>(storyboardPaths.storyboardMetadata(id), {
    onError(error) {
      console.log('error', error)
    },
  })
  const [storyForm, setStoryForm] = useState<ICreateStoryBoardDemo>({
    styles: storyboard?.styles?.map((style) => style.id) || [],
    customStyles: storyboard?.custom_styles || [],
    model: null,
    amount_of_scenes: 5,
  })
  const { trigger: triggerRegenerateStoryboard } = usePostResource<IRegenerateStoryBoard, { id: string }>(
    storyboardPaths.regenerateStoryBoard(id),
    {
      onError() {
        toast.error('Cannot Regenerate Storyboard')
      },
      onSuccess({ id }) {
        // This will then get handled by useStoryboardsEvents() in App.tsx
        setStoryBoardActiveEvents((c) => [...c, id])
        navigate(pagesRoutesEnum.projects)
      },
    },
  )

  const onCancel = () => {
    setVisible(false)
    setStoryForm({
      styles: storyboard?.styles?.map((style) => style.id) || [],
      customStyles: storyboard?.custom_styles || [],
      model: null,
      amount_of_scenes: 5,
    })
  }

  const onSubmit = async () => {
    const body: IRegenerateStoryBoard = {
      styles: storyForm.styles,
      custom_styles: storyForm.customStyles,
    }
    await triggerRegenerateStoryboard(body)
  }

  const onOpen = () => {
    setVisible(true)
  }

  const hasStyles = storyForm.styles.length > 0 || storyForm.customStyles.length > 0
  const footerTemplate = (
    <div className="w-full flex justify-content-end pt-3 pb-0">
      <Button disabled={!hasStyles} onClick={onSubmit}>
        Change Style
      </Button>
    </div>
  )
  return (
    <div onClick={(e) => e.stopPropagation()}>
      <span onClick={onOpen}>{children}</span>
      <StyledDialog
        className="w-11 md:w-7 "
        header={<h2>Change Storyboard Style</h2>}
        footer={footerTemplate}
        visible={visible}
        onHide={onCancel}
        resizable={false}
      >
        <StyleStep storyForm={storyForm} setStoryForm={setStoryForm} />
      </StyledDialog>
    </div>
  )
}

const StyledDialog = styled(Dialog)`
  h2 {
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 150%;
    color: ${({ theme }) => theme.colors.secondary_100};
    text-align: center;
  }
  h3 {
    font-weight: 400;
    font-size: 16px;
    line-height: 150%;
    color: ${({ theme }) => theme.colors.secondary_100};
    text-align: center;
  }

  p {
    padding: 0.5rem 0;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 150%;
    color: ${({ theme }) => theme.colors.gray_60};
    text-align: center;
  }
  .option {
    color: ${({ theme }) => theme.colors.gray_60};
    border: 1px solid ${({ theme }) => theme.colors.gray_40};
    border-radius: 8px;
    padding: 5px 10px;
    span {
      color: ${({ theme }) => theme.colors.secondary_100};
    }
  }
  button {
    /* Prevents this weird yellow circle around the "x" in the style modal */
    &:focus {
      box-shadow: none !important;
    }
  }
`

export default ChangeStyleModal
