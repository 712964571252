import { Location } from 'features/storyboard/storyboard.types'
import { Card } from 'primereact/card'
import { InputText } from 'primereact/inputtext'
import { InputTextarea } from 'primereact/inputtextarea'
import { useEffect, useState } from 'react'
import { Button } from 'shared/components/Buttons/Button'
import { onDataChange } from 'shared/utils/handlers'
import styled from 'styled-components'

interface LocationCardProps {
  location: Location
  updateLocation: (c: Location) => void
  deleteLocation: () => void
}

export function LocationCard({ location, updateLocation, deleteLocation }: LocationCardProps) {
  const [localLocation, setLocalLocation] = useState<Location>(location)

  useEffect(() => {
    updateLocation(localLocation)
  }, [localLocation])

  const cardHeader = (
    <div className="flex flex-column align-items-center gap-1">
      <p>&nbsp;</p> {/* Hack to get some top margin. */}
      {!localLocation.manual ? (
        <div
          style={{
            maxWidth: '90%',
            overflow: 'hidden',
            position: 'relative',
            display: 'inline-block',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
          }}
        >
          <h2>{localLocation.name}</h2>
        </div>
      ) : (
        <InputText
          placeholder="Location Name"
          value={localLocation.name}
          name="name"
          onChange={onDataChange(setLocalLocation)}
          style={{ textAlign: 'center' }}
        />
      )}
      <p>{localLocation.bio} &nbsp;</p>
    </div>
  )

  return (
    <Div>
      <Card style={{ margin: '10px', position: 'relative', backgroundColor: '#fff3e0' }} header={cardHeader}>
        <div className="flex flex-column gap-2">
          <InputTextarea
            placeholder="Description"
            value={localLocation.description}
            name="description"
            rows={10}
            onChange={onDataChange(setLocalLocation)}
          />
        </div>
        {localLocation.manual && (
          <Button size="large" className="remove-button" onClick={deleteLocation}>
            <i className="pi pi-trash"></i>
          </Button>
        )}
      </Card>
    </Div>
  )
}

export default LocationCard

const Div = styled.div`
  .remove-button {
    display: none;
    position: absolute;
    inset: 0 0 auto auto;
    z-index: 2;
    margin: 15px;
  }

  &:hover {
    .remove-button {
      display: block;
    }
  }
`
