import 'primeflex/primeflex.css'
import 'primeicons/primeicons.css'
import 'primereact/resources/primereact.min.css'
import 'primereact/resources/themes/saga-orange/theme.css'
import React from 'react'
import styled from 'styled-components'
import { Nav } from './Nav'

interface IProps {
  children: React.ReactNode | React.ReactNode[]
}

export const Layout = ({ children }: IProps) => {
  return (
    <Div>
      <div>
        <Nav />
        <main>{children}</main>
      </div>
    </Div>
  )
}

const Div = styled.div`
  height: 100vh;

  > div {
    overflow: auto;
    height: 100vh;

    > main {
      margin-top: ${({ theme }) => theme.constants.navbarHeight};
      height: calc(100% - ${({ theme }) => theme.constants.navbarHeight});
      max-width: 1366px;
      margin-inline: auto;
    }
  }
`
