export const scrollIntoView = (id: string, block: ScrollLogicalPosition = 'center'): void => {
  if (document?.getElementById(id)) {
    const element = document.getElementById(id) as HTMLElement
    element.scrollIntoView({ behavior: 'smooth', block })
  }
}

export const scrollToElement = (listItemId: string, containerId: string): void => {
  const listItem = document.getElementById(listItemId)
  const listContainer = document.getElementById(containerId)
  // const contentContainer = document.querySelector('.content')

  if (listItem && listContainer) {
    const listItemCenterPosition =
      listItem.offsetTop - (listContainer.getBoundingClientRect().height - listItem.getBoundingClientRect().height) / 2

    listContainer.scrollTo({ top: listItemCenterPosition, behavior: 'smooth' })
    // contentContainer.scrollTo({ top: 0, behavior: 'smooth' })
  }
}

export const moveToElement = (listItemId: string, containerId: string): void => {
  const listItem = document.getElementById(listItemId)
  const listContainer = document.getElementById(containerId)
  // const contentContainer = document.querySelector('.content')

  if (listItem && listContainer) {
    const listItemCenterPosition =
      listItem.offsetTop - (listContainer.getBoundingClientRect().height - listItem.getBoundingClientRect().height) / 2

    listContainer.scrollTo({ top: listItemCenterPosition, behavior: 'instant' as ScrollBehavior })
    // contentContainer.scrollTo({ top: 0, behavior: 'smooth' })
  }
}
