import { createGlobalStyle } from 'styled-components'

export const GlobalColorClasses = createGlobalStyle`
  .primary_100 {
    color: ${({ theme }) => theme.colors.primary_100};
  } 
  .primary_85 {
    color: ${({ theme }) => theme.colors.primary_85};
  } 
  .primary_70 {
    color: ${({ theme }) => theme.colors.primary_70};
  } 
  .primary_55 {
    color: ${({ theme }) => theme.colors.primary_55}; 
  } 
  .primary_40 {
    color: ${({ theme }) => theme.colors.primary_40};
  } 
  .primary_30 {
    color: ${({ theme }) => theme.colors.primary_30};
  } 
  .primary_25 {
    color: ${({ theme }) => theme.colors.primary_25};
  } 
  .primary_10 {
    color: ${({ theme }) => theme.colors.primary_10};
  } 
  .primary_2 {
    color: ${({ theme }) => theme.colors.primary_2};
  } 

  .secondary_100 {
    color: ${({ theme }) => theme.colors.secondary_100};
  } 
  .secondary_85 {
    color: ${({ theme }) => theme.colors.secondary_85};
  } 
  .secondary_70 {
    color: ${({ theme }) => theme.colors.secondary_70};
  } 
  .secondary_55 {
    color: ${({ theme }) => theme.colors.secondary_55};
  } 
  .secondary_40 {
    color: ${({ theme }) => theme.colors.secondary_40};
  } 
  .secondary_25 {
    color: ${({ theme }) => theme.colors.secondary_25};
  } 
  .secondary_10 {
    color: ${({ theme }) => theme.colors.secondary_10};
  } 
  .black {
    color: ${({ theme }) => theme.colors.black};
  } 
  .white {
    color: ${({ theme }) => theme.colors.white};
  } 
  .grey_100 {
    color: ${({ theme }) => theme.colors.grey_100};
  } 
  .gray_85 {
    color: ${({ theme }) => theme.colors.gray_85};
  } 
  .gray_70 {
    color: ${({ theme }) => theme.colors.gray_70};
  } 
  .gray_60 {
    color: ${({ theme }) => theme.colors.gray_60};
  }
  .gray_55 {
    color: ${({ theme }) => theme.colors.gray_55};
  } 
  .gray_40 {
    color: ${({ theme }) => theme.colors.gray_40};
  } 
  .gray_25 {
    color: ${({ theme }) => theme.colors.gray_25};
  } 
  .gray_10 {
    color: ${({ theme }) => theme.colors.gray_10};
  } 
  .error {
    color: ${({ theme }) => theme.colors.error};
  } 
  .warning {
    color: ${({ theme }) => theme.colors.warning};
  } 
  .success {
    color: ${({ theme }) => theme.colors.success};
  } 

  .bg-primary_100 {
    background-color: ${({ theme }) => theme.colors.primary_100};
  } 
  .bg-primary_85 {
    background-color: ${({ theme }) => theme.colors.primary_85};
  } 
  .bg-primary_70 {
    background-color: ${({ theme }) => theme.colors.primary_70};
  } 
  .bg-primary_55 {
    background-color: ${({ theme }) => theme.colors.primary_55};
  } 
  .bg-primary_40 {
    background-color: ${({ theme }) => theme.colors.primary_40};
  } 
  .bg-primary_25 {
    background-color: ${({ theme }) => theme.colors.primary_25};
  } 
  .bg-primary_10 {
    background-color: ${({ theme }) => theme.colors.primary_10};
  } 
  .bg-secondary_100 {
    background-color: ${({ theme }) => theme.colors.secondary_100};
  } 
  .bg-secondary_85 {
    background-color: ${({ theme }) => theme.colors.secondary_85};
  } 
  .bg-secondary_70 {
    background-color: ${({ theme }) => theme.colors.secondary_70};
  } 
  .bg-secondary_55 {
    background-color: ${({ theme }) => theme.colors.secondary_55};
  } 
  .bg-secondary_40 {
    background-color: ${({ theme }) => theme.colors.secondary_40};
  } 
  .bg-secondary_25 {
    background-color: ${({ theme }) => theme.colors.secondary_25};
  } 
  .bg-secondary_10 {
    background-color: ${({ theme }) => theme.colors.secondary_10};
  } 
  .bg-black {
    background-color: ${({ theme }) => theme.colors.black};
  } 
  .bg-white {
    background-color: ${({ theme }) => theme.colors.white};
  } 
  .bg-grey_100 {
    background-color: ${({ theme }) => theme.colors.grey_100};
  } 
  .bg-gray_85 {
    background-color: ${({ theme }) => theme.colors.gray_85};
  } 
  .bg-gray_70 {
    background-color: ${({ theme }) => theme.colors.gray_70};
  } 
  .bg-gray_55 {
    background-color: ${({ theme }) => theme.colors.gray_55};
  } 
  .bg-gray_40 {
    background-color: ${({ theme }) => theme.colors.gray_40};
  } 
  .bg-gray_25 {
    background-color: ${({ theme }) => theme.colors.gray_25};
  } 
  .bg-gray_10 {
    background-color: ${({ theme }) => theme.colors.gray_10};
  } 
  .bg-error {
    background-color: ${({ theme }) => theme.colors.error};
  } 
  .bg-warning {
    background-color: ${({ theme }) => theme.colors.warning};
  } 
  .bg-success {
    background-color: ${({ theme }) => theme.colors.success};
  } 
`
