import { ReactNode } from 'react'
import { ThemeProvider } from 'styled-components'

import { GlobalColorClasses } from './styles/GlobalColorClasses.styled'
import { GlobalStyles } from './styles/GlobalStyles.styled'
import { PrimereactThemeResets } from './styles/PrimereactThemeResets.styled'

export const theme = {
  colors: {
    primary_100: '#FF8412',
    primary_85: '#FFE453',
    primary_70: '#FFE871',
    primary_55: '#FFEE90',
    primary_40: '#FFF2AE',
    primary_25: '#FFF7CC',
    primary_30: '#C8A685',
    primary_10: '#FFFCEB',
    primary_2: '#FFFDFA',
    secondary_100: '#232F3E',
    secondary_85: '#A6BBCD',
    secondary_70: '#B5C7D6',
    secondary_55: '#C5D3DF',
    secondary_40: '#D5DFE8',
    secondary_25: '#E5EBF0',
    secondary_10: '#F5F7FA',
    fullBlack: '#000000',
    black: '#242424',
    white: '#ffffff',
    grey_100: '#333333',
    gray_60: '#7B8189',
    gray_85: '#4F4F4F',
    gray_70: '#828282',
    gray_55: '#BDBDBD',
    gray_40: '#E0E0E0',
    gray_25: '#ECEDEE',
    gray_10: '#F2F2F2',
    error: '#F47065',
    warning: '#FFC046',
    success: '#8DDF9B',
  },

  constants: {
    navbarHeight: '60px',
    //   pageTitleHeight: themeConstant(68),
    //   tableHeadHeight: themeConstant(56),
    //   paginationHeight: themeConstant(44),
    //   breadcrumbHeight: themeConstant(62),
  },
}

interface IProps {
  children: ReactNode | ReactNode[]
}

export const Theme = ({ children }: IProps) => {
  return (
    <ThemeProvider theme={theme}>
      <GlobalStyles />
      <PrimereactThemeResets />
      <GlobalColorClasses />
      {children}
    </ThemeProvider>
  )
}
