import cx from 'classnames'
import { useGetResourcesImmutable } from 'features/storyboard/hooks/useGetResources'
import { storyboardPaths } from 'features/storyboard/storyboard.api'
import { ICreateStoryBoardDemo, IStoryBoardOptions, IStoryBoardStyle } from 'features/storyboard/storyboard.types'
import { Chips, ChipsChangeEvent } from 'primereact/chips'
import { SelectItem } from 'primereact/selectitem'
import { Dispatch, SetStateAction, useEffect, useState } from 'react'
import { LoadingBox } from 'shared/components/ApiStates/Loading/LoadingBox'
import styled from 'styled-components'
interface IStepTwo {
  storyForm: ICreateStoryBoardDemo
  setStoryForm: Dispatch<SetStateAction<ICreateStoryBoardDemo>>
}

const StyleStep = ({ storyForm, setStoryForm }: IStepTwo) => {
  const { styles } = storyForm
  const { data, isLoading } = useGetResourcesImmutable<IStoryBoardOptions>(storyboardPaths.options)
  const [localStyles, setLocalStyles] = useState<any[]>([
    ...storyForm.customStyles,
    ...storyForm.styles.map((styleId) => data?.styles.find((style) => style.id == styleId)),
  ])

  const onStyleChange = (style: IStoryBoardStyle) => {
    if (localStyles.includes(style)) {
      setLocalStyles((prev) => prev.filter((el) => typeof el === 'string' || el.id !== style.id))
    } else {
      setLocalStyles((prev) => {
        return [...prev, style]
      })
    }
  }

  const onCustomStyleChange = (e: ChipsChangeEvent) => {
    setLocalStyles(e.value as any[])
  }

  const customChip = (item: SelectItem | string) => {
    return (
      <div>
        <span>{typeof item !== 'string' ? item?.title : item}</span>
      </div>
    )
  }

  useEffect(() => {
    const predefinedStyles = localStyles
      .filter((style) => typeof style !== 'string')
      .map((styled) => {
        return styled.id
      })
    const customStyles = localStyles.filter((style) => typeof style === 'string')
    setStoryForm((prev) => {
      return { ...prev, customStyles, styles: predefinedStyles }
    })
  }, [localStyles])

  if (isLoading) return <LoadingBox />
  return (
    <Div>
      <div className="flex flex-column gap-3">
        <Chips
          placeholder="Choose one or more cinematic styles, or add your own"
          value={localStyles}
          itemTemplate={customChip}
          onChange={onCustomStyleChange}
        />
        <div className="styles-container">
          {data?.styles?.map((style) => (
            <div
              key={style.id}
              onClick={() => {
                onStyleChange(style)
              }}
              className={cx({ active: styles.includes(style.id) })}
            >
              <img width={240} height={159} src={style.image || 'https://picsum.photos/240/160'} alt={style.title} />
              <p className="title">{style.title}</p>
              <p className="text-500">{style.director && style.year && `${style.director}, ${style.year}`}</p>
            </div>
          ))}
        </div>
      </div>
    </Div>
  )
}
const Div = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  max-height: 100%;
  overflow: auto;

  > div {
    width: 100%;
    max-height: 100%;
    overflow: auto;
    .styles-container {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      column-gap: 1rem;
      justify-content: center;
      row-gap: 1rem;
      .active {
        box-shadow: 0px 4px 32px rgba(253, 154, 25, 0.15);
        border-color: ${({ theme }) => theme.colors.primary_100};
      }
      > div {
        width: 240px;
        padding: 0.5rem;
        border: 1px solid rgba(35, 47, 62, 0.2);
        border-radius: 4px;
        cursor: pointer;
        > p {
          color: ${({ theme }) => theme.colors.gray_60};
        }
        .title {
          color: ${({ theme }) => theme.colors.secondary_100};
        }
        > img {
          border-radius: 4px;
        }
        span {
          border: 1px solid rgba(35, 47, 62, 0.2);
          font-weight: 400;
          font-size: 14px;
          line-height: 114%;
          color: ${({ theme }) => theme.colors.gray_60};
          padding: 4px 8px;
          border-radius: 8px;
        }
      }
    }
  }
  h2 {
    font-weight: 600;
    font-size: 20px;
    line-height: 132%;
    color: ${({ theme }) => theme.colors.secondary_100};
  }
  p {
    font-weight: 400;
    font-size: 16px;
    line-height: 150%;
    color: ${({ theme }) => theme.colors.gray_60};
  }
`

export default StyleStep
