import { recordChangePageEvent, recordStoryActionEvent } from 'features/eventTracking/eventTracking.api'
import { changePageInteractionEventsEnum, interactionEventsEnum } from 'features/eventTracking/eventTracking.enums'
import { useGetResources } from 'features/storyboard/hooks/useGetResources'
import { useInlineStoryboardScenesEvent } from 'features/storyboard/hooks/useInlineStoryboardScenesEvent'
import { usePostResource } from 'features/storyboard/hooks/usePostResources'
import { storyboardPaths } from 'features/storyboard/storyboard.api'
import { subscriptionsPlansEnum } from 'features/storyboard/storyboard.enums'
import { initialStoryForm } from 'features/storyboard/storyboard.model'
import { Character, IStoryBoard, IStoryBoardOptions, Location } from 'features/storyboard/storyboard.types'
import { useAtom, useSetAtom } from 'jotai'
import { MenuItem } from 'primereact/menuitem'
import { Steps } from 'primereact/steps'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import styled from 'styled-components'
import { pagesRoutesEnum } from '../../../../pages/Router/router.enums'
import { Button } from '../../../../shared/components/Buttons/Button'
import { activeStepAtom, storyFormAtom, storyboardEventIdsAtom } from '../../storyboard.state'
import AIEngineStep from './AIEngineStep'
import CharacterStep from './CharacterStep'
import { getCharactersAndCounts, getLocationsAndCounts } from './CharacterUtils'
import LocationStep from './LocationStep'
import { SceneSelectionStep } from './SceneSelectionStep'
import ScriptStep from './ScriptStep'
import StyleStep from './StyleStep'

type Step = {
  label: string
  component: JSX.Element
  isEnabled: boolean
}

export const CreateStoryTabs = () => {
  const [storyForm, setStoryForm] = useAtom(storyFormAtom)
  const setStoryBoardActiveEvents = useSetAtom(storyboardEventIdsAtom)
  const [activeStep, setActiveStep] = useAtom(activeStepAtom)
  const navigate = useNavigate()

  // Logic to fetch the parsed script.
  const storyboardUrl = storyForm.id ? `${storyboardPaths.getScenes(storyForm.id, 0, 0)}` : null
  const { activeEvent, activeEventId } = useInlineStoryboardScenesEvent(storyForm.id)
  const {
    data: storyboard,
    mutate: mutateStoryboard,
    isLoading: storyboardIsLoading,
  } = useGetResources<IStoryBoard>(
    activeEventId === storyForm.id && activeEvent && activeEvent.total > 0 && activeEvent.total == activeEvent.completed
      ? storyboardUrl
      : null,
  )

  useEffect(() => {
    mutateStoryboard()
  }, [activeEvent])

  const [characters, setCharacters] = useState<Character[]>([])
  const [locations, setLocations] = useState<Location[]>([])

  const MIN_MENTIONS = 10
  useEffect(() => {
    setCharacters(
      getCharactersAndCounts(storyboard?.scenes || [])
        .filter((characterAndCount) => characterAndCount[1] >= MIN_MENTIONS)
        .map(
          (characterAndCount) =>
            ({
              name: characterAndCount[0],
              bio: '' + `${characterAndCount[1]} mentions`,
              gender: '',
              age: '',
              race: '',
              description: '',
              manual: false,
            } as Character),
        ),
    )

    setLocations(
      getLocationsAndCounts(storyboard?.scenes || [])
        .filter((locationAndCount) => locationAndCount[1] >= MIN_MENTIONS)
        .map(
          (locationAndCount) =>
            ({
              name: locationAndCount[0],
              bio: '' + `${locationAndCount[1]} mentions`,
              description: '',
            } as Location),
        ),
    )
  }, [storyboard?.scenes])

  const ORDERED_STEPS: Step[] = [
    {
      label: 'Script',
      component: <ScriptStep />,
      isEnabled: storyForm.file || storyForm.library || (storyForm.title && storyForm.text) ? true : false,
    },
    {
      label: 'Scene Selection',
      component: <SceneSelectionStep storyboard={storyboard} storyboardIsLoading={storyboardIsLoading} />,
      isEnabled: (storyForm.scene_indices?.length || 0) > 0,
    },
    {
      label: 'Characters',
      component: <CharacterStep characters={characters} setCharacters={setCharacters} />,
      isEnabled: true,
    },
    {
      label: 'Locations',
      component: <LocationStep locations={locations} setLocations={setLocations} />,
      isEnabled: true,
    },
    {
      label: 'Style',
      component: <StyleStep storyForm={storyForm} setStoryForm={setStoryForm} />,
      isEnabled: storyForm.styles.length > 0 || storyForm.customStyles.length > 0,
    },
    { label: 'AI Engine', component: <AIEngineStep />, isEnabled: storyForm.model ? true : false },
  ]

  const isFirstStep = activeStep === 0
  const isLastStep = activeStep === ORDERED_STEPS.length - 1

  const { data: options, isLoading: isLoadingOptions } = useGetResources<IStoryBoardOptions>(storyboardPaths.options)

  const { trigger: triggerUpdateStory, isMutating: isUpdatingStory } = usePostResource<
    { model: string | null; styles: string[]; custom_styles: string[] },
    IStoryBoard
  >(storyboardPaths.updateStory(storyForm.id || ''), {
    onError(error) {
      const { code, message, status } = error
      recordStoryActionEvent({
        interactionElement: interactionEventsEnum.createStoryFailed,
        error: { code, message, status },
      })
      toast.error('Could not start storyboard')
    },
    onSuccess() {
      addMoreScenesTrigger({ scene_indices: storyForm.scene_indices || [] })
    },
  })

  const { trigger: addMoreScenesTrigger, isMutating: isAddingMoreStories } = usePostResource<
    { scene_indices: number[] },
    IStoryBoard
  >(storyboardPaths.storyboardGenerateMoreScenes(storyForm.id || ''), {
    onError(error) {
      const { code, message, status } = error
      recordStoryActionEvent({
        interactionElement: interactionEventsEnum.generateMoreStoryScenesFailed,
        error: { code, message, status },
      })
      toast.error('Could not generate more scenes')
    },
    onSuccess() {
      // This will then get handled by useStoryboardsEvents() in App.tsx
      setStoryBoardActiveEvents((c) => [...c, storyForm?.id || ''])
      navigate(pagesRoutesEnum.projects)
      setActiveStep(0)
    },
  })

  const { trigger: startPaymentAsync, isMutating: isStartingPayment } = usePostResource<
    { type: subscriptionsPlansEnum; success_redirect_url: string; failed_redirect_url: string },
    { payment_link: string }
  >(storyboardPaths.payment, {
    onError(error) {
      const { code, message, status } = error
      recordStoryActionEvent({
        interactionElement: interactionEventsEnum.generatePaymentLinkFailed,
        error: { code, message, status },
      })
      toast.error('Could not generate a payment link')
    },
    onSuccess(data) {
      window.open(data.payment_link, '_blank')
    },
  })

  // handlers
  const onNextStep = () => {
    setActiveStep((prev) => prev + 1)
  }
  const onPrevStep = () => {
    setActiveStep((prev) => prev - 1)
  }

  const onSubmit = () => {
    const data = {
      model: storyForm.model,
      styles: storyForm.styles,
      custom_styles: storyForm.customStyles,
      characters: characters
        .filter((c) => c.age !== '' || c.gender !== '' || c.race !== '' || c.description !== '')
        .map((c) => {
          return { name: c.name, gender: c.gender, age: c.age, race: c.race, description: c.description }
        }),
      locations: locations
        .filter((loc) => loc.description !== '')
        .map((loc) => {
          return { name: loc.name, description: loc.description }
        }),
    }
    triggerUpdateStory(data)
  }

  const onUpgrade = async (type: subscriptionsPlansEnum) => {
    const success_redirect_url =
      process.env.NODE_ENV === 'development'
        ? `http://localhost:3000${pagesRoutesEnum.paymentSuccess}`
        : `${window.location.protocol}//${window.location.hostname}${pagesRoutesEnum.paymentSuccess}`
    const failed_redirect_url =
      process.env.NODE_ENV === 'development'
        ? `http://localhost:3000${pagesRoutesEnum.paymentFailed}`
        : `${window.location.protocol}//${window.location.hostname}${pagesRoutesEnum.paymentFailed}`

    startPaymentAsync({ type, success_redirect_url, failed_redirect_url })
  }

  useEffect(() => {
    return () => {
      setStoryForm(initialStoryForm)
    }
  }, [])

  return (
    <Div>
      <div className="form">
        {/* By fixing the width, we make sure that each step takes the same amount of space, and elements look centered. */}
        <Steps
          activeIndex={activeStep}
          model={ORDERED_STEPS.map((step) => ({ label: step.label, style: { width: '10px' } } as MenuItem))}
        />
        {ORDERED_STEPS[activeStep].component}
      </div>

      {isFirstStep && options && options?.max_amount_of_scenes === 0 && (
        <div className="text-center mt-1 text-xs flex flex-column align-items-center justify-content-center gap-3 border-1 border-400 border-round-3xl max-w-20rem py-3 px-5 mx-auto">
          <span>You have no credits left. Purchase more to continue.</span>
          <Button
            isLoading={isStartingPayment}
            onClick={() => {
              recordChangePageEvent({
                page: window.location.pathname,
                to: pagesRoutesEnum.subscriptions,
                interactionElement: changePageInteractionEventsEnum.upgradeToProCreateStoryGenerateTab,
              })
              onUpgrade(subscriptionsPlansEnum.regular)
            }}
          >
            Purchase
          </Button>
        </div>
      )}
      <div className="buttons-container">
        {!isFirstStep && (
          <Button variant="outline" onClick={onPrevStep} size="large">
            Back
          </Button>
        )}

        <div>
          <Button
            disabled={!ORDERED_STEPS[activeStep].isEnabled}
            onClick={isLastStep ? onSubmit : onNextStep}
            size="large"
          >
            {isLastStep ? 'Generate' : 'Continue'}
          </Button>
        </div>
      </div>
    </Div>
  )
}

const Div = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  padding: 1rem 1.5rem;

  .form {
    width: 100%;
    display: flex;
    flex-direction: column;
    flex: 1;
    overflow-y: auto;
    gap: 2rem;
  }

  .buttons-container {
    display: flex;
    justify-content: center;
    align-items: start;
    gap: 1rem;
    padding-top: 1rem;
  }
`
