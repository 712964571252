import cx from 'classnames'
import { useState } from 'react'
import styled from 'styled-components'
import DocumentUploader from './DocumentUploader'
import ScriptPaster from './ScriptPaster'
import ScriptsLibrary from './ScriptsLibrary'

const ScriptStep = () => {
  const [activeTab, setActiveTab] = useState<'upload' | 'paste' | 'library'>('upload')

  return (
    <Div>
      <div className="flex flex-column gap-4">
        <div className="flex flex-column gap-1 max-width align-items-center">
          <h2>Input your script, or choose from our sample scripts.</h2>
        </div>
        <div className="tabs max-width">
          <div
            onClick={() => setActiveTab('upload')}
            className={cx({ active: activeTab == 'upload' }, 'tab py-2 px-0 md:px-8')}
          >
            Upload PDF
          </div>
          <div
            onClick={() => setActiveTab('library')}
            className={cx({ active: activeTab == 'library' }, 'tab py-2 px-0 md:px-8')}
          >
            Sample scripts
          </div>
          <div
            onClick={() => setActiveTab('paste')}
            className={cx({ active: activeTab == 'paste' }, 'tab py-2 px-0 md:px-8')}
          >
            Enter text
          </div>
        </div>
        {
          {
            upload: <DocumentUploader />,
            paste: <ScriptPaster />,
            library: <ScriptsLibrary />,
          }[activeTab]
        }
      </div>
    </Div>
  )
}
const Div = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  max-height: 100%;
  overflow: hidden;

  .max-width {
    width: 100%;
    max-width: 900px;
  }

  > div {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow: hidden;
  }
  h2 {
    font-weight: 600;
    font-size: 20px;
    line-height: 132%;
    color: ${({ theme }) => theme.colors.secondary_100};
  }
  p {
    font-weight: 400;
    font-size: 16px;
    line-height: 150%;
    color: ${({ theme }) => theme.colors.gray_60};
  }
  .tabs {
    display: flex;
    border: 1px solid ${({ theme }) => theme.colors.primary_100};
    border-radius: 4px;
    .tab {
      display: flex;
      justify-content: center;
      width: 50%;
      cursor: pointer;
      color: ${({ theme }) => theme.colors.gray_60};
    }
    .active {
      background: linear-gradient(270deg, rgba(0, 0, 0, 0.8) 0%, rgba(0, 0, 0, 0) 100%),
        ${({ theme }) => theme.colors.primary_100};
      background-blend-mode: soft-light, normal;
      color: ${({ theme }) => theme.colors.white};
    }
  }
`
export default ScriptStep
