export const machineLearningModels = [
  {
    title: 'Stable Diffusion v1.5',
    value: 'stable-diffusion-v1-5',
  },
]

export const storyboardStyles = [
  {
    icon: 'https://picsum.photos/150/80',
    title: 'Citizen Kane',
    value: 'Citizen Kane',
    label: 'Orson Welles, 1941',
  },
  {
    icon: 'https://picsum.photos/150/80',
    title: 'Blade Runner 2049',
    value: 'Blade Runner 2049',
    label: 'Denis Villenueve, 2017',
  },
  {
    icon: 'https://picsum.photos/150/80',
    title: 'Grand Budapest Hotel',
    value: 'Grand Budapest Hotel',
    label: 'Wes Anderson, 2014',
  },
  {
    icon: 'https://picsum.photos/150/80',
    title: 'Jaws',
    value: 'Jaws',
    label: 'Steven Spielberg, 1975',
  },
  {
    icon: 'https://picsum.photos/150/80',
    title: 'Citizen Kane',
    value: 'Citizen Kane2',
    label: 'Orson Welles, 1941',
  },
  {
    icon: 'https://picsum.photos/150/80',
    title: 'Blade Runner 2049',
    value: 'Blade Runner 20492',
    label: 'Denis Villenueve, 2017',
  },
  {
    icon: 'https://picsum.photos/150/80',
    title: 'Grand Budapest Hotel',
    value: 'Grand Budapest Hotel2',
    label: 'Wes Anderson, 2014',
  },
  {
    icon: 'https://picsum.photos/150/80',
    title: 'Jaws',
    value: 'Jaws2',
    label: 'Steven Spielberg, 1975',
  },
  {
    icon: 'https://picsum.photos/150/80',
    title: 'Citizen Kane',
    value: 'Citizen Kane3',
    label: 'Orson Welles, 1941',
  },
  {
    icon: 'https://picsum.photos/150/80',
    title: 'Blade Runner 2049',
    value: 'Blade Runner 20493',
    label: 'Denis Villenueve, 2017',
  },
  {
    icon: 'https://picsum.photos/150/80',
    title: 'Grand Budapest Hotel',
    value: 'Grand Budapest Hotel3',
    label: 'Wes Anderson, 2014',
  },
  {
    icon: 'https://picsum.photos/150/80',
    title: 'Jaws',
    value: 'Jaws3',
    label: 'Steven Spielberg, 1975',
  },
  {
    icon: 'https://picsum.photos/150/80',
    title: 'Citizen Kane',
    value: 'Citizen Kane4',
    label: 'Orson Welles, 1941',
  },
  {
    icon: 'https://picsum.photos/150/80',
    title: 'Blade Runner 2049',
    value: 'Blade Runner 20494',
    label: 'Denis Villenueve, 2017',
  },
  {
    icon: 'https://picsum.photos/150/80',
    title: 'Grand Budapest Hotel',
    value: 'Grand Budapest Hotel4',
    label: 'Wes Anderson, 2014',
  },
  {
    icon: 'https://picsum.photos/150/80',
    title: 'Jaws',
    value: 'Jaws4',
    label: 'Steven Spielberg, 1975',
  },
]

export const initialStoryForm = {
  id: null,
  styles: [],
  customStyles: [],
  model: null,
}
