import { usePostMultiPartResource, usePostResource } from 'features/storyboard/hooks/usePostResources'
import { postStoryResource, storyboardPaths } from 'features/storyboard/storyboard.api'
import { storyFormAtom } from 'features/storyboard/storyboard.state'
import { IStoryBoard } from 'features/storyboard/storyboard.types'
import { useAtom } from 'jotai'
import { useEffect } from 'react'
import { LoadingBox } from 'shared/components/ApiStates/Loading/LoadingBox'
import styled from 'styled-components'
import { SceneSelectionTable } from './SceneSelectionTable'

export interface ISelectedRow {
  id: string
  sceneIndex: number
  location: string
  time: string
  summary: string
  characters?: string[]
  tags?: string[]
}

interface SceneSelectionProps {
  storyboard: IStoryBoard | undefined
  storyboardIsLoading: boolean
}

export const SceneSelectionStep = (props: SceneSelectionProps) => {
  const [storyForm, setStoryForm] = useAtom(storyFormAtom)

  const { trigger: triggerNewStoryFromPdf } = usePostMultiPartResource<FormData, string>(storyboardPaths.newStory, {
    onSuccess: (id) => {
      setStoryForm((c) => ({ ...c, id }))
    },
  })

  const { trigger: triggerNewStoryFromText } = usePostResource<FormData, string>(storyboardPaths.newStoryFromText, {
    onSuccess: (id) => {
      setStoryForm((c) => ({ ...c, id }))
    },
  })

  const handleScriptSelection = async () => {
    if (storyForm.file) {
      const formData = new FormData()
      formData.append('file', storyForm.file)
      triggerNewStoryFromPdf(formData)
    } else if (storyForm.title && storyForm.text) {
      const formData = new FormData()
      formData.append('title', storyForm.title)
      formData.append('text', storyForm.text)
      triggerNewStoryFromText(formData)
    } else if (storyForm.library) {
      const data = await postStoryResource<Record<string, never>, string>(
        `${storyboardPaths.newStory}?sample_script_id=${storyForm.library}`,
        { arg: {} },
      )
      setStoryForm((c) => ({ ...c, id: data }))
    }
  }

  useEffect(() => {
    handleScriptSelection()
  }, [])

  if (props.storyboardIsLoading || !props.storyboard)
    return (
      <LoadingBox
        message={
          <div className="flex flex-column gap-3 justify-content-center align-items-center">
            <p>We are parsing the script. Hang tight for a few seconds while we do some AI magic.</p>
          </div>
        }
      />
    )

  return (
    <Div>
      <div className="flex flex-column gap-1 max-width align-items-center pb-5">
        <h2>Select the scenes you want to visualize.</h2>
        <p>You will be able to add more later.</p>
      </div>
      <SceneSelectionTable
        scenes={props.storyboard.scenes}
        requestInProgress={props.storyboardIsLoading}
        onSelectionChange={(scene_indices) =>
          setStoryForm((c) => ({ ...c, scene_indices, amount_of_scenes: undefined }))
        }
      />
    </Div>
  )
}

const Div = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  max-height: 100%;
  overflow: auto;

  .max-width {
    width: 100%;
    max-width: 640px;
  }

  > div {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  h2 {
    font-weight: 600;
    font-size: 20px;
    line-height: 132%;
    color: ${({ theme }) => theme.colors.secondary_100};
  }
  p {
    font-weight: 400;
    font-size: 16px;
    line-height: 150%;
    color: ${({ theme }) => theme.colors.gray_60};
  }
  .tabs {
    display: flex;
    border: 1px solid ${({ theme }) => theme.colors.primary_100};
    border-radius: 4px;
    .tab {
      display: flex;
      justify-content: center;
      width: 50%;
      cursor: pointer;
      color: ${({ theme }) => theme.colors.gray_60};
    }
    .active {
      background: linear-gradient(270deg, rgba(0, 0, 0, 0.8) 0%, rgba(0, 0, 0, 0) 100%),
        ${({ theme }) => theme.colors.primary_100};
      background-blend-mode: soft-light, normal;
      color: ${({ theme }) => theme.colors.white};
    }
  }
`
